import DataTable from "react-data-table-component";
import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { useTranslation } from "react-i18next";
import SearchBar from "material-ui-search-bar";
import * as Sentry from "@sentry/browser";
import { useAppSelector } from "../../store/hooks";
// import SearchBar from "material-ui-search-bar";

// A super simple expandable component

export const MyComponent: React.FC<any> = (data, setCsvData) => {
  const {
    transfer,
    auth: { user, currency, lang, rate, dana_pay_bank_accounts },
  } = useAppSelector((state: any) => state.persistedReducer);
  // const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [CSVdata, setCSVData] = useState<any>([]);
  const [searched, setSearched] = useState<any>("");
  const [addRowData, setAddRowData] = useState<Boolean>(false);

  useEffect(() => {
    if (data?.data?.length > 0 && data?.data !== undefined) {
      setCSVData(addRowData === true ? [data?.data] : data?.data);
      setAddRowData(false);
    }
    if (data?.data?.data?.length > 0 && data?.data?.data !== undefined) {
      setCSVData(addRowData === true ? [data?.data?.data] : data?.data?.data);
      setAddRowData(false);
    }
  }, [data, addRowData]);

  const seachingData = (value: any) => {
    try {
      if (data?.data?.data === undefined) {
        const filterData = data?.data.filter((row: any) => {
          return row.first_name.toLowerCase().includes(value.toLowerCase())
            ? row.first_name.toLowerCase().includes(value.toLowerCase())
            : row.last_name.toLowerCase().includes(value.toLowerCase())
            ? row.last_name.toLowerCase().includes(value.toLowerCase())
            : row.phone_number.toLowerCase().includes(value.toLowerCase())
            ? row.phone_number.toLowerCase().includes(value.toLowerCase())
            : row.amount_without_fees
                .toLowerCase()
                .includes(value.toLowerCase())
            ? row.amount_without_fees
                .toLowerCase()
                .includes(value.toLowerCase())
            : row.email !== null &&
              row.email.toLowerCase().includes(value.toLowerCase());
        });
        setCSVData(filterData);
      } else {
        const filterData = data?.data?.data.filter((row: any) => {
          return row.first_name.toLowerCase().includes(value.toLowerCase())
            ? row.first_name.toLowerCase().includes(value.toLowerCase())
            : row.last_name.toLowerCase().includes(value.toLowerCase())
            ? row.last_name.toLowerCase().includes(value.toLowerCase())
            : row.phone_number.toLowerCase().includes(value.toLowerCase())
            ? row.phone_number.toLowerCase().includes(value.toLowerCase())
            : row.amount_without_fees
                .toLowerCase()
                .includes(value.toLowerCase())
            ? row.amount_without_fees
                .toLowerCase()
                .includes(value.toLowerCase())
            : row.email.toLowerCase().includes(value.toLowerCase());
        });
        setCSVData(filterData);
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const cancelSearch = () => {
    setSearched("");
    seachingData(searched);
  };
  // const ExpandedComponent: React.FC<any> = ({ data }) => <pre>{JSON.stringify(data?.data?.data, null, 2)}</pre>;
  const deleteRow = (rowItem: any) => {
    try {
      const removeItem: any = CSVdata.filter(
        (item: any) => item.id.toString() !== rowItem.id.toString()
      );
      const removeItemWithNumber: any = CSVdata.filter(
        (item: any) =>
          item.identification_number?.toString() !==
          rowItem.identification_number?.toString()
      );
      if (data?.data?.data) {
        data.data.data = removeItem;
      } else if (data?.data?.length > 0) {
        data.setCsvData(removeItem);
      }
      setCSVData(removeItem);
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const addRow = () => {
    try {
      setAddRowData(true);
      const findLastIndex = Number(CSVdata[CSVdata.length - 1]?.id) + 1;
      const sequenceId: any = CSVdata?.find((val: any) => {
        return val.newItem === true;
      });
      const sequenceIdSeries = Number(sequenceId?.id) + 1;
      const findLastIndexbulk =
        Number(
          transfer?.verifyBulkTransfer?.data?.beneficiaries[
            transfer?.verifyBulkTransfer?.data?.beneficiaries.length - 1
          ]?.identification_number
        ) + 1;
      const sequenceIdWithOld =
        transfer?.verifyBulkTransfer?.data?.beneficiaries.find((val: any) => {
          return val.newItem === false;
        });
      const sequenceIdSeriesData =
        Number(sequenceId?.identification_number) + 1;
      CSVdata.unshift({
        identification_number:
          sequenceIdWithOld === undefined
            ? findLastIndexbulk
            : sequenceIdSeriesData,
        id:
          sequenceId === undefined
            ? findLastIndex.toString()
            : sequenceIdSeries.toString(),
        newItem: true,
      });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const columns: any = [
    {
      // name: t("First_Name"),
      selector: (row: any) => {
        return (
          <div
            className={
              row?.first_name === ""
                ? "custom-error Customeinputparrent"
                : "Customeinputparrent"
            }
          >
            <input
              type="text"
              className="form-control"
              placeholder={t("First_Name")}
              onChange={(e) => {
                setCSVData((val: any) => {
                  const x = val.find((item: any) => {
                    return item.id === row.id;
                  });
                  const ind = val.findIndex((items: any) => {
                    return items.id === row.id;
                  });
                  x.first_name = e.target.value;
                  x.newItem = false;
                  const newState = [...val];
                  newState.splice(ind, 1, x);
                  return newState;
                });
              }}
              value={row?.first_name}
            />
          </div>
        );
      },
    },
    {
      // name: t("Last_Name"),
      selector: (row: any) => {
        return (
          <div
            className={
              row?.last_name === ""
                ? "custom-error Customeinputparrent"
                : "Customeinputparrent"
            }
          >
            <input
              type="text"
              className="form-control"
              placeholder={t("Last_Name")}
              onChange={(e) => {
                setCSVData((val: any) => {
                  const x = val.find((item: any) => {
                    return item.id === row.id;
                  });
                  const ind = val.findIndex((items: any) => {
                    return items.id === row.id;
                  });
                  x.last_name = e.target.value;
                  x.newItem = false;
                  const newState = [...val];
                  newState.splice(ind, 1, x);
                  return newState;
                });
              }}
              value={row?.last_name}
            />
          </div>
        );
      },
    },
    {
      // name: t("Email"),
      selector: (row: any) => {
        return (
          <div
            className={
              row?.email === "" || row?.email === null
                ? "custom-error Customeinputparrent"
                : "Customeinputparrent"
            }
          >
            <input
              type="text"
              className="form-control"
              placeholder={t("Email")}
              onChange={(e) => {
                setCSVData((val: any) => {
                  const x = val.find((item: any) => {
                    return item.id === row.id;
                  });
                  const ind = val.findIndex((items: any) => {
                    return items.id === row.id;
                  });
                  x.email = e.target.value;
                  x.newItem = false;
                  const newState = [...val];
                  newState.splice(ind, 1, x);
                  return newState;
                });
              }}
              value={row?.email}
            />
          </div>
        );
      },
    },
    {
      // name: t("Phone_Number"),
      selector: (row: any) => {
        return (
          <div
            className={
              row?.phone_number === ""
                ? "custom-error Customeinputparrent"
                : "Customeinputparrent"
            }
          >
            <input
              type="Number"
              className="form-control"
              placeholder={t("Phone_Number")}
              onChange={(e) => {
                setCSVData((val: any) => {
                  const x = val.find((item: any) => {
                    return item.id === row.id;
                  });
                  const ind = val.findIndex((items: any) => {
                    return items.id === row.id;
                  });
                  x.phone_number = e.target.value;
                  x.newItem = false;
                  const newState = [...val];
                  newState.splice(ind, 1, x);
                  return newState;
                });
              }}
              value={row?.phone_number}
            />
          </div>
        );
      },
    },
    {
      // name: t("Sent_amount_EUR_Only"),
      selector: (row: any) => {
        return (
          <div
            className={
              row?.amount_without_fees === "" || row?.amount_in_euros === ""
                ? "custom-error Customeinputparrent"
                : "Customeinputparrent"
            }
          >
            <input
              type="number"
              className="form-control"
              placeholder={t("Sent_amount_EUR_Only")}
              onChange={(e) => {
                setCSVData((val: any) => {
                  const x = val.find((item: any) => {
                    return item.id === row.id;
                  });
                  const ind = val.findIndex((items: any) => {
                    return items.id === row.id;
                  });
                  x.amount_without_fees = e.target.value;
                  x.newItem = false;
                  const newState = [...val];
                  newState.splice(ind, 1, x);
                  return newState;
                });
              }}
              value={row?.amount_without_fees}
            />
          </div>
        );
      },
    },
    {
      name: "",
      selector: (row: any) => {
        return (
          <button
            className={
              row?.newItem === true ||
              row?.first_name === "" ||
              row?.last_name === "" ||
              row.email === "" ||
              row?.phone_number === "" ||
              row?.amount_without_fees === "" ||
              row["Currency(EUR, CFA)"] === "" ||
              row?.email === null
                ? "ErrorIcon"
                : "SucessIcon"
            }
          >
            <i
              className={
                row?.newItem === true ||
                row?.first_name === "" ||
                row?.last_name === "" ||
                row.email === "" ||
                row?.phone_number === "" ||
                row?.amount_without_fees === "" ||
                row["Currency(EUR, CFA)"] === "" ||
                row?.email === null
                  ? "fa fa-exclamation-circle"
                  : "fa fa-check-circle"
              }
            />
          </button>
        );
        // return <button className='ErrorIcon'><i className='fa fa-exclamation-circle' /></button>
      },
    },
    {
      name: "",
      selector: (row: any) => {
        return (
          <button className="deleteIconUpload">
            <i className="fa fa-Trash" onClick={() => deleteRow(row)} />
          </button>
        );
      },
    },
  ];

  return (
    <>
      <div className="CustomeUploadTable">
        <div style={{ margin: "1rem" }}>
          <SearchBar
            className="searchUploadCustome"
            placeholder={t("Search")}
            value={searched}
            onChange={(newValue: any) => seachingData(newValue)}
            onCancelSearch={() => cancelSearch()}
          />
        </div>
        <div className="customeTableWrapper">
          {CSVdata?.length + " " + t("entries")}
          <button
            className="CustomeAddCell"
            disabled={CSVdata?.length > 0 ? false : true}
          >
            <i className="fa fa-plus" onClick={() => addRow()} />
          </button>
        </div>
        <DataTable columns={columns} data={CSVdata} pagination />
      </div>
    </>
  );
};
