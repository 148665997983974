import InputField from "../forms/InputField";
import CustomDropdown from "../CustomDropdown";
import { useMemo } from "react";
import * as Sentry from "@sentry/browser";

const MobileWithdrawBox = (props: any) => {
  const {
    mobileOperatorList,
    setwidthdrawMethod,
    widthdrawMethod,
    t,
    transfer,
    setselectedMobileOperator,
    setSelectedCountryReset,
    setinstitutionId,
    setcustomMobileOperator,
    selectedMobileOperator,
    customMobileOperator,
    phone,
    setPhone,
    customPhoneNumber,
    setcustomPhoneNumber,
    beneficiaryMMAccounts,
    numberInputInvalidChars,
    directPaymentAmountExceed,
  } = props;

  const maxLimit = useMemo(() => {
    try {
      if (
        transfer?.getAllMobileOperator &&
        transfer?.getAllMobileOperator?.length > 0
      ) {
        const mobileOperatorObject = transfer?.getAllMobileOperator.find(
          (cashoutMethod: any) => {
            return (
              cashoutMethod &&
              cashoutMethod.cashout_method &&
              cashoutMethod.cashout_method.payment_type &&
              cashoutMethod.cashout_method.payment_type.name == "mobile_money"
            );
          }
        );
        if (mobileOperatorObject) {
          return mobileOperatorObject.cashout_method.max_amount + " EUR";
        }
      } else {
        return 0;
      }
    } catch (error) {
      Sentry.captureException(error);
      return 0;
    }
  }, [transfer]);

  return (
    <>
      {mobileOperatorList.length > 0 && (
        <>
          <div className={`wrapperBankCheckBox m-0 innerBankBox `}>
            <div className={`wrapperBankCheckBoxwrapper`}>
              <input
                type="checkbox"
                className="default:ring-2"
                onChange={(e) => {
                  e.target.checked && setwidthdrawMethod("Mobile Money");
                }}
                name="Mobile Money"
                checked={widthdrawMethod == "Mobile Money"}
              />
              <span className="checkmark"></span>
            </div>
            <label
              className={`opacity-[${
                directPaymentAmountExceed && widthdrawMethod == "Mobile Money"
                  ? 0.5
                  : 1
              }]`}
            >
              {t("Mobile Money")}
            </label>
          </div>

          {directPaymentAmountExceed && widthdrawMethod == "Mobile Money" && (
            <small className="mb-2 innerBankBox text-red-500">
              {t("max_amount_exceeded_cashout_message_1")}
              {maxLimit}
              {t("max_amount_exceeded_cashout_message_2")}
            </small>
          )}
        </>
      )}

      {widthdrawMethod == "Mobile Money" && mobileOperatorList.length > 0 && (
        <div className="mb-1 flex flex-col innerBankBox">
          <div className="innerBoxDesc mb-2">{t("mobile_money_warning")}</div>

          <CustomDropdown
            isDisabled={
              directPaymentAmountExceed && widthdrawMethod == "Mobile Money"
            }
            setCustomProvider={setcustomMobileOperator}
            defaultValue={transfer?.transactionToRepeat?.data?.operator}
            setinstitutionId={setinstitutionId}
            onchange={(val: any) => {
              setselectedMobileOperator(val);
              setSelectedCountryReset(val);
            }}
            list={mobileOperatorList}
            placeholderComponent={
              <div style={{ fontSize: "14px" }}>
                {t("Select_Mobile_Operator")}
                <span className="text-red-500">{t("required_red")}</span>
              </div>
            }
            customClasses="wrapperBankCheckBox innerBankBox m-0 !bg-white"
            placeholder={`${t("Select_Mobile_Operator")}${t("required_red")}`}
          />

          {selectedMobileOperator == "Other" && (
            <InputField
              disabled={
                directPaymentAmountExceed && widthdrawMethod == "Mobile Money"
              }
              handleChange={(e: any) => setcustomMobileOperator(e.target.value)}
              value={customMobileOperator}
              label=""
              type="text"
              name="customMobileOperator"
              placeholder="Mobile Operator"
            />
          )}

          <div
            className="w-full flex px-0 gap-1  text-sm items-center  border-2 rounded-2"
            style={{
              // background: '#fafafa',
              color: "#080707",
              border: "0px",
              padding: "0px 16px",
              margin: "0px 0px",
            }}
          >
            <div
              className={`p-2 bg-white flex items-center opacity-[${
                directPaymentAmountExceed && widthdrawMethod == "Mobile Money"
                  ? 0.3
                  : 1
              }]`}
            >
              <p className="text-sm font-bold">
                {transfer?.beneficiary?.country_code}
              </p>
            </div>

            {/* phone number dropdown */}
            {beneficiaryMMAccounts.length > 0 ? (
              <CustomDropdown
                isDisabled={
                  directPaymentAmountExceed && widthdrawMethod == "Mobile Money"
                }
                defaultValue={
                  transfer?.transactionToRepeat?.data?.phone_number || phone
                }
                onchange={(val: any) => {
                  setPhone(val);
                }}
                list={beneficiaryMMAccounts}
                customClasses="wrapperBankCheckBox innerBankBox m-0 w-full !bg-white"
                placeholder={t("select_mobile_number")}
              />
            ) : (
              <InputField
                disabled={
                  directPaymentAmountExceed && widthdrawMethod == "Mobile Money"
                }
                name="phone_number"
                handleChange={(e: any) => setPhone(e.target.value)}
                value={phone}
              />
            )}
          </div>

          {phone == "Other" && (
            <div className="w-full mb-1">
              <input
                disabled={
                  directPaymentAmountExceed && widthdrawMethod == "Mobile Money"
                }
                onChange={(text) => {
                  setcustomPhoneNumber(text.target.value);
                }}
                value={customPhoneNumber}
                className="flex-1 w-full p-2 text-sm w-full px-2 py-2 text-sm bg-white border-2 rounded-2"
                placeholder={t("Phone_Number")}
                type="number"
                onKeyDown={(e) => {
                  if (numberInputInvalidChars.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                style={{
                  color: "#080707",
                  border: "0px",
                  padding: "15px 16px",
                  margin: "7px 0px",
                }}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MobileWithdrawBox;
