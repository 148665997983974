import { Modal } from "@material-ui/core";
import React, { useMemo } from "react";
import InputField from "../forms/InputField";
import Country_comp from "../Country_comp";

const MobileOperatorModal = (props: any) => {
  const {
    isMobileOperator,
    openModal,
    transfer,
    t,
    allMobileOperators,
    handleChange,
    setotherOperator,
    values,
    handleMobileOperator,
    otherOperator,
  } = props;

  const areMobileOperatorsPresent = useMemo(() => {
    return (
      transfer?.getAllMobileOperator?.filter((val: any) => val.providers) > 0
    );
  }, [transfer]);

  return (
    <Modal open={isMobileOperator} onClose={openModal}>
      <div
        className={
          areMobileOperatorsPresent
            ? "overflow-y-scroll justify-center row h-1/2 mt-2"
            : "overflow-y-scroll justify-center row mt-2"
        }
      >
        <div
          className={
            areMobileOperatorsPresent
              ? "p-4 bg-white rounded col-md-4"
              : "p-4 bg-white rounded flex flex-column col-md-6"
          }
        >
          <div className="my-2 flex justify-center relative items-center text-center">
            <p>{t("Select_Mobile_Operator")}</p>
            <button
              onClick={() => openModal()}
              className="flex absolute right-0 items-center justify-center shadow-lg h-[40px] w-[40px] rounded-full"
            >
              <i className="m-0 fa fa-close"></i>
            </button>
          </div>

          <div
            className={
              areMobileOperatorsPresent
                ? "row"
                : "row flex my-3 justify-center items-center gap-2 bacnkCheckBoxWrapper p-2 m-0"
            }
          >
            {allMobileOperators?.map((item: any, index: number) => (
              <div
                className="wrapperBankCheckBox innerBankBox marg-0"
                style={{ opacity: 1 }}
                key={index + item?.name}
              >
                <div className="wrapperBankCheckBoxwrapper">
                  <input
                    type="checkbox"
                    className="default:ring-2"
                    onChange={(e) => {
                      handleMobileOperator(item.name);
                      if (e.target.checked) {
                        handleChange("operator")(item.name);
                      } else {
                        handleChange("operator")("");
                      }
                      if (item.name == "Other" && e.target.checked) {
                        setotherOperator("");
                      }
                    }}
                    checked={item.checked}
                  />
                  <span className="checkmark"></span>
                </div>

                {item.name == "Other" ? (
                  item.checked ? (
                    <InputField
                      name="operator"
                      extraClasses="mb-3 customInputStyle w-100 !px-0 !opacity-100"
                      handleChange={(e: any) => {
                        setotherOperator(e.target.value);
                        values.operator = e.target.value;
                      }}
                      placeholder={t("Other")}
                      value={otherOperator}
                    />
                  ) : (
                    <label>
                      <Country_comp eng_country_name={t("Other")} />
                    </label>
                  )
                ) : (
                  <label>
                    <Country_comp eng_country_name={item?.name} />
                  </label>
                )}
              </div>
            ))}

            {/* no operator */}
            {allMobileOperators.length < 1 && (
              <small className="font-bold mb-4 text-gray-600 flex justify-center items-center">
                {t("No_Operator_Found")}
              </small>
            )}
          </div>
          <button
            disabled={
              !(
                values?.operator !== undefined &&
                values?.operator !== null &&
                values?.operator !== ""
              )
            }
            className="w-full rounded-lg btn btn-block"
            style={{
              backgroundColor: "rgb(3, 115, 117)",
              color: "white",
            }}
            onClick={() => openModal()}
          >
            {t("done")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default MobileOperatorModal;
