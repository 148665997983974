const extractError = (error: any) => {
  if (error?.data?.errors !== undefined) {
    const Err = Object.values(error.data.errors);
    return Err.join(", ");
  } else {
    return error?.message || error?.data?.message;
  }
};

export { extractError };
