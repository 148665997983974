import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useReducer, useState } from "react";
import { toast } from "material-react-toastify";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import InputField from "../../components/forms/InputField";
import {
  getMMAuthenticate,
  getMMProvider,
  getNewStatus,
} from "../../store/features/Transfer/Transfer";
import {
  addBeneficiary,
  openBulkTransferDialog,
  resetTransfer,
  setBeneFound,
  setStep,
  setTLError,
  setTransactionToRepeat,
  setTransferDialogOpen,
  updateBulkTData,
} from "../../store/features/Transfer/TransferSlice";
import { useAppDispatch } from "../../store/hooks";
import { resetAuth } from "../../store/features/Auth/AuthSlice";
import { logout } from "../../store/features/Auth/Auth";
import { buildLimitError } from "../../utilities/help";
import { extractErrorMessage } from "../../helpers";

type stateProps = {
  modalOpen: boolean;
  response: any;
  mm_state: string;
  verifying_top: boolean;
  is_polling: boolean;
  otp_sent: boolean;
  providers: any;
  selected_provider: string;
  otp: any;
  subtitleText: string;
  is_polling_after_ussd: boolean;
  failed_message: string;
};

const initial_state: stateProps = {
  modalOpen: false,
  response: null,
  mm_state: "",
  verifying_top: false,
  is_polling: false,
  is_polling_after_ussd: false,
  otp_sent: false,
  providers: [],
  selected_provider: "",
  otp: null,
  subtitleText: "",
  failed_message: "",
};

const possible_success_states = [
  "transaction.payment_collected",
  "payment_intent.succeeded",
  "transfer.succeeded",
];

const possible_failure_states = [
  "payment_intent.payment_failed",
  "payment.failed",
  "transfer.failed",
];

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_PROVIDERS":
      return { ...state, providers: action.payload };
    case "SET_MODAL":
      return { ...state, modalOpen: action.payload };
    case "UPDATE_MM_STATE":
      return { ...state, mm_state: action.payload };
    case "UPDATE_FAILED_MESSAGE":
      return { ...state, failed_message: action.payload };
    case "UPDATE_POLLING":
      return { ...state, is_polling: action.payload };
    case "UPDATE_POLLING_AFTER_USSD":
      return { ...state, is_polling_after_ussd: action.payload };
    case "UPDATE_OTP_SENT":
      return { ...state, otp_sent: action.payload };
    case "UPDATE_VERIFYING_OTP":
      return { ...state, verifying_top: action.payload };
    case "UPDATE_RESPONSE":
      return { ...state, response: action.payload };
    case "SET_SELECTED_PROVIDER":
      return { ...state, selected_provider: action.payload };
    case "SET_OTP":
      return { ...state, otp: action.payload };
    case "SET_SUBTITLE":
      return { ...state, subtitleText: action.payload };
    default:
      return state;
  }
};

const MobileMoney: React.FC<any> = ({ response }) => {
  const { t } = useTranslation();
  const appDispatch = useAppDispatch(); // global state dispatcher
  const [state, dispatch] = useReducer(reducer, initial_state);
  const history = useHistory();
  const [importedResponse, setImportedResponse] = useState(response);

  const pollingForNewStatus = () => {
    const payment_id =
      importedResponse?.details?.id || importedResponse?.payment?.id;
    const first_call_time = new Date().getTime();
    return new Promise((resolve, rej) => {
      (async function requestStatus() {
        const updated_time = new Date().getTime();
        const difference = updated_time - first_call_time;
        const minutes = Math.round(difference / 60000);
        if (minutes === 1) {
          dispatch({ type: "UPDATE_MM_STATE", payload: "failed" });
          dispatch({
            type: "UPDATE_FAILED_MESSAGE",
            payload: "failed_message",
          });
          return;
        }

        const result: any = await getNewStatus(payment_id);

        const length = result.length;
        const current = length > 0 ? result[length - 1] : {};
        if (["action_required", "payment_required"].includes(current.status)) {
          resolve(result[length - 1]);
          return;
        } else if (possible_success_states.includes(current.type)) {
          dispatch({ type: "UPDATE_POLLING", payload: false });
          dispatch({ type: "UPDATE_POLLING_AFTER_USSD", payload: false });
          dispatch({ type: "UPDATE_MM_STATE", payload: "success" });
          return;
        } else if (possible_failure_states.includes(current.type)) {
          dispatch({ type: "UPDATE_POLLING", payload: false });
          dispatch({ type: "UPDATE_POLLING_AFTER_USSD", payload: false });
          dispatch({ type: "UPDATE_MM_STATE", payload: "failed" });
          dispatch({
            type: "UPDATE_FAILED_MESSAGE",
            payload: current.details.message,
          });
        } else {
          setTimeout(() => {
            requestStatus();
          }, 1000);
        }
      })();
    });
  };

  const pollingForSuccessOrFail = () => {
    const payment_id =
      importedResponse?.details?.id || importedResponse?.payment?.id;
    const first_call_time = new Date().getTime();
    return new Promise((resolve, rej) => {
      (async function requestStatus() {
        const updated_time = new Date().getTime();
        const difference = updated_time - first_call_time;
        const minutes = Math.round(difference / 60000);
        if (minutes === 1) {
          dispatch({ type: "UPDATE_MM_STATE", payload: "failed" });
          dispatch({
            type: "UPDATE_FAILED_MESSAGE",
            payload: "failed_message",
          });
          return;
        }

        const result: any = await getNewStatus(payment_id);

        const length = result.length;
        const current = length > 0 ? result[length - 1] : {};
        if (possible_success_states.includes(current.type)) {
          resolve(current);
          dispatch({ type: "UPDATE_POLLING", payload: false });
          dispatch({ type: "UPDATE_POLLING_AFTER_USSD", payload: false });
          dispatch({ type: "UPDATE_MM_STATE", payload: "success" });
          return;
        } else if (possible_failure_states.includes(current.type)) {
          dispatch({ type: "UPDATE_POLLING", payload: false });
          dispatch({ type: "UPDATE_POLLING_AFTER_USSD", payload: false });
          dispatch({ type: "UPDATE_MM_STATE", payload: "failed" });
          dispatch({
            type: "UPDATE_FAILED_MESSAGE",
            payload: current.details.message,
          });
        } else {
          setTimeout(() => {
            requestStatus();
          }, 5000);
        }
      })();
    });
  };

  const verifyMMOtp = () => {
    const { response, otp } = state;

    if (otp && otp.trim().length < 4) {
      return toast.error(t("otp_required"));
    }

    dispatch({ type: "UPDATE_VERIFYING_OTP", payload: true });
    const payload = {
      payment_id:
        importedResponse?.payment?.id || importedResponse?.details?.id,
      otp: otp.trim(),
    };

    getMMAuthenticate(payload)
      .then((res: any) => {
        dispatch({ type: "UPDATE_VERIFYING_OTP", payload: false });
        if (
          (res.status && res.status === "successful") ||
          possible_success_states.includes(res.type)
        ) {
          dispatch({ type: "UPDATE_MM_STATE", payload: "success" });
          dispatch({ type: "UPDATE_OTP_SENT", payload: false });
          dispatch({
            type: "UPDATE_FAILED_MESSAGE",
            payload: response.details.message,
          });
        } else if ("lastPaymentFailure" in res) {
          dispatch({ type: "UPDATE_MM_STATE", payload: "cancelled" });
          dispatch({ type: "UPDATE_OTP_SENT", payload: false });
          dispatch({
            type: "SET_SUBTITLE",
            payload: res.lastPaymentFailure.message,
          });
        }
      })
      .catch((error: any) => {
        toast.warning(t(error?.data?.message));
        Sentry.captureException(error);
        // toast.error(t(error?.data?.message));
        dispatch({ type: "UPDATE_VERIFYING_OTP", payload: false });
      });
  };

  const sendMMOtp = () => {
    dispatch({ type: "UPDATE_VERIFYING_OTP", payload: true });
    dispatch({ type: "UPDATE_OTP_SENT", payload: false });

    const payload = {
      payment_id:
        importedResponse?.payment?.id || importedResponse?.details?.id,
      selected_provider: state.selected_provider,
    };

    getMMProvider(payload)
      .then(async (res: any) => {
        dispatch({ type: "UPDATE_VERIFYING_OTP", payload: false });

        if (res.status === 403) {
          toast.success(t(res.error.message));
        } else {
          if (
            res.status === "action_required" &&
            res?.nextAction?.type == "otp"
          ) {
            dispatch({ type: "UPDATE_OTP_SENT", payload: true });
            dispatch({ type: "SET_PROVIDERS", payload: [] });
          } else if (res.status === "payment_required") {
            toast.success(t("wait_for_prompt_on_your_phone"));
            dispatch({ type: "SET_PROVIDERS", payload: [] });
            dispatch({ type: "UPDATE_MM_STATE", payload: "pending_mm_pin" });
            dispatch({
              type: "UPDATE_FAILED_MESSAGE",
              payload: res?.nextAction.message,
            });
          } else if (res.status === "processing") {
            dispatch({ type: "UPDATE_POLLING", payload: true });
            dispatch({ type: "SET_PROVIDERS", payload: [] });

            // perform a polling for the new status
            const poll_response: any = await pollingForNewStatus();
            dispatch({
              type: "SET_SUBTITLE",
              payload: poll_response.next_action.message,
            });

            if (poll_response.status === "action_required") {
              if (poll_response.next_action.type.toLowerCase() === "otp") {
                dispatch({ type: "SET_PROVIDERS", payload: [] });
                dispatch({ type: "UPDATE_POLLING", payload: false });
                dispatch({ type: "UPDATE_OTP_SENT", payload: true });
                dispatch({
                  type: "SET_SUBTITLE",
                  payload: poll_response.next_action.message,
                });
              } else if (
                poll_response.next_action.type.toLowerCase() === "ussd"
              ) {
                pollingForSuccessOrFail();
                dispatch({ type: "SET_PROVIDERS", payload: [] });
                dispatch({ type: "UPDATE_POLLING", payload: false });
                dispatch({ type: "UPDATE_POLLING_AFTER_USSD", payload: true });
                toast.success(t("wait_for_prompt_on_your_phone"));
                dispatch({
                  type: "UPDATE_MM_STATE",
                  payload: "pending_mm_pin",
                });
                dispatch({
                  type: "SET_SUBTITLE",
                  payload: poll_response.next_action.message,
                });
              }
            } else if (poll_response.status === "payment_required") {
              pollingForSuccessOrFail();
              dispatch({ type: "SET_PROVIDERS", payload: [] });
              dispatch({ type: "UPDATE_POLLING", payload: false });
              toast.success(t("wait_for_prompt_on_your_phone"));
              dispatch({
                type: "SET_SUBTITLE",
                payload: poll_response.next_action.message,
              });
              dispatch({ type: "UPDATE_MM_STATE", payload: "pending_mm_pin" });
            } else if (poll_response.status === "successful") {
              dispatch({ type: "UPDATE_MM_STATE", payload: "success" });
              dispatch({ type: "SET_PROVIDERS", payload: [] });
            }
          } else if (res.status === "successful") {
            dispatch({ type: "SET_PROVIDERS", payload: [] });
            dispatch({ type: "UPDATE_POLLING", payload: false });
            dispatch({ type: "UPDATE_POLLING_AFTER_USSD", payload: false });
            dispatch({ type: "UPDATE_MM_STATE", payload: "success" });
          }
        }
      })
      .catch((error: any) => {
        dispatch({ type: "UPDATE_VERIFYING_OTP", payload: false });
        toast.error(t(extractErrorMessage(error)));

        Sentry.captureException(error);
      });
  };

  const cancelPayment = () => {
    goBackHome(true);
  };

  const goBackHome = (cancelling = false) => {
    const payment_id =
      importedResponse?.payment?.id || importedResponse?.details?.id;
    localStorage.removeItem("appref");

    const id = localStorage.getItem("payment_id");
    const mm_link = localStorage.getItem("ext_mm_process_link");
    appDispatch(setBeneFound(false));
    appDispatch(addBeneficiary(null));
    appDispatch(setStep(0));
    appDispatch(setTLError(null));
    appDispatch(setTransactionToRepeat(null));
    appDispatch(setTransferDialogOpen(false));
    appDispatch(openBulkTransferDialog("close"));
    appDispatch(updateBulkTData({ users: {} }));
    localStorage.removeItem("mobile_response");
    // handle logout for external payments
    if (id !== null || mm_link !== null) {
      localStorage.removeItem("payment_id");
      localStorage.removeItem("ext_mm_process_link");
      localStorage.removeItem("payment_details");
      logout()
        .then((res: any) => {
          localStorage.removeItem("user:key");
          dispatch(resetAuth());
          if (cancelling) {
            window.location?.replace(response?.payment?.data?.cancel_url);
          } else {
            window.location?.replace(
              `${response?.payment?.data?.redirect_url}?payment_id=${payment_id}`
            );
          }
        })
        .catch((error: any) => {
          Sentry.captureException(error);
        });
    } else {
      history.push("/");
    }
  };

  useEffect(() => {
    const store: any = localStorage.getItem("mobile_response");
    if (store) {
      const response = JSON.parse(store);
      dispatch({ type: "UPDATE_RESPONSE", payload: response });
      const mmProviders =
        response?.select_providers || response.data.available_providers;
      if (mmProviders) {
        const provider_array = Object.values(mmProviders);
        dispatch({ type: "SET_MODAL", payload: true });
        dispatch({ type: "SET_PROVIDERS", payload: provider_array });
        dispatch({ type: "UPDATE_MM_STATE", payload: "" });
        dispatch({ type: "UPDATE_OTP_SENT", payload: false });
      }
    }
  }, []);

  return (
    <div className="h-screen">
      <div
        className="left-0 right-0 flex flex-row items-center justify-between px-4 py-2 fixed-top"
        style={{ backgroundColor: "#037375" }}
      >
        <p className="font-bold text-white">Mobile Money</p>
        <button
          onClick={() => cancelPayment()}
          style={{ height: 40, width: 40, borderRadius: 20 }}
          className="flex items-center justify-center shadow-lg"
        >
          <i className="m-0 text-white fa fa-close"></i>
        </button>
      </div>
      <div className="flex flex-col justify-center h-full bg-gray-100 bg-opacity-20">
        <div className="row">
          <div className="col-md-6 mx-auto">
            <div className="flex flex-col justify-center p-10 bg-white rounded-lg shadow-lg">
              {/* //polling view */}
              {state.is_polling === true && (
                <>
                  <div className="p-2 mx-auto">
                    <CircularProgress size={40} />
                  </div>
                  <p className="mt-4 mb-1 text-2xl font-bold text-center">
                    {t("Processing")}
                  </p>
                  <small className="mb-4 text-center">
                    {t("processing_text")}
                  </small>
                </>
              )}

              {/* //otp view */}
              {state.otp_sent && (
                <>
                  <h1 className="my-2 text-2xl font-bold text-center">
                    {t("OTP_Verification")}
                  </h1>
                  <p className="my-1 text-center">
                    <small>{state.subtitleText}</small>
                  </p>
                  <div className="bg-white">
                    <InputField
                      name="mmOtp"
                      handleChange={(text: any) =>
                        dispatch({
                          type: "SET_OTP",
                          payload: text.target.value,
                        })
                      }
                      onBlur={() => null}
                      value={state.otp}
                      label={t("EnterOTP")}
                      error={""}
                      touched={true}
                    />
                    {state.verifying_top ? (
                      <div className="flex items-center justify-center p-2">
                        <p className="text-gray-600">
                          <small>{t("Please_wait")}</small>
                        </p>
                      </div>
                    ) : (
                      <button
                        onClick={() => verifyMMOtp()}
                        style={{ backgroundColor: "rgb(3, 115, 117)" }}
                        className="w-full px-10 my-4 rounded-lg btn btn-block"
                      >
                        <small className="text-white">{t("continue")}</small>
                      </button>
                    )}
                  </div>
                </>
              )}

              {/* // select providers view */}
              {state.providers.length > 0 && (
                <>
                  <b className="mb-2 text-2xl text-center">
                    {t("select_provider")}
                  </b>
                  <small className="text-center">
                    {t("select_provider_text")}
                  </small>{" "}
                  <br />
                  <div className="flex flex-col my-3">
                    <select
                      className="px-2 py-2 rounded border-1"
                      onChange={(e: any) =>
                        dispatch({
                          type: "SET_SELECTED_PROVIDER",
                          payload: e.target.value,
                        })
                      }
                    >
                      <option>---</option>
                      {state.providers.map((val: any, index: number) => (
                        <option value={val.name.toLowerCase()}>
                          {val.name}
                        </option>
                      ))}
                    </select>

                    {state.verifying_top ? (
                      <div className="flex items-center justify-center p-2 my-4">
                        <p className="text-gray-600">
                          <small>{t("Please_wait")}</small>
                        </p>
                      </div>
                    ) : (
                      <>
                        <button
                          className="px-10 py-2 my-4 rounded-md"
                          onClick={sendMMOtp}
                          style={{
                            backgroundColor: "#027375",
                          }}
                        >
                          <small className="font-bold text-white">
                            {t("continue")}
                          </small>
                        </button>

                        <button
                          className="px-10 py-2 my-1 rounded-md"
                          onClick={cancelPayment}
                          style={{
                            backgroundColor: "#f1f1f1",
                          }}
                        >
                          <small className="font-bold text-gray-700">
                            {t("cancel")}
                          </small>
                        </button>
                      </>
                    )}
                  </div>
                </>
              )}

              {/* // success */}
              {state.mm_state === "cancelled" && (
                <>
                  <img
                    src="../close.png"
                    style={{ height: 80, width: 80, margin: 20 }}
                    className="mx-auto"
                  />
                  <p className="py-2 text-2xl font-bold text-center capitalize">
                    {t("payment_verification_failed")}
                  </p>
                  <p className="py-2 text-sm text-center text-gray-600">
                    <small>{state.subtitleText}</small>
                  </p>
                  <button
                    onClick={() => goBackHome(false)}
                    className="my-4 btn"
                    style={{ backgroundColor: "rgb(3, 115, 117)" }}
                  >
                    <small className="font-bold text-white ">
                      {t("backHome")}
                    </small>
                  </button>
                </>
              )}

              {state.mm_state === "failed" && (
                <>
                  <img
                    src="../close.png"
                    style={{ height: 80, width: 80, margin: 20 }}
                    className="mx-auto"
                  />
                  <p className="py-2 text-2xl font-bold text-center capitalize">
                    {t("payment_verification_failed")}
                  </p>
                  <p className="py-2 text-sm text-center text-gray-600">
                    <small>{state.subtitleText}</small>
                  </p>
                  <button
                    onClick={() => goBackHome(false)}
                    className="my-4 btn"
                    style={{ backgroundColor: "rgb(3, 115, 117)" }}
                  >
                    <small className="font-bold text-white ">
                      {t("backHome")}
                    </small>
                  </button>
                </>
              )}

              {/* // cancelled */}
              {state.mm_state === "pending_mm_pin" && (
                <>
                  <img
                    src="../phone-alert.png"
                    style={{ height: 80, width: 80, margin: 20 }}
                    className="mx-auto"
                  />
                  <p className="py-2 text-2xl font-bold text-center capitalize">
                    {t("payment_verification_pending")}
                  </p>
                  <p className="py-2 text-sm text-center text-gray-600">
                    <small>{state.subtitleText}</small>
                  </p>
                  {state.is_polling_after_ussd ? (
                    <div className="p-2 mx-auto">
                      <CircularProgress size={25} />
                    </div>
                  ) : (
                    <button
                      onClick={() => goBackHome(true)}
                      className="my-4 btn"
                      style={{ backgroundColor: "rgb(3, 115, 117)" }}
                    >
                      <small className="font-bold text-white ">
                        {t("backHome")}
                      </small>
                    </button>
                  )}
                </>
              )}

              {/* // pending  */}
              {state.mm_state === "success" && (
                <>
                  <img
                    src="../check.png"
                    style={{ height: 80, width: 80, margin: 20 }}
                    className="mx-auto"
                  />
                  <p className="py-2 text-2xl font-bold text-center capitalize">
                    {t("mm_success_title")}
                  </p>
                  <p className="py-2 text-sm text-center text-gray-600">
                    {t("mm_success_description")}
                  </p>
                  <button
                    onClick={() => goBackHome(false)}
                    className="my-4 btn"
                    style={{ backgroundColor: "rgb(3, 115, 117)" }}
                  >
                    <small className="font-bold text-white ">
                      {t("backHome")}
                    </small>
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMoney;
