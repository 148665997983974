import * as React from "react";
import Box from "@material-ui/core/Box";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/browser";
import "../../helpers/i18n";
import {
  initiateCashOut,
  confirmCashOut,
  currentBalance,
  getAppliedFeesForWithdraws,
  getUsersMFIAccounts,
} from "../../store/features/Transfer/Transfer";
import { toast } from "material-react-toastify";
import { extractError } from "../../utility";
import { useHistory } from "react-router";
import { getGoogleSheetData, translateError } from "../../utilities/help";
import {
  clearSelectedBank,
  setSelectedBank,
} from "../../store/features/Auth/AuthSlice";
import {
  openWithdraw,
  setWithDrawData,
} from "../../store/features/Transfer/TransferSlice";
import WithdrawAmountModal from "../../components/Withdraws/WithdrawAmountModal";
import WithdrawPaymentModal from "../../components/Withdraws/WithdrawPaymentModal";
import WithdrawVerificationModal from "../../components/Withdraws/WithdrawVerificationModal";
import WithdrawSuccessModal from "../../components/Withdraws/WithdrawSuccessModal";
import useGoogleSheets from "use-google-sheets";

const RATE = 655.957;

const Cashout: React.FC<any> = ({ setWithDrawOpen }) => {
  const { t } = useTranslation();
  const {
    auth: { user, userBalance, selected_account, lang },
    transfer: { loggedInUserCountry },
  } = useAppSelector((state) => state.persistedReducer);
  const dispatch = useAppDispatch();

  const [steps, setSteps] = React.useState([
    t("amount"),
    t("PaymentMode"),
    t("verification"),
    t("Confirmation"),
  ]);

  const {
    auth: { currency, rate },
    transfer,
  } = useAppSelector((state) => state.persistedReducer);
  const [toWithDraw, setToWithDraw] = React.useState<any>(
    transfer?.withdraw?.toWithDraw || 0
  );
  const [loading, setLoading] = React.useState(false);
  const [otp, setOTP] = React.useState(0);
  const [fees, setFees] = React.useState(0);
  const [type, setType] = React.useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [error, setError] = React.useState(null);
  const [cashoutResponse, setCashoutResponse] = React.useState<any>(null);
  const [cashoutMethod, setCashoutMethod] = React.useState<any[]>([]);
  const [method, setMethod] = React.useState<any>(null);
  const [euro_balance, setEuroBalance] = React.useState<any>(0);
  const [size, setSize] = React.useState<any>([0, 0]);
  const [repeatWithDraw, setRepeatWithDraw] = React.useState<boolean>(false);
  const [c_channel, setCommunicationChannel] = React.useState(
    transfer?.loggedInUserCountry?.preferred_notification_channel ?? "mail"
  );
  const history = useHistory();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handlePrev = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const showToast = (message: string, type = "error") => {
    toast.error(t(message));
  };

  const setTypeFromPaymentType = (type: any) => {
    setRepeatWithDraw(false);
    if (type === "manual_bank_transfer" || type === "bank_transfer") {
      setType("bank");
    } else if (type === "mobile_money") {
      setType("mobile");
    } else if (type === "mfi") {
      setType("mfi");
    }
  };

  // used for withdraw repeat
  const getMethods = async (id?: any) => {
    try {
      const methods = transfer.loggedInUserCountry.cashout_methods;

      // show all providers for dp-1119
      // set bank payout as default method
      if (methods.length > 0) {
        const isCashoutPresent = methods.find(
          (method: any) => method?.cashout_method?.id == id
        );
        if (id !== undefined && id !== null && isCashoutPresent !== undefined) {
          // set provider selection with option selected
          setCashoutMethod(
            methods.map((itm: any) =>
              itm?.cashout_method?.id == id
                ? { ...itm, isChecked: true }
                : { ...itm, isChecked: false }
            )
          );
          // set cashin in method id
          setMethod(id);
        } else {
          // set first option as default selected
          setCashoutMethod(
            methods.map((itm: any, itmInd: any) =>
              itmInd == 0
                ? { ...itm, isChecked: true }
                : { ...itm, isChecked: false }
            )
          );
          const firstMethod = methods[0];
          setTypeFromPaymentType(
            firstMethod?.cashout_method?.payment_type?.name
          );
          setMethod(firstMethod?.cashout_method?.id);
          dispatch(clearSelectedBank());
        }
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  // on submit
  const startCashOut = () => {
    try {
      setError(null);
      if (method === "") {
        showToast(t("select_cashOut"));
        return;
      }
      if (selected_account === null && type !== "delivery") {
        showToast(t("select_account"));
        return;
      }
      if (parseFloat(euro_balance) <= 0) {
        showToast(t("balance_zero"));
        return;
      }
      const amount_in_euros =
        currency === "CFA" ? toWithDraw / rate : toWithDraw;
      const local_amount = currency === "CFA" ? toWithDraw : toWithDraw * RATE;

      if (amount_in_euros > parseFloat(euro_balance)) {
        showToast(t("Withdrawal_amount"));
        return;
      }

      setLoading(true);
      let finalpayload = {};
      if (type == "mfi") {
        finalpayload = {
          amount_without_fees_in_euro: Number(amount_in_euros),
          cashout_method_id: Number(method),
          mfi_account_id: selected_account.id,
          mobile_money_account_id: null,
          local_amount,
          local_currency: "CFA",
          country_id: loggedInUserCountry.id,
        };
      } else if (type == "bank") {
        finalpayload = {
          amount_without_fees_in_euro: Number(amount_in_euros),
          cashout_method_id: Number(method),
          bank_account_id: selected_account.id,
          mobile_money_account_id: null,
          local_amount,
          local_currency: "CFA",
          country_id: loggedInUserCountry.id,
        };
      } else if (type == "mobile") {
        finalpayload = {
          amount_without_fees_in_euro: Number(amount_in_euros),
          cashout_method_id: Number(method),
          bank_account_id: null,
          mobile_money_account_id: selected_account.id,
          local_amount,
          local_currency: "CFA",
          country_id: loggedInUserCountry.id,
        };
      } else if (type == "delivery") {
        finalpayload = {
          amount_without_fees_in_euro: Number(amount_in_euros),
          cashout_method_id: Number(method),
          local_amount,
          local_currency: "CFA",
          country_id: loggedInUserCountry.id,
          delivery_receiver_id: user?.id,
        };
      }

      initiateCashOut(finalpayload)
        .then((response: any) => {
          setCashoutResponse(response);
          setLoading(false);
          handleNext();
          window.heap.track("Account to withdraw details", {
            email: user.email,
            country: user.country,
            phone: user.full_phone_number,
            name: user.full_name,
          });
        })
        .catch((error: any) => {
          setLoading(false);
          toast.error(translateError(extractError(error), t));

          Sentry.captureException(error);
        });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const confirmOTP = () => {
    try {
      setLoading(true);
      confirmCashOut({
        key_code: otp,
        id: cashoutResponse?.cashout.id,
      })
        .then(() => {
          setLoading(false);
          handleNext();
          window.heap.track("Withdraw confirmation", {
            email: user.email,
            country: user.country,
            phone: user.full_phone_number,
            name: user.full_name,
          });
        })
        .catch((error: any) => {
          setLoading(false);
          toast.error(translateError(extractError(error), t));

          Sentry.captureException(error);
        });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const calculatedFees = () => {
    if (toWithDraw < 1) {
      return;
    }
    try {
      setLoading(true);
      getAppliedFeesForWithdraws(
        {
          euro_amount: currency === "EUR" ? toWithDraw : toWithDraw / RATE,
          country_id: loggedInUserCountry?.id,
          cashout_method_id: method,
        },
        user?.id
      )
        .then((response: any) => {
          setFees(response.fee);
        })
        .catch((error: any) => {
          toast.warning(t(error?.data?.message));
          Sentry.captureException(error);
        })
        .finally(() => setLoading(false));
    } catch (error: any) {
      setLoading(false);
      Sentry.captureException(error);
    }
  };
  const renderSection = () => {
    if (activeStep === 0) {
      return (
        <WithdrawAmountModal
          loading={loading}
          size={size}
          steps={steps}
          t={t}
          userBalance={userBalance}
          toWithDraw={toWithDraw}
          setToWithDraw={setToWithDraw}
          euro_balance={euro_balance}
          rate={rate}
          fees={fees}
          currency={currency}
          handleNext={handleNext}
          user={user}
        />
      );
    }

    if (activeStep === 1) {
      return (
        <WithdrawPaymentModal
          setCashoutMethod={setCashoutMethod}
          size={size}
          steps={steps}
          t={t}
          checkingForRestriction={checkingForRestriction}
          dispatch={dispatch}
          setType={setType}
          setMethod={setMethod}
          type={type}
          startCashOut={startCashOut}
          isUserRestricted={isUserRestricted}
          setRepeatWithDraw={setRepeatWithDraw}
          setSelectedBank={setSelectedBank}
          selected_account={selected_account}
          cashoutMethod={cashoutMethod}
          loading={loading}
          handlePrev={handlePrev}
          error={error}
        />
      );
    }

    if (activeStep === 2) {
      return (
        <WithdrawVerificationModal
          size={size}
          steps={steps}
          t={t}
          c_channel={c_channel}
          setCommunicationChannel={setCommunicationChannel}
          setOTP={setOTP}
          cashoutResponse={cashoutResponse}
          lang={lang}
          handlePrev={handlePrev}
          confirmOTP={confirmOTP}
        />
      );
    }

    if (activeStep === 3) {
      return (
        <WithdrawSuccessModal
          size={size}
          steps={steps}
          t={t}
          dispatch={dispatch}
          history={history}
        />
      );
    }
  };

  const getBalance = async () => {
    try {
      const balance: any = await currentBalance();
      const bal = balance?.client.euro_balance;
      setEuroBalance(bal);
    } catch (error: any) {
      toast.error(t(extractError(error)));
    }
  };

  React.useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  React.useEffect(() => {
    getBalance();
    getMethods();
    getUsersMFIAccounts();
  }, []);

  React.useEffect(() => {
    if (method !== null) {
      calculatedFees();
    }
  }, [method, toWithDraw]);

  // check if user is restriced
  const [isUserRestricted, setisUserRestricted] = React.useState(false);
  const [checkingForRestriction, setcheckingForRestriction] =
    React.useState(false);

  // setter for bank with methodid
  const findAndSetBank = () => {
    try {
      if (transfer?.loggedInUserCountry?.cashout_methods !== undefined) {
        const cashoutMethods = transfer.loggedInUserCountry.cashout_methods;
        const banktransferMethod =
          cashoutMethods &&
          cashoutMethods.find(
            (methodItm: any) =>
              methodItm?.cashout_method?.payment_type?.name ==
                "bank_transfer" ||
              methodItm?.cashout_method?.payment_type?.name ==
                "manual_bank_transfer"
          );
        setType("bank");
        setRepeatWithDraw(false);

        banktransferMethod && setMethod(banktransferMethod?.cashout_method?.id);
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const {
    data: googleSheetData,
    loading: googleSheetsLoading,
    error: googleSheetError,
  } = useGoogleSheets({
    apiKey: process.env.REACT_APP_GOOGLE_SHEET_API_ID!,
    sheetId: process.env.REACT_APP_GOOGLE_SHEET_ID!,
  });

  React.useEffect(() => {
    if (googleSheetError) {
      Sentry.captureException(googleSheetError);
    }
  }, [googleSheetError]);

  // const handleUserRestriction = (handleRepeatCallback?: any) => {
  //   // dont run on closed window
  //   if (transfer?.isWithdrawOpen !== true) {
  //     return;
  //   }
  //   try {
  //     setcheckingForRestriction(true);
  //     const doc = getGoogleDoc();

  //     if (doc !== undefined) {
  //       getGoogleSheetRowData(doc)
  //         .then((restrictedEmails: any) => {
  //           if (restrictedEmails == undefined) {
  //             return;
  //           }
  //           if (restrictedEmails.includes(user?.email)) {
  //             setisUserRestricted(true);

  //             // reset selected account in case of non bank method
  //             if (!(transfer?.withdraw?.withDrawMethod === "bank_transfer")) {
  //               dispatch(clearSelectedBank());
  //             }

  //             // set type as bank, repeat false, set method id
  //             findAndSetBank();
  //           } else {
  //             setisUserRestricted(false);
  //             handleRepeatCallback && handleRepeatCallback();
  //           }
  //         })
  //         .catch((error: any) => {
  //           toast.warning(t(error?.data?.message));
  //           Sentry.captureException(error);
  //         })
  //         .finally(() => {
  //           setcheckingForRestriction(false);
  //           handleRepeatCallback && handleRepeatCallback();
  //         });
  //     }
  //   } catch (error: any) {
  //     setcheckingForRestriction(false);
  //     handleRepeatCallback && handleRepeatCallback();

  //   }
  // };

  const handleUserRestriction = (handleRepeatCallback?: any) => {
    // dont run on closed window
    if (transfer?.isWithdrawOpen !== true || googleSheetsLoading) {
      return;
    }
    try {
      setcheckingForRestriction(true);

      let restrictedEmails: any = getGoogleSheetData(googleSheetData);
      if (restrictedEmails == undefined) {
        setcheckingForRestriction(false);
        return;
      }
      if (restrictedEmails.includes(user?.email)) {
        setisUserRestricted(true);

        // reset selected account in case of non bank method
        if (!(transfer?.withdraw?.withDrawMethod === "bank_transfer")) {
          dispatch(clearSelectedBank());
        }

        // set type as bank, repeat false, set method id
        findAndSetBank();
        setcheckingForRestriction(false);
      } else {
        setisUserRestricted(false);
        setcheckingForRestriction(false);
        handleRepeatCallback && handleRepeatCallback();
      }
    } catch (error: any) {
      Sentry.captureException(error);
      setcheckingForRestriction(false);
      handleRepeatCallback && handleRepeatCallback();
    }
  };

  const handleRepeatOperation = () => {
    if (transfer?.withdraw?.withDrawMethod === "mobile_money") {
      setRepeatWithDraw(true);
      setType("mobile");
      getMethods(transfer?.withdraw?.cashoutMethod?.id);
    } else if (
      transfer?.withdraw?.withDrawMethod === "manual_bank_transfer" ||
      transfer?.withdraw?.withDrawMethod === "bank_transfer"
    ) {
      setRepeatWithDraw(true);
      setType("bank");
      getMethods(transfer?.withdraw?.cashoutMethod?.id);
    } else if (transfer?.withdraw?.withDrawMethod === "mfi") {
      setRepeatWithDraw(true);
      setType("mfi");
      getMethods(transfer?.withdraw?.cashoutMethod?.id);
    } else if (transfer?.withdraw?.withDrawMethod === "delivery") {
      setRepeatWithDraw(true);
      setType("delivery");
      getMethods(transfer?.withdraw?.cashoutMethod?.id);
    }
  };

  // repeat operation and handling restriction
  React.useEffect(() => {
    // set cashout method id
    // set type
    // set repeat on
    // also check for restriction
    if (transfer?.withdraw?.withDrawMethod !== "") {
      handleUserRestriction(handleRepeatOperation());
    } else {
      handleUserRestriction();
    }
  }, [transfer?.withdraw?.withDrawMethod, googleSheetsLoading]);

  return (
    <div className="cashoutdiv h-full">
      <div
        className="px-1 py-2 overflow-y-scroll"
        style={{ backgroundColor: "rgb(3, 115, 117)" }}
      >
        <div className="container flex flex-row items-center justify-between">
          <span className="font-bold text-white">
            {t("Enter_Withdraw_details")}
          </span>

          <button
            id="withdraw-cancel"
            data-wthdraw-cancel="withdraw-cancel"
            onClick={() => {
              dispatch(clearSelectedBank());
              window.heap.track("Withdraw cancel", {
                email: user.email,
                country: user.country,
                phone: user.full_phone_number,
                name: user.full_name,
              });
              dispatch(openWithdraw(false));
              dispatch(
                setWithDrawData({
                  toWithDraw: 0,
                })
              );
            }}
            style={{ height: 40, width: 40, borderRadius: 20 }}
            className="flex items-center justify-center shadow-lg"
          >
            <i
              id="withdraw-cancel"
              className="m-0 text-white fa fa-close"
              data-wthdraw-cancel="withdraw-cancel"
            ></i>
          </button>
        </div>
      </div>
      <Box className="h-full" style={{ width: "100%" }}>
        {size[0] > 900 ? (
          <div className="container">
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                } = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
        ) : null}

        {activeStep === steps.length ? (
          <React.Fragment />
        ) : (
          <div className="overflow-y-scroll" style={{ height: "inherit" }}>
            {renderSection()}
          </div>
        )}
      </Box>
    </div>
  );
};

export default Cashout;
