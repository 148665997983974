import React, { useState } from "react";
import bg from "../../images/danapay_login.png";
import bg_en from "../../images/danapay_login_en.png";
import { resendEmail } from "../../store/features/Auth/Auth";
import { useAppSelector } from "../../store/hooks";
import * as Sentry from "@sentry/browser";
import LangSwitch from "../../components/LangSwitch";
import { useTranslation } from "react-i18next";
import "../../helpers/i18n";
import { toast } from "material-react-toastify";
import { buildString } from "../../utilities/help";
import { Link } from "react-router-dom";

const VerifyEMail = () => {
  const { user, lang } = useAppSelector((state) => state.persistedReducer.auth);
  const [processing, setProcessing] = useState(false);
  const { t } = useTranslation();

  const HandleBtnClick = () => {
    setProcessing(true);
    try {
      resendEmail({ email: user?.email })
        .then((result: any) => {
          setProcessing(false);
          const success_message: any = buildString(
            result.message?.toLowerCase()
          );
          toast.success(t(success_message));
        })
        .catch((error: any) => {
          setProcessing(false);
          if (error.status === 422 || error.status === 500) {
            const err: any = Object.values(error.data.errors)[0];
            toast.error(t(err[0]));
          }

          Sentry.captureException(error);
        });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  return (
    <div className="w-screen h-screen overflow-hidden row ">
      <div className="h-screen p-0 col-md-5">
        <div className="flex flex-row justify-between px-4 pt-8 pl-4">
          <LangSwitch />
        </div>
        <div className="flex flex-col items-center justify-center h-full">
          <div style={{ width: "80%" }}>
            <div className="px-4 py-8 m-auto rounded-lg shadow-lg">
              <h2 className="mb-2 text-2xl font-bold">{t("sent_code")}</h2>
              <p className="mb-4 text-sm">{t("click_to_continue")}</p>
              <div className="row">
                <div className="col-md-6">
                  {processing ? (
                    <p>{t("Please_wait")}...</p>
                  ) : (
                    <button
                      className="px-12 btn"
                      onClick={HandleBtnClick}
                      disabled={processing}
                      style={{ backgroundColor: "rgb(3, 115, 117)" }}
                    >
                      <small className="text-white capitalize">
                        {t("Send_New_Link")}
                      </small>
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="py-4 my-8 text-center">
              <small>
                {t("click_here_to")}
                <Link
                  to="/login"
                  className="font-bold rounded"
                  style={{ color: "rgb(3, 115, 117)" }}
                >
                  {t("login")}
                </Link>
              </small>
            </div>
          </div>
        </div>
      </div>
      <div
        className="h-screen p-0 col-md-7"
        style={{
          backgroundImage: `url(${lang === "en" ? bg_en : bg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      ></div>
    </div>
  );
};

export default VerifyEMail;
