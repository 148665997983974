import React, { useEffect, useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";

interface Props {
  name: string;
  handleChange: any;
  value: any;
  label?: string;
  onBlur?: any;
  error?: string;
  disabled?: boolean;
  type?: string;
  touched?: any;
  placeholder?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    input: {
      marginBottom: 10,
      marginTop: 10,
    },
  })
);

const PasswordField: React.FC<Props> = ({
  label,
  name,
  value,
  handleChange,
  onBlur,
  error = null,
  disabled = false,
  type = "password",
  touched = false,
  placeholder,
}) => {
  const isError = error === "" || error === "undefined" ? false : true;
  const [inputType, setInputType] = useState(type);

  return (
    <>
      <div className="w-full mb-1">
        {label && (
          <label className="mb-1 text-sm text-gray-600 md:text-base text-sm">
            <small>{label}</small>
          </label>
        )}
        <div className="flex flex-row">
          <div
            className="flex flex-row w-full border-2"
            style={{
              background: "#fafafa",
              color: "#080707",
              border: "0px",
              padding: "0px 0px",
              margin: "7px 0px",
              borderRadius: "10px",
            }}
          >
            <input
              type={inputType}
              // className="flex-1 w-full p-2 rounded-2"
              className="flex-1 !px-[16px] w-full rounded-2"
              required
              value={value}
              name={name}
              onChange={handleChange}
              disabled={disabled}
              placeholder={placeholder}
              autoComplete="off"
              style={{
                background: "#fafafa",
                color: "#080707",
                border: "0px",
                padding: "0px 0px",
                margin: "7px 0px",
              }}
            />
            <div
              onClick={() => {
                const value = inputType === "text" ? "password" : "text";
                setInputType(value);
              }}
              style={{
                height: 40,
                width: 40,
                background: "#fafafa",
                color: "#080707",
                border: "0px",
                padding: "0px 0px",
                margin: "7px 0px",
              }}
              className="flex items-center justify-center bg-grey cursor-pointer"
            >
              {inputType === "text" ? (
                <i
                  className="text-gray-400 fa fa-eye-slash"
                  style={{
                    backgroundColor: "#fafafa",
                    border: "0px",
                    padding: "0px 0px",
                    margin: "7px 0px",
                  }}
                />
              ) : (
                <i
                  className="text-gray-400 fa fa-eye"
                  style={{
                    background: "#fafafa",
                    border: "0px",
                    padding: "0px 0px",
                    margin: "7px 0px",
                  }}
                />
              )}
            </div>
          </div>
        </div>

        {isError && (
          <small className="mt-2 text-red-400" style={{ fontSize: 11 }}>
            {error}
          </small>
        )}
      </div>
    </>
  );
};

export default PasswordField;
