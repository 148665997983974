import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";

const AccountsInfo = (props: any) => {
  const {
    t,
    setAccountType,
    mfi_accounts,
    hasMMOption,
    mobile_accounts,
    bank_accounts,
    dana_pay_bank_accounts,
    setDPOpen,
    openAccountScreen,
  } = props;
  return (
    <div className="accountisinfo col-lg-5 col-md-8 p-2 pt-4 overflow-y-scroll">
      <div className="my-4">
        <p className="font-bold text-gray-600">{t("manage_accounts")}</p>{" "}
        <small>{t("manage_accounts_sub")}</small>
        <div className="my-4 overflow-y-scroll rounded-lg ">
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {/* mfi accounts */}
                <TableRow
                  className="cursor-pointer hover:bg-gray-100"
                  onClick={() => {
                    setAccountType("mfi");
                    openAccountScreen();
                  }}
                >
                  <TableCell>
                    <div className="flex flex-row items-center justify-between font-bold">
                      <div
                        className=" sender_circle"
                        style={{
                          backgroundColor: "rgb(3, 115, 117)",
                          color: "#fff",
                        }}
                      >
                        {mfi_accounts?.length}
                      </div>
                      {t("mfi_accounts")}
                    </div>
                  </TableCell>
                </TableRow>

                {hasMMOption && (
                  <TableRow
                    className="cursor-pointer hover:bg-gray-100"
                    onClick={() => {
                      setAccountType("mobile");
                      openAccountScreen();
                    }}
                  >
                    <TableCell>
                      <div className="flex flex-row items-center justify-between font-bold">
                        <div
                          className="sender_circle"
                          style={{
                            backgroundColor: "rgb(3, 115, 117)",
                            color: "#fff",
                          }}
                        >
                          {mobile_accounts?.length}
                        </div>
                        {t("mobile_money_accounts")}
                      </div>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow
                  className="cursor-pointer hover:bg-gray-100"
                  onClick={() => {
                    setAccountType("bank");
                    openAccountScreen();
                  }}
                >
                  <TableCell>
                    <div className="flex flex-row items-center justify-between  font-bold">
                      <div
                        className="sender_circle"
                        style={{
                          backgroundColor: "rgb(3, 115, 117)",
                          color: "#fff",
                        }}
                      >
                        {bank_accounts?.length}
                      </div>
                      {t("bank_accounts")}
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      <div className="my-4">
        <p className="font-bold text-gray-600">{t("danapay_accounts")}</p>{" "}
        <small>{t("danapay_accounts_sub")}</small>
        <div className="my-4 overflow-y-scroll rounded-lg ">
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow
                  className="cursor-pointer hover:bg-gray-100"
                  onClick={() => setDPOpen(true)}
                >
                  <TableCell>
                    <div className="flex flex-row items-center justify-between font-bold">
                      <div
                        className="sender_circle"
                        style={{
                          backgroundColor: "rgb(3, 115, 117)",
                          color: "#fff",
                        }}
                      >
                        {dana_pay_bank_accounts?.length}
                      </div>
                      {t("danapay_bank_accounts")}
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default AccountsInfo;
