import { Modal } from "@material-ui/core";
import { Formik } from "formik";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import InputField from "./forms/InputField";
import * as Sentry from "@sentry/browser";
import { toast } from "material-react-toastify";
import {
  createMFIAccount,
  getMFIAccounts,
  updateMFIAccount,
} from "../store/features/Transfer/Transfer";

const MfiAccountFormModal = ({
  open,
  onCloseCallback,
  t,
  selected_account,
  type,
}: any) => {
  // all accounts
  const [mfiaccounts, setmfiaccounts] = useState<any>([]);
  const [processing, setProcessing] = React.useState(false);

  // set all accounts from api
  useEffect(() => {
    try {
      if (type == "mfi") {
        getMFIAccounts()
          .then((res: any) => {
            if (res?.data.length > 0) {
              setmfiaccounts(
                res?.data.filter((itm: any) => itm?.institution?.type == "mfi")
              );
            }
          })
          .catch((error: any) => {
            Sentry.captureException(error);
            toast.error(t(error?.data?.message));
          });
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, [type]);

  return (
    <Modal open={open} onClose={() => onCloseCallback()} className="mx-3">
      <div className="flex items-center justify-center h-screen">
        <div className="row flex items-center justify-center lg:w-full md:w-full">
          <div className="p-3 bg-white rounded col-md-6">
            <div className="flex flex-row items-center justify-between">
              <h3>
                {selected_account == null
                  ? t("Add_New_Account")
                  : t("Edit_Account")}
              </h3>
              <button
                onClick={() => onCloseCallback()}
                style={{ height: 40, width: 40, borderRadius: 20 }}
                className="flex items-center justify-center shadow-lg"
              >
                <i className="m-0 fa fa-close"></i>
              </button>
            </div>
            <Formik
              initialValues={{
                title: selected_account?.title || "",
                id: selected_account?.owner_id || "",
                owner_name: selected_account?.owner_name || "",
                mfi_name:
                  selected_account?.mfi_name ||
                  (mfiaccounts.length > 0 && mfiaccounts[0]?.name) ||
                  "",
              }}
              onSubmit={(data: any) => {
                setProcessing(true);
                const payload = {
                  title: data.title,
                  owner_id: data.id,
                  owner_name: data.owner_name,
                  mfi_name: data.mfi_name,
                };

                if (selected_account == null) {
                  createMFIAccount(payload)
                    .catch((error: any) => {
                      Sentry.captureException(error);
                      toast.error(t(error?.data?.message));
                    })
                    .finally(() => {
                      setProcessing(false);
                      onCloseCallback();
                    });
                } else {
                  updateMFIAccount(selected_account?.id, payload)
                    .catch((error: any) => {
                      Sentry.captureException(error);
                      toast.error(t(error?.data?.message));
                    })
                    .finally(() => {
                      setProcessing(false);
                      onCloseCallback();
                    });
                }
              }}
              validationSchema={yup.object().shape({
                title: yup.string().required("title_required"),
                id: yup.string().required("account_id_required"),
                owner_name: yup.string().required("owner_name_required"),
                mfi_name: yup.string().required("mfi_name_required"),
              })}
            >
              {({
                values,
                handleChange,
                handleBlur,
                errors,
                touched,
                handleSubmit,
              }) => (
                <>
                  <InputField
                    name="title"
                    extraClasses="bankNameDropDown mb-3"
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t("Account_Title")}
                    value={values.title}
                    label={t("Account_Title")}
                    error={t(`${errors.title}`)}
                    touched={touched.title}
                  />
                  <InputField
                    name="id"
                    extraClasses="bankNameDropDown mb-3"
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t("Account id")}
                    value={values.id}
                    label={t("Account id")}
                    error={t(`${errors.id}`)}
                    touched={touched.id}
                  />
                  <InputField
                    extraClasses="bankNameDropDown mb-3"
                    name="owner_name"
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t("owner_name")}
                    value={values.owner_name}
                    label={t("owner_name")}
                    error={t(`${errors.owner_name}`)}
                    touched={touched.owner_name}
                  />

                  <label
                    htmlFor="mfiname"
                    className="mb-1 text-gray-600 text-[12.6px]"
                  >
                    {t("select_mfi_name")}
                  </label>
                  <select
                    required
                    name="mfi_name"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    className="w-full py-1 bg-white border-2 border-b-slate-400"
                  >
                    {mfiaccounts.length > 0 &&
                      mfiaccounts.map((account: any, index: any) => {
                        return (
                          <option
                            selected={
                              account.name == selected_account?.mfi_name ||
                              (selected_account &&
                                selected_account[0]?.mfi_name)
                            }
                            value={account.name}
                          >
                            {account.name}
                          </option>
                        );
                      })}
                  </select>
                  {errors.mfi_name && (
                    <small className="text-red-500">
                      {t(`${errors.mfi_name}`)}
                    </small>
                  )}

                  <div className="flex flex-row items-center justify-between mt-10 justify-content-center">
                    {processing ? (
                      <button
                        className={`btn text-center rounded-md leading-6 text-white text-[12.6px]`}
                        style={{ backgroundColor: "rgb(3, 115, 117)" }}
                      >
                        {t("Please_wait")}
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className={`btn text-center rounded-md leading-6 text-white text-[12.6px]`}
                        onClick={() => handleSubmit()}
                        style={{ backgroundColor: "rgb(3, 115, 117)" }}
                      >
                        {selected_account == null
                          ? t("Add_Account")
                          : t("Edit_Account")}
                      </button>
                    )}
                  </div>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MfiAccountFormModal;
