import { Modal } from "@material-ui/core";
import OtpInput from "react-otp-input";
import {
  edit_active_user_profile,
  verify_email_address,
} from "../../store/features/Auth/Auth";
import { editUser } from "../../store/features/Auth/AuthSlice";
import { extractErrorMessage } from "../../helpers";
import * as Sentry from "@sentry/browser";
import { toast } from "material-react-toastify";

const EmailUpdateModal = (props: any) => {
  const {
    open,
    onClose,
    email_otp_code,
    setEmailOtpCode,
    t,
    email_address,
    user,
    dispatch,
  } = props;
  return (
    <Modal open={open} onClose={onClose}>
      <div className="row">
        <div className="col-md-4 mx-auto">
          <div className="flex flex-col items-center justify-center p-4 bg-white rounded">
            <div className="mt-10" />

            <img
              src="/email.png"
              style={{ height: 150, width: 150, marginBottom: 50 }}
            />
            <p className="text-center">
              <b>{t("verify_your_email")}</b>
            </p>
            <p className="text-center">
              <small>{t("verify_your_email_text")}</small>
            </p>

            <OtpInput
              value={email_otp_code}
              onChange={(code: any) => setEmailOtpCode(code)}
              numInputs={6}
              separator={<span> &nbsp; &nbsp; </span>}
              inputStyle={{
                height: 50,
                width: 50,
                borderWidth: 1,
                borderColor: "#aaa",
                color: "#282828",
                borderRadius: 10,
              }}
            />

            <br />
            <button
              className="flex-1 px-12 rounded-lg btn btn-block"
              style={{ backgroundColor: "rgb(3, 115, 117)" }}
              onClick={() => {
                try {
                  verify_email_address({
                    verification_code: email_otp_code,
                    email: email_address,
                  })
                    .then(() => {
                      edit_active_user_profile(
                        {
                          email: email_address,
                          reason_for_modification: "Updating email",
                        },
                        user.id
                      )
                        .then(() => {
                          dispatch(
                            editUser({
                              email: email_address,
                            })
                          );
                          toast.success(t("phone_update_success_message"));
                          onClose();
                        })
                        .catch((error: any) => {
                          const err = extractErrorMessage(error).join(", ");
                          toast.error(t(err));

                          Sentry.captureException(error);
                        });
                    })
                    .catch((error: any) => {
                      const err = extractErrorMessage(error).join(", ");
                      toast.error(t(err));

                      Sentry.captureException(error);
                    });
                } catch (error: any) {
                  Sentry.captureException(error);
                }
              }}
            >
              <small className="font-bold text-white btn-block">
                {t("phone_verify_btn_text")}
              </small>
            </button>
            <div className="mt-20" />
            <div className="mt-10" />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EmailUpdateModal;
