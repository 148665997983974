import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface StateProps {
  beneficiary: any;
  transferResult: any;
  fetchingBeneficiary: any;
  makingTransfer: any;
  transferCountry: any;
  transfers: any;
  selectedTransfer: any;
  isModalOpened: boolean;
  transferFromType: string;
  transferId: any;
  fetchingData: boolean;
  escrowState: boolean;
  actedOn: any;
  page: number;
  danaPayCountries: any;
  allCountries: any;
  fetching: boolean;
  loggedInUserCountry: any;
  beneFound: boolean;
  preferredComChannel: string;
  step: number;
  isTransferDialogOpen: boolean;
  transactionToRepeat: any;
  trueLayerError: any;
  usersForBulkyTransfer: any;
  isBulkTransferDialogOpen: boolean;
  bulkTCountry: string;
  bulkTransferData: any;
  deposit: any;
  withdraw: any;
  isDepositOpen: boolean;
  isWithdrawOpen: boolean;
  bulkTransfer: any;
  verifyBulkTransfer: any;
  directPayment: any;
  getAllMobileOperator: any;
  selectedOperator: any;
  hub2Operators: any;
}

const initialState: StateProps = {
  transferResult: null,
  beneficiary: null,
  transferCountry: null,
  fetchingBeneficiary: "",
  makingTransfer: "",
  transfers: [],
  selectedTransfer: null,
  isModalOpened: false,
  transferFromType: "internal",
  transferId: null,
  fetchingData: true,
  escrowState: false,
  actedOn: {},
  page: 1,
  danaPayCountries: [],
  allCountries: [],
  fetching: false,
  loggedInUserCountry: null,
  beneFound: false,
  preferredComChannel: "",
  step: 0,
  isTransferDialogOpen: false,
  transactionToRepeat: null,
  trueLayerError: null,
  usersForBulkyTransfer: {},
  isBulkTransferDialogOpen: false,
  bulkTCountry: "mali",
  bulkTransferData: {
    country: "france",
    reason: "",
    paymentMethod: "",
    operator: "",
  },
  withdraw: { toWithDraw: 0 },
  deposit: { amountInEURos: 0, amountInCFA: 0, paymentMethod: "" },
  isDepositOpen: false,
  isWithdrawOpen: false,
  bulkTransfer: [],
  verifyBulkTransfer: [],
  directPayment: [],
  getAllMobileOperator: [],
  hub2Operators: [],
  selectedOperator: {},
};

const TransferSlice = createSlice({
  name: "transfer",
  initialState,
  reducers: {
    addBeneficiary(state, actions: PayloadAction<any>) {
      state.beneficiary = actions.payload;
    },
    addTransferCountry(state, actions: PayloadAction<any>) {
      if (actions.payload) {
        state.transferCountry = actions.payload;
      }
    },
    addLoggedInUserCountry(state, actions: PayloadAction<any>) {
      state.loggedInUserCountry = actions.payload;
    },
    addTransferResult(state, actions: PayloadAction<any>) {
      state.transferResult = actions.payload;
    },
    fetchTransferRecords(state, actions: PayloadAction<any>) {
      state.transfers = actions.payload;
      state.fetchingData = false;
    },
    setSelected(state, actions: PayloadAction<any>) {
      state.selectedTransfer = actions.payload;
      if (actions.payload !== null) {
        state.isModalOpened = true;
      }
    },
    closeModal(state) {
      state.isModalOpened = false;
      state.selectedTransfer = null;
    },
    setTransactionToRepeat(state, actions: PayloadAction<any>) {
      state.transactionToRepeat = actions.payload;
    },
    setTransferFromType(state, actions: PayloadAction<any>) {
      state.transferFromType = actions.payload;
    },
    setExternalApiPaymentId(state, actions: PayloadAction<any>) {
      state.transferId = actions.payload;
    },
    setFetchState(state) {
      state.fetchingData = true;
    },
    resetTransfer(state) {
      state.transferResult = null;
      state.beneficiary = null;
      state.transferCountry = null;
      state.fetchingBeneficiary = "";
      state.makingTransfer = "";
      state.transfers = [];
      state.selectedTransfer = null;
      state.isModalOpened = false;
      state.transferFromType = "internal";
      state.transferId = null;
      state.fetchingData = false;
      state.danaPayCountries = [];
      state.allCountries = [];
      state.page = 1;
      state.allCountries = [];
      state.loggedInUserCountry = null;
      state.hub2Operators = [];
    },
    updateEscrowState(state, action: PayloadAction<any>) {
      state.escrowState = action.payload;
    },
    updateActedOn(state, action: PayloadAction<any>) {
      state.actedOn = {
        ...state.actedOn,
        [action.payload.id]: action.payload.status,
      };
    },
    updatePage(state, action: PayloadAction<any>) {
      state.page = action.payload;
    },
    updateTransaction(state, action: PayloadAction<any>) {
      state.transfers = state.transfers.map((transfer: any) => {
        if (transfer.transaction_id === action.payload.id) {
          return { ...transfer, status: action.payload.status };
        } else {
          return transfer;
        }
      });
    },
    updateCountries(state, actions: PayloadAction<any>) {
      state.danaPayCountries = actions.payload[0];
      state.allCountries = actions.payload[1];
    },

    updateDanaPayCountries(state, actions: PayloadAction<any>) {
      state.danaPayCountries = actions.payload;
    },

    setIsFetching(state, actions: PayloadAction<any>) {
      state.fetching = actions.payload;
    },

    setPreferredComChannel(state, actions: PayloadAction<any>) {
      state.preferredComChannel = actions.payload;
    },
    setBeneFound(state, actions: PayloadAction<any>) {
      state.beneFound = actions.payload;
    },
    setStep(state, actions: PayloadAction<any>) {
      state.step = actions.payload;
    },
    setTransferDialogOpen(state, actions: PayloadAction<any>) {
      state.isTransferDialogOpen = actions.payload;
    },
    setTLError(state, action: PayloadAction<any>) {
      state.trueLayerError = action.payload;
    },

    // include/delete user or clear all users
    updateUsersForBulkyTransfer(state, action: PayloadAction<any>) {
      const user = action.payload;
      if (user !== null) {
        if (state.usersForBulkyTransfer.hasOwnProperty(user?.id)) {
          const selected = state.usersForBulkyTransfer;
          delete selected[user?.id];
          state.usersForBulkyTransfer = selected;
        } else {
          state.usersForBulkyTransfer = {
            ...state.usersForBulkyTransfer,
            [user?.id]: user,
          };
        }
      } else {
        state.usersForBulkyTransfer = {};
      }
    },

    // update amount,fees,value etc for specific user
    updateUsersForBulkyTransferFees(state, action: PayloadAction<any>) {
      const { id, amount, amount_cfa } = action.payload;
      state.usersForBulkyTransfer = {
        ...state.usersForBulkyTransfer,
        [id]: {
          ...state.usersForBulkyTransfer[id],
          enteredValue: amount,
          value_cfa: amount_cfa,
        },
      };
    },
    openBulkTransferDialog(state, action: PayloadAction<any>) {
      if (action.payload && action.payload === "close") {
        state.isBulkTransferDialogOpen = false;
      } else {
        state.isBulkTransferDialogOpen = !state.isBulkTransferDialogOpen;
      }
    },
    updateBulkTData(state, action: PayloadAction<any>) {
      if (action.payload.hasOwnProperty("users")) {
        state.usersForBulkyTransfer = action.payload.users;
      } else {
        state.bulkTransferData = {
          ...state.bulkTransferData,
          ...action.payload,
        };
      }
    },
    setDepositData(state, action: PayloadAction<any>) {
      state.deposit = action.payload;
    },
    setWithDrawData(state, action: PayloadAction<any>) {
      state.withdraw = action.payload;
    },
    openDeposit(state, action: PayloadAction<any>) {
      state.isDepositOpen = action.payload;
    },
    openWithdraw(state, action: PayloadAction<any>) {
      state.isWithdrawOpen = action.payload;
    },
    bulkTransferCsvPayment(state, action: PayloadAction<any>) {
      state.bulkTransfer = action.payload;
    },
    verifiedBulkCsv(state, action: PayloadAction<any>) {
      state.verifyBulkTransfer = action.payload;
    },
    directPayment(state, action: PayloadAction<any>) {
      state.directPayment = action.payload;
    },
    getAllMobileOperatorWithCode(state, action: PayloadAction<any>) {
      state.getAllMobileOperator = action.payload;
    },
    resetPayment(state, action: PayloadAction<any>) {
      // state.bulkTransferData.paymentMethod = "";
      state.bulkTransferData.paymentMethod = action.payload;
    },
    setHub2Operators(state, action: PayloadAction<any>) {
      state.hub2Operators = action.payload;
    },
  },
});

export const {
  setDepositData,
  setWithDrawData,
  openDeposit,
  openWithdraw,
  addBeneficiary,
  addTransferCountry,
  addTransferResult,
  fetchTransferRecords,
  setSelected,
  closeModal,
  setTransferFromType,
  setExternalApiPaymentId,
  setFetchState,
  resetTransfer,
  updateEscrowState,
  updateActedOn,
  updatePage,
  updateTransaction,
  updateCountries,
  setIsFetching,
  addLoggedInUserCountry,
  setPreferredComChannel,
  setBeneFound,
  setStep,
  setTransferDialogOpen,
  setTransactionToRepeat,
  setTLError,
  updateUsersForBulkyTransfer,
  updateUsersForBulkyTransferFees,
  openBulkTransferDialog,
  updateBulkTData,
  updateDanaPayCountries,
  bulkTransferCsvPayment,
  verifiedBulkCsv,
  directPayment,
  getAllMobileOperatorWithCode,
  setHub2Operators,
  resetPayment,
} = TransferSlice.actions;
export default TransferSlice.reducer;
