import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import "../../helpers/i18n";
import { toast } from "material-react-toastify";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { customerIoIdentify } from "../../utilities/customeriohelpers";
import * as Sentry from "@sentry/browser";

const VerifyingEmail = () => {
  const search = useLocation().search;
  const user_object = useAppSelector(
    (state) => state.persistedReducer.auth.user
  );
  const [verified, setVerified] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [linkedUsed, setLinkUsed] = useState(false);
  const [endpointError, setendpointError] = useState(false);
  const queryURL = new URLSearchParams(search);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [text, setText] = useState({
    title: "EMail_Verification",
    body: "email_validated",
  });
  const { t } = useTranslation();

  const identifyUserWithTimeStamp = (query: any, email: any) => {
    try {
      const timeStamp = Math.floor(Date.now() / 1000);
      const id = query
        .match(/\/([a-zA-Z0-9\-]{1,})\?/gi)
        .join("")
        .replaceAll("/", "")
        .replaceAll("?", "");
      customerIoIdentify({
        id,
        email,
        created_at: timeStamp,
      });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const performVerification = (query: any) => {
    try {
      setIsVerifying(true);
      fetch(query, {
        headers: {
          "Content-Type": "application/json",
          "X-API-SECRET": `${process.env.REACT_APP_X_REQUEST_SECRET}`,
        },
      })
        .then((response: any) => {
          if (response.verified || response?.status == 200) {
            window.heap.track("Email verification", {
              email: user_object?.email,
            });
            setVerified(true);
            if (localStorage.getItem("ext_mm_process_link")) {
              checkUserLoggedIn(response);
            }
          } else if (response?.status == 422) {
            toast.warn(t("link_already_used"));
            setLinkUsed(true);
            throw { name: "link_already_used" };
          } else {
            throw { name: "server error" };
          }
          return response.json();
        })
        .then((res: any) => {
          try {
            identifyUserWithTimeStamp(query, res.email);
          } catch (error: any) {
            Sentry.captureException(error);
          }
        })
        .catch((error: any) => {
          if (!(error?.name == "link_already_used")) {
            setendpointError(true);
          }
          toast.warning(t(error?.data?.message));
          Sentry.captureException(error);
        })
        .finally(() => {
          setIsVerifying(false);
        });
    } catch (error: any) {
      setIsVerifying(false);
    }
  };

  const checkUserLoggedIn = (response: any) => {
    // no auth token
    const key = localStorage.getItem("user:key");
    if (key) {
      history.push("/dashboard/home");
    } else {
      if (response) {
        response.json().then((res: any) => {
          history.push(`/login?email=${res?.email}`);
        });
      } else {
        history.push("/login");
      }
    }
    //
  };

  useEffect(() => {
    const link = queryURL.get("queryURL");
    const redirect_uri = queryURL.get("redirect_uri");
    if (redirect_uri) {
      localStorage.setItem("ext_mm_process_link", redirect_uri);
      setText({
        title: "mm_title",
        body: "mm_text",
      });
    }

    performVerification(link);
  }, [search]);

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="text-center w-1/2">
        {isVerifying && (
          <div className="bg-white shadow-sm-lg rounded-xl">
            <img
              src="/images/loading.gif"
              alt=""
              className="m-auto"
              style={{ height: 120 }}
            />
            <br />
            <h2 className="text-2xl font-bold text-blue-500">
              {t("Verifying_your_Email")}
            </h2>
            <p>{t("Please_wait")}... </p>
          </div>
        )}
        {verified && (
          <div className="bg-white shadow-sm-lg rounded-xl">
            <img
              src="/images/email.png"
              className="m-auto"
              style={{ height: 150 }}
            />
            <br />
            <h2 className="mb-3 text-2xl font-bold text-blue-500">
              {t(text.title)}
            </h2>
            <p style={{ textAlign: "center" }}>{t(text.body)}</p>
            <p style={{ textAlign: "center", marginBottom: 5 }}>
              <Link to="/login" className="font-bold text-blue-400">
                {t("login_here")}
              </Link>
            </p>
            <p>
              <small className="text-gray-600">Danapay</small>
            </p>
          </div>
        )}
        {linkedUsed && (
          <div className="text-center">
            <div className="bg-white shadow-sm-lg rounded-xl">
              <img
                src="/images/email-blocker.png"
                className="m-auto"
                style={{ height: 150 }}
              />
              <br />
              <h2 className="mb-3 text-2xl font-bold text-blue-500">
                {t("link_already_used")}
              </h2>
              <p style={{ textAlign: "center" }}>
                {t("link_already_used_desc")}
              </p>
              <p>
                <small className="text-gray-600">Danapay</small>
              </p>
            </div>
          </div>
        )}
        {endpointError && (
          <div className="text-center">
            <div className="bg-white shadow-sm-lg rounded-xl">
              <i
                style={{
                  fontSize: 100,
                  height: 90,
                  margin: 0,
                  color: "#777",
                }}
                className="text-4xl fa fa-frown-o"
              ></i>

              <br />
              <h2 className="mb-3 text-2xl font-bold text-blue-500">
                {t("verification_email_error")}
              </h2>
              <p style={{ textAlign: "center" }}>
                {t("verification_email_error_description")}
              </p>
              <p>
                <small className="text-gray-600">Danapay</small>
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VerifyingEmail;
