import { openWithdraw } from "../../store/features/Transfer/TransferSlice";

const WithdrawSuccessModal = (props: any) => {
  const { size, steps, t, dispatch, history } = props;
  return (
    <div>
      <div className="row">
        {size[0] < 900 && (
          <div className="col-md-12 py-4 text-center">
            <h2>{steps[3]}</h2>
          </div>
        )}

        <div className="text-center col-md-6 mx-auto">
          <div
            style={{ marginTop: 30 }}
            className="mt-4 mb-10 shadow-lg lg:p-10 md:p-4 sm:p-2"
          >
            <h1 className="py-4 text-3xl font-bold">{t("onTheWay")}</h1>
            <p>{t("onTheWayDesc")}</p>

            <button
              className="px-4 mt-3 mb-4 rounded-lg btn"
              style={{ backgroundColor: "rgb(3, 115, 117)" }}
              onClick={() => {
                dispatch(openWithdraw(false));
                history.push("/");
              }}
            >
              <small className="font-bold text-white">{t("backHome")}</small>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawSuccessModal;
