import React from "react";
import "./styles.css";
import TransactionListItem from "../TransactionListItem/TransactionListItem";
import { useTranslation } from "react-i18next";
import "../../helpers/i18n";
interface Props {
  transactions: any;
}

const TransactionsCard: React.FC<Props> = ({ transactions }) => {
  const { t } = useTranslation();

  return (
    <section className="transactions">
      {transactions?.length === 0 && (
        <div className="row">
          <div className="p-10 text-center col-md-12">
            <div className="text-gray-500">{t("noTransactions")}</div>
          </div>
        </div>
      )}
      <div className="mx-2 overflow-y-scroll rounded-lg">
        <TransactionListItem transactions={transactions} />
      </div>
    </section>
  );
};

export default TransactionsCard;
