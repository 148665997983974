import { Alert } from "@material-ui/lab";
import InfoMessage from "../InfoMessage";

const SelectBankModal = (props: any) => {
  const {
    t,
    selectedBankState,
    setBankIndex,
    setselectedBankState,
    bankIndex,
    setSelectedBank,
    setActiveStep,
    dana_pay_bank_accounts,
  } = props;

  const CustomMessageComponent = () => {
    return (
      <>
        {t("after_bank_transfer_message_1")}
        <b>{t("after_bank_transfer_message_2")}</b>
      </>
    );
  };

  return (
    <div className="flex justify-center 5542">
      <div className="p-4 max-sm:!px-3 shadow-lg col-md-8 max-w-[600px] col-11">
        <div className="my-4 mb-4 text-center">
          <h3 className="text-2xl font-bold">
            {t("bank_transfer_success_title")}
          </h3>
          <p>{t("bank_transfer_success_subtext")}</p>
        </div>
        <InfoMessage
          message={"after_bank_transfer_message"}
          extraClasses="mt-4"
          customMessageComponent={<CustomMessageComponent />}
        />
        <Alert severity="warning" className="my-2">
          {t("after_bank_transfer_message_3")}
        </Alert>
        <p className="mb-2 mt-4 text-center">{t("pi_text")}</p>
        <>
          <div className="bacnkCheckBoxWrapper flex flex-col gap-2 m-0 p-0 p-2 mb-4">
            {selectedBankState.length > 0 &&
              selectedBankState.map((bank: any, index: number) => {
                return (
                  <div className="wrapperBankCheckBox m-0 innerBankBox">
                    <div className="wrapperBankCheckBoxwrapper">
                      <input
                        type="checkbox"
                        className="default:ring-2"
                        onChange={(e: any) => {
                          setBankIndex(index);
                          setselectedBankState((prev: any) =>
                            prev.map((itm: any, ind: any) => {
                              if (ind == index) {
                                return {
                                  ...itm,
                                  checked: true,
                                };
                              } else {
                                return {
                                  ...itm,
                                  checked: false,
                                };
                              }
                            })
                          );
                        }}
                        name={bank.bank_name}
                        checked={bank.checked}
                      />
                      <span className="checkmark"></span>
                    </div>
                    <label>{bank.bank_name}</label>
                  </div>
                );
              })}
          </div>
          <button
            onClick={() => {
              if (bankIndex !== null) {
                setSelectedBank(dana_pay_bank_accounts[bankIndex]);

                setActiveStep(3);
              }
            }}
            className="w-full rounded-md btn"
            style={{
              backgroundColor: "rgb(3, 115, 117)",
            }}
          >
            <small className="mx-10 font-bold text-white">
              {t("continue")}
            </small>
          </button>
        </>
      </div>
    </div>
  );
};

export default SelectBankModal;
