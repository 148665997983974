import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  data: any;
  setData: any;
  value: any;
  width?: boolean;
  label?: string;
  type?: string;
  canShowAll?: boolean;
  hasOptionalInput?: boolean;
  classApply?: boolean;
}

const SelectField: React.FC<Props> = ({
  data,
  setData,
  value,
  width = false,
  label = "",
  type = null,
  canShowAll = false,
  hasOptionalInput = false,
  classApply = false
}) => {
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setData(event.target.value as string);
  };

  return (
    <div className="form-group">
      {label !== "" && (
        <label className="mb-1">
          {classApply === true ? <p className="withdrawTitle">{t(label)}</p> : <small>{t(label)}</small>}
        </label>
      )}
      <select
        className={`p-2 border-1 rounded-md !bg-white ${width ? "w-full" : " mx-2"
          }`}
        value={value}
        onChange={handleChange}
      >
        {canShowAll && (
          <option
            style={{ fontSize: 13 }}
            value={"All"}
            className="text-gray-300"
          >
            {t("All")}
          </option>
        )}
        {hasOptionalInput && (
          <option
            style={{ fontSize: 13 }}
            value=""
            className="text-gray-300"
          ></option>
        )}

        {data &&
          data
            .filter((option: any) => option.type !== type)
            .map((val: any, index: number) => (
              <option value={val.favorite?.first_name ? val.favorite?.first_name : val?.provider} key={index} style={{ fontSize: 13 }}>
                {val.favorite?.first_name ? val.favorite?.first_name?.toLowerCase().replaceAll(" ", "_") : t(`${val.name?.toLowerCase().replaceAll(" ", "_")}`)}
              </option>
            ))}
      </select>
    </div>
  );
};

export default SelectField;

