import { Modal } from "@material-ui/core";
import SelectField from "../forms/SelectField";

const DownloadCSVModal = (props: any) => {
  const { open, t, setData, value, onClick, downloading, onCancel } = props;
  return (
    <Modal open={open}>
      <div className="row flex justify-center">
        <div className="col-md-6 col-lg-4 col-10">
          <div className="px-4 py-10 my-20 bg-white rounded-md shadow-lg">
            <h2 className="mb-2 text-xl font-bold text-gray-700">
              {t("downloading_transaction")}
            </h2>
            <p className="text-sm">{t("download_csv_subtitle")}</p>

            <div className="my-2">
              <div className="p-2 mb-2 bg-green-50 ">
                <small className="text-sm font-semibold text-green-900 text-[11px]">
                  {t("csv_format_text")}
                </small>
              </div>

              <SelectField
                data={["default", "odoo", "saari"].map((val) => {
                  return { type: val, name: val };
                })}
                setData={(text: any) => setData(text)}
                value={value}
                width
              />
            </div>

            <div className="flex gap-2 flex-wrap flex-row mt-4">
              <a
                onClick={onCancel}
                className="flex items-center justify-center flex-1 px-10 py-2 bg-gray-200 rounded-md cursor-pointer"
              >
                <small className="text-gray-900">{t("cancel")}</small>
              </a>
              <a
                onClick={onClick}
                className="flex items-center justify-center px-10 py-2 bg-gray-900 rounded-md cursor-pointer flex-1"
              >
                <small
                  className={downloading ? "text-green-500" : "text-white"}
                >
                  {downloading ? t("downloading") : t("download_CSV")}
                </small>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DownloadCSVModal;
