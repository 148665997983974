import { Modal } from "@material-ui/core";
import OtpInput from "react-otp-input";
import {
  edit_active_user_profile,
  verify_phone_otp,
} from "../../store/features/Auth/Auth";
import { extractErrorMessage } from "../../helpers";
import { editUser } from "../../store/features/Auth/AuthSlice";
import { getTransferCountries } from "../../store/features/Transfer/Transfer";
import { addLoggedInUserCountry } from "../../store/features/Transfer/TransferSlice";
import * as Sentry from "@sentry/browser";
import useResize from "../../hooks/useResize";
import { useMemo } from "react";
import { toast } from "material-react-toastify";

const PhoneNumberModal = (props: any) => {
  const {
    t,
    open,
    onClose,
    otp_code,
    setOtpCode,
    country,
    new_phone,
    dispatch,
    setEmailOtpCode,
    user,
  } = props;

  const onVerify = () => {
    try {
      verify_phone_otp({
        verification_code: parseInt(otp_code),
        country_code: parseInt(country),
        phone_number: new_phone,
      })
        .then((res: any) => {
          edit_active_user_profile(
            {
              country_code: parseInt(country),
              phone_number: new_phone,
              reason_for_modification: "Updating phone number",
            },
            user.id
          )
            .then((response: any) => {
              setEmailOtpCode("");
              dispatch(
                editUser({
                  country_code: parseInt(country),
                  phone_number: new_phone,
                  full_phone_number: `${country}${new_phone}`,
                })
              );
              // change logged in user country in redux
              getTransferCountries()
                .then((resp: any) => {
                  dispatch(
                    addLoggedInUserCountry(
                      resp?.data.find(
                        (cc: any) =>
                          cc.country_code === response?.user?.country_code
                      )
                    )
                  );
                })
                .catch((error: any) => {
                  toast.warning(t(error.data.message));
                  Sentry.captureException(error);
                });

              toast.success(t("phone_update_success_message"));
              onClose();
            })
            .catch((error: any) => {
              const err = extractErrorMessage(error).join(", ");
              toast.error(t(err));

              Sentry.captureException(error);
            });
        })
        .finally(() => {
          setEmailOtpCode("");
          onClose();
        });
    } catch (error: any) {
      setEmailOtpCode("");
      onClose();
      Sentry.captureException(error);
    }
  };

  const [w, h] = useResize();

  const inputStyle = useMemo(() => {
    if (w < 380) {
      return {
        height: 30,
        width: 30,
        borderWidth: 1,
        borderColor: "#aaa",
        color: "#282828",
        borderRadius: 5,
      };
    } else {
      return {
        height: 50,
        width: 50,
        borderWidth: 1,
        borderColor: "#aaa",
        color: "#282828",
        borderRadius: 10,
      };
    }
  }, [w]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      className="flex-center h-full overflow-scroll"
    >
      <div className="col-md-6 m-2 col-lg-4 col-11 flex flex-col items-center justify-center p-4 max-sm:!p-3 bg-white rounded-lg">
        <img
          src="/phone_verification.png"
          style={{ height: 100, width: 100, marginBottom: 30 }}
        />
        <p className="text-2xl text-center font-bold">
          {t("phone_verify_header")}
        </p>
        <p className="text-center text-[12.6px]">{t("phone_verify_text")}</p>

        <OtpInput
          value={otp_code}
          className="my-4"
          onChange={(code: any) => setOtpCode(code)}
          numInputs={6}
          separator={<span> &nbsp; &nbsp; </span>}
          inputStyle={inputStyle}
        />

        <div className="flex w-full max-sm:flex-col flex-wrap gap-2">
          <button
            className="flex-1 bg-gray-300 rounded-lg btn btn-block text-[12.6px]"
            onClick={onClose}
          >
            {t("cancel")}
          </button>
          <button
            className="flex-1 rounded-lg btn btn-block font-bold text-white text-[12.6px]"
            style={{ backgroundColor: "rgb(3, 115, 117)" }}
            onClick={onVerify}
          >
            {t("phone_verify_btn_text")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default PhoneNumberModal;
