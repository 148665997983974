import React, { useMemo } from "react";
import * as Sentry from "@sentry/browser";

const DeliveryWithdrawBox = (props: any) => {
  const {
    isDeliveryPayoutPresent,
    setwidthdrawMethod,
    widthdrawMethod,
    t,
    transfer,
    directPaymentAmountExceed,
  } = props;

  const maxLimit = useMemo(() => {
    try {
      if (
        transfer?.getAllMobileOperator &&
        transfer?.getAllMobileOperator?.length > 0
      ) {
        const mobileOperatorObject = transfer?.getAllMobileOperator.find(
          (cashoutMethod: any) => {
            return (
              cashoutMethod &&
              cashoutMethod.cashout_method &&
              cashoutMethod.cashout_method.payment_type &&
              cashoutMethod.cashout_method.payment_type.name == "delivery"
            );
          }
        );
        if (mobileOperatorObject) {
          return mobileOperatorObject.cashout_method.max_amount + " EUR";
        }
      } else {
        return 0;
      }
    } catch (error) {
      Sentry.captureException(error);
      return 0;
    }
  }, [transfer]);

  return (
    <>
      {isDeliveryPayoutPresent && (
        <>
          <div className="wrapperBankCheckBox m-0 innerBankBox">
            <div className="wrapperBankCheckBoxwrapper">
              <input
                type="checkbox"
                className="default:ring-2"
                onChange={(e) => {
                  e.target.checked && setwidthdrawMethod("delivery");
                }}
                name="Micro finance"
                checked={widthdrawMethod == "delivery"}
              />
              <span className="checkmark"></span>
            </div>
            <label
              className={`opacity-[${
                directPaymentAmountExceed && widthdrawMethod == "delivery"
                  ? 0.5
                  : 1
              }]`}
            >
              {t("delivery")}
            </label>
          </div>

          {directPaymentAmountExceed && widthdrawMethod == "delivery" && (
            <small className="mb-2 innerBankBox text-red-500">
              {t("max_amount_exceeded_cashout_message_1")}
              {maxLimit}
              {t("max_amount_exceeded_cashout_message_2")}
            </small>
          )}
        </>
      )}
    </>
  );
};

export default DeliveryWithdrawBox;
