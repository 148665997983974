import React, { useEffect, useState } from "react";
import { uploadFiles } from "../store/features/Auth/Auth";
import { toast } from "material-react-toastify";
import { is } from "immer/dist/internal";
import * as Sentry from "@sentry/browser";

const documentsTypes = [
  {
    name: "Register_of_commerce",
    type: "register-of-commerce",
    uploaded: false,
  },
  {
    name: "Articles_of_association",
    type: "article-of-association",
    uploaded: false,
  },
  {
    name: "Beneficiary_owner",
    type: "beneficiary-owners-id-document",
    uploaded: false,
  },
  {
    name: "Directors_IDs",
    type: "directors-id-document",
    uploaded: false,
  },
  {
    name: "Proof_of_address",
    type: "proof-of-address",
    uploaded: false,
  },
];

const FileUploadComp: React.FC<any> = ({
  company_id,
  t,
  type,
  uploaded_documents,
  setUploadedDocuments,
  documentUploading,
  setDocumentUploading,
}) => {
  const [documents, setDocuments] = useState(documentsTypes);
  const [activeIndex, setActiveIndex] = useState(0);
  const [error, setError] = useState(false);
  useEffect(() => {
    setUploadedDocuments && setUploadedDocuments(documents);
  }, [documents]);

  const selectDocument = (index: number) => {
    setActiveIndex(index);
  };

  const upload = (event: any) => {
    try {
      const files = Object.values(event.target.files);
      if (files.length > 0) {
        files.map((selectedFile: any) => {
          const size = selectedFile.size;
          if (size < 5000000) {
            const formData = new FormData();
            formData.append("file", selectedFile, selectedFile.name);
            formData.append("type", documents[activeIndex].type);
            setDocumentUploading(true);
            uploadFiles(formData, company_id)
              .then((res) => {
                setDocumentUploading(false);
                const all = [...documents];
                all[activeIndex].uploaded = true;
                setDocuments(all);
              })
              .catch((error: any) => {
                Sentry.captureException(error);
                setDocumentUploading(false);
                setError(error?.data?.message);
                toast.error(t(error?.data?.message));
              });
          } else {
            toast.error(t("file_to_large"));
          }
        });
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    try {
      const documents_uploaded = documentsTypes.map((value: any) => {
        const isUploaded = uploaded_documents.find(
          (doc: any) => doc.type === value.type
        );
        if (isUploaded) {
          return { ...value, uploaded: true };
        } else {
          return value;
        }
      });
      setDocuments(documents_uploaded);
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, []);

  return (
    <div className="mt-4 border rounded">
      <div className="row">
        <div className="p-0 col-md-4">
          <ul className="divide-y divide-gray-300">
            {documents.map((type: any, index: number) => (
              <li
                className="p-4 cursor-pointer hover:bg-gray-50"
                key={index}
                onClick={() => selectDocument(index)}
                style={{
                  backgroundColor: index === activeIndex ? "#eee" : "#fff",
                }}
              >
                <i
                  className="p-2 font-bold text-gray-200 rounded-full fa fa-file-pdf-o 200"
                  style={{
                    backgroundColor: type.uploaded
                      ? "rgba(150, 232, 74, 0.32)"
                      : " rgba(0, 0, 0, 0.32)",
                    color: type.uploaded ? "green" : "#fff",
                  }}
                ></i>
                <small>{t(type.name)}</small>
              </li>
            ))}
          </ul>
        </div>
        <div className="p-6 bg-gray-200 col-md-8">
          <p>
            <b>
              {t("upload")} {t(documents[activeIndex].name)}
            </b>
          </p>
          <small>{t("select_files")}...</small>
          <hr className="mt-3 mbt-3" />
          {documents[activeIndex].uploaded ? (
            <div className="flex flex-col items-center justify-center p-6">
              <i
                className="mt-3 mb-3 text-green-500 fa fa-check-circle"
                style={{ fontSize: 70 }}
                aria-hidden="true"
              ></i>
              <small>
                <b>
                  {t(documents[activeIndex].name)} {t("uploaded_successfully")}.
                </b>
              </small>
            </div>
          ) : (
            <div>
              <div className="relative border border-gray-500 border-dashed">
                {documentUploading ? (
                  <>
                    <div className="flex items-center justify-center">
                      <img
                        src={
                          type === "profile"
                            ? "../uploading.gif"
                            : "./uploading.gif"
                        }
                        style={{ height: 100 }}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <input
                      type="file"
                      className="relative z-50 block w-full h-full p-20 opacity-0 cursor-pointer"
                      onChange={upload}
                      accept="image/png, image/jpeg, application/pdf"
                      multiple
                    />
                    <div className="absolute top-0 left-0 right-0 p-10 m-auto text-center">
                      <h4>
                        {t("Drop_file_to_upload")}
                        <br />
                        {t("or")}
                      </h4>
                      <p
                        style={{ color: "rgb(3, 115, 117)" }}
                        className="font-bold"
                      >
                        {t("select_files")}
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FileUploadComp;
