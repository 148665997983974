import * as Sentry from "@sentry/browser";
export const customerIoIdentify = (props: any) => {
  try {
    const _cio = (window as any)._cio || [];
    (window as any)._cio = _cio;

    _cio.identify({
      ...props,
    });
  } catch (error: any) {
    Sentry.captureException(error);
  }
};

export const customerIoTrack = (eventData: { name: string; data: any }) => {
  try {
    const _cio = (window as any)._cio || [];
    (window as any)._cio = _cio;

    _cio.track(eventData.name, eventData.data);
  } catch (error: any) {
    Sentry.captureException(error);
  }
};
