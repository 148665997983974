import axios from "axios";
import instance from "../../../apis/axiosInstance";

export const fetchBeneficiary = (obj: any) => {
  return instance.post("/users/getBeneficiary", JSON.stringify(obj));
};

export const initTransfer = (obj: any) => {
  return instance.post("/payments", JSON.stringify(obj));
};

export const getPaymentById = (id: string) => {
  return instance.get(`/operations/${id}`);
};

export const initFinForExternalPayment = (id: string) => {
  return instance.put(`/payments/${id}/confirm`);
};

export const getTransfers = (page: any, limit = 8) => {
  if (page <= 0) {
    page = 1;
  }
  return instance.get(`/operations?page=${page}&per_page=${limit}`);
};
export const getAllTransfers = () => {
  return instance.get("/operations");
};

export const filterTransfers = (transferType: any) => {
  return instance.get(`/operations?transferType=${transferType.toLowerCase()}`);
};

export const handle_search = (params: string) => {
  return instance.get(`/operations?${params}`);
};

export const getTransferDetails = (id: any) => {
  return instance.get("/operations/" + id);
};

export const confirmTransaction = (id: string) => {
  return instance.post(`/escrowTransactions/${id}/confirmExecution`);
};

export const confirmRefund = (id: string) => {
  return instance.post(`/escrowTransactions/${id}/confirmRefund`);
};

export const getTransferCountries = () => {
  return instance.get(`/transactions/transferCountries`);
};

export const getDanaPayCountries = () => {
  return instance.get("/get-countries");
};

export const revokeExecutionConfirmation = (id: any) => {
  return instance.post(`/escrowTransactions/${id}/revokeExecutionConfirmation`);
};

export const revokeRefundConfirmation = (id: any) => {
  return instance.post(`/escrowTransactions/${id}/revokeRefundConfirmation`);
};

//add accounts
export const createAccount = (account: any) => {
  return instance.post(`/userBankAccounts`, JSON.stringify(account));
};

//get accounts
export const getAccounts = () => {
  return instance.get(`/userBankAccounts/all`);
};
export const getBankAccountsById = (id: string) => {
  return instance.get(`/userBankAccounts/all/${id}`);
};

//edit accounts
export const editAccount = (account_id: any) => {
  // return instance.post(`/userBankAccounts/${account_id}`);
};

//delete accounts
export const deleteAccount = (account_id: any) => {
  return instance.delete(`/userBankAccounts/${account_id}`);
};

//cash out routes

export const initiateCashOut = (payload: any) => {
  return instance.post(`/initiatePayout`, JSON.stringify(payload));
};

export const confirmCashOut = (payload: any) => {
  return instance.post(`/verifyPayout`, JSON.stringify(payload));
};

export const resendCashOutCode = (cashOutId: string) => {
  return instance.post(`/cashout/${cashOutId}/resendCode`);
};

export const getCashOutMethods = () => {
  return instance.get(`/transactions/transferCashoutMethods`);
};

export const getCountryCashoutMethods = (country_id: string) => {
  return instance.get(
    `transactions/transferCountries/${country_id}/cashoutMethods`
  );
};

export const currentBalance = () => {
  return instance.get(`/auth/current`);
};

export const fetchBankDetails = () => {
  return instance.get(`/payments/bankdetails`);
};

export const getAppliedFeesForTransfers = (data: any, user_id: any) => {
  return instance.post(
    `/payments/getEuroAppliedFees/transfer/${user_id}`,
    JSON.stringify(data)
  );
};

export const calculateBulkTransferFees = (data: any, user_id: string) => {
  return instance.post(
    `/payments/getEuroAppliedFees/bulkTransfer/${user_id}`,
    JSON.stringify(data)
  );
};

export const makeBulkyPayment = (data: any) => {
  return instance.post(`/payments/initiateBulkPayment`, JSON.stringify(data));
};

export const bulkTransferPayment = (data: any) => {
  return instance.post(
    `/transactions/bulkTransfers/initiate-high-volume-payment`,
    JSON.stringify(data)
  );
};

export const verifyBulkCsv = async (data: any) => {
  return instance.post(
    `/transactions/bulkTransfers/verify-beneficiaries`,
    JSON.stringify(data)
  );
};

export const getAllMobileOperatorWithCountryCode = async (query: any) => {
  return await instance.get(`/cashout/${query}`);
};

export const getAppliedFeesForWithdraws = (data: any, user_id: any) => {
  return instance.post(
    `/payments/getEuroAppliedFees/withdraw/${user_id}`,
    JSON.stringify(data)
  );
};

export const getAppliedFeesForDeposits = (data: any, user_id: any) => {
  return instance.post(
    `/payments/getEuroAppliedFees/deposit/${user_id}`,
    JSON.stringify(data)
  );
};

export const depositOnAccount = (data: any) => {
  return instance.post(`/payin`, JSON.stringify(data));
};

export const getDanapayBanks = (country_calling_code: any) => {
  return instance.get(`/danapayBankDetails/country/${country_calling_code}`);
};

export const getBankDetails = (bank_id: any, payment_id = "", amount = "") => {
  let urlBody = `id=${bank_id}`;
  if (amount !== "") {
    urlBody = `${urlBody}&amount=${amount}`;
  }
  if (payment_id !== "") {
    urlBody = `${urlBody}&payment_id=${payment_id}`;
  }
  return instance.get(`notifications/danapayBankDetails?${urlBody}`);
};

export const completeOnDemandPayment = (payment_id: string) => {
  return instance.post(`/payments/onDemand/${payment_id}/accept`);
};

export const getPaymentDetail = (payment_id: string) => {
  return instance.get(`/payments/${payment_id}`);
};

export const cancelWithdraw = (id: string) => {
  return instance.delete(`cashout/${id}`);
};

export const cancelTransfer = (id: string) => {
  return instance.delete(`payments/${id}`);
};

export const completeTLPayment = (payload: any) => {
  return instance.post(
    `/payments/truelayer/initiatePayment`,
    JSON.stringify(payload)
  );
};

export const sendResendCode = (data: any, cashout_id: string) => {
  return instance.post(
    `cashout/${cashout_id}/resendCode`,
    JSON.stringify(data)
  );
};

export const completePaymentOnDemandTransfer = (
  payload: any,
  payment_id: string
) => {
  return instance.put(`/payments/${payment_id}`, JSON.stringify(payload));
};

export const completePayLineTransfer = (token: string) => {
  return instance.get(`payments/getPaylineDetails/${token}`);
};

export const fetch_receipt = (payment_id: any) => {
  return instance.get(`payments/invoices/${payment_id}`, {
    responseType: "blob",
  });
};
export const getMMProvider = (payload: any) => {
  return instance.post("/payments/hub2/execute", JSON.stringify(payload));
};

export const getMMAuthenticate = (payload: any) => {
  return instance.post("/payments/hub2/confirm", JSON.stringify(payload));
};

export const getNewStatus = (payment_id: any) => {
  return instance.get(`/payments/hub2/${payment_id}`);
};

export const getNewDirectPayment = (payload: any) => {
  return instance.post("/payments/direct", JSON.stringify(payload));
};

export const downloadCSV = (payload: any) => {
  return instance.post("/operations/download_csv", JSON.stringify(payload));
};

export const downloadCSVFile = (payload: any) => {
  const token = localStorage.getItem("user:key");
  return axios.post("/operations/download_csv", payload, {
    baseURL: `${process.env.REACT_APP_BASE_URL}/api/v1`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-API-SECRET": process.env.REACT_APP_X_REQUEST_SECRET,
      Authorization: `Bearer ${token}`,
    },
  });
};

export const paymentsAnonymous = (appref: string) => {
  return instance.post("/payments/anonymous", JSON.stringify({ appref }));
};

// get all aloohpay methods
export const getMFIAccounts = () => {
  return instance.get("/aloohpay/institutions");
};
export const getAloohpayByCountry = (countryCode: Number) => {
  return instance.get(`/aloohpay/institutions?country_code=${countryCode}`);
};
// get user's all mfi accounts
export const getUsersMFIAccounts = () => {
  return instance.get("/userMFIAccounts/all");
};

// get user's all mfi accounts by id
export const getUsersMFIAccountsById = (id: string) => {
  return instance.get(`/userMFIAccounts/all/${id}`);
};

// create mfi account
export const createMFIAccount = (payload: any) => {
  return instance.post("/userMFIAccounts", JSON.stringify(payload));
};

// update mfi account
export const updateMFIAccount = (MFI_account_id: any, payload: any) => {
  return instance.put(
    `/userMFIAccounts/${MFI_account_id}`,
    JSON.stringify(payload)
  );
};

// delete mfi account
export const deleteMFIAccount = (MFI_account_id: any) => {
  return instance.delete(`/userMFIAccounts/${MFI_account_id}`);
};

// get hub2 accounts for bank
export const getHub2BankAccounts = (countryCode: Number) => {
  return instance.get(`/Hub2/institutions?country_code=${countryCode}`);
};

// get hub2 accounts for bank
export const getDanapayProviders = (countryCode: Number) => {
  return instance.get(`/danapay/institutions?country_code=${countryCode}`);
};

export const dynamicGetEndpoint = (provider: any, countryCode: Number) => {
  return instance.get(`/${provider}/institutions?country_code=${countryCode}`);
};
