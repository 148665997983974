import React from "react";
import Avatar from "react-avatar";

const BeneficiarySummary: React.FC<any> = ({ name, company, size = 12 }) => {
  return (
    <div className="flex flex-row w-full py-3">
      <Avatar name={name} size="45" round />
      <div className="flex flex-col ml-2">
        <span className="text-base font-bold capitalize">{name}</span>
        <small style={{ fontSize: size }}>{company}</small>
      </div>
    </div>
  );
};

export default BeneficiarySummary;
