import { useEffect, useLayoutEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import bg from "../../images/danapay_login.png";
import bg_en from "../../images/danapay_login_en.png";
import InputField from "../../components/forms/InputField";
import { useHistory, useLocation } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { Formik, Form } from "formik";
import { signUp } from "../../store/features/Auth/Auth";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import Modal from "@material-ui/core/Modal";
import * as Sentry from "@sentry/browser";
import {
  loginUser,
  set_external_user,
} from "../../store/features/Auth/AuthSlice";
import { extractErrorMessage } from "../../helpers";
import { Alert } from "@material-ui/lab";
import LangSwitch from "../../components/LangSwitch";
import { useTranslation } from "react-i18next";
import "../../helpers/i18n";
import * as Yup from "yup";
import PasswordField from "../../components/forms/PasswordField";
import { buildString, disableSpacePaste } from "../../utilities/help";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import GoogleCaptchaVerfication from "./GoogleCaptchaVerfication";
import AndroidButton from "../../components/AndroidButton";
import IOSButton from "../../components/IOSButton";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("password_required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?\-_\/])[A-Za-z\d#$@!%&*?\-_\/]{8,30}$/g,
      "P_REG"
    ),
  email: Yup.string().email("email_not_valid").required("email_required"),
  confirmPassword: Yup.string()
    .required("password_required")
    .oneOf([Yup.ref("password"), null], "PSM"),
});

const useStyles = makeStyles({
  root: {
    width: "100wv)",
    height: "100vh",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
  },
  form__header: {
    backgroundColor: "#fff",
    height: 80,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 20,
    paddingRight: 20,
  },
  form__body: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "calc(100vh - 140px)",
  },
  form__footer: {
    padding: 10,
    height: 60,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  footSPan: {
    fontSize: 13,
    marginRight: 20,
    fontWeight: "bold",
    color: "#666",
  },
  logo: {
    height: 40,
    width: 40,
  },
  form__header_brand: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  form__header_links: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    flex: 1,
  },
  brandName: {
    fontWeight: "bold",
    fontSize: 20,
    marginLeft: 5,
  },
  btn: {
    textTransform: "capitalize",
    marginRight: 15,
    paddingLeft: 20,
    paddingRight: 20,
  },

  formBox: {
    marginBottom: 40,
    marginTop: 10,
  },
  formField: {
    width: "70%",
  },
});

declare global {
  interface Window {
    // ⚠️ notice that "Window" is capitalized here
    heap: any;
  }
}

const Join = () => {
  const { search } = useLocation();
  const queryURL: any = new URLSearchParams(search);
  const { t } = useTranslation();
  const lang = useAppSelector((state) => state.persistedReducer.auth.lang);
  const [size, setSize] = useState([0, 0]);
  const [email, setEmail] = useState("");
  const [errorMessage, showErrorMessage] = useState<any>([]);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [captchaToken, setCaptchaToken] = useState<any>("");

  const [typeOfUser, settypeOfUser] = useState([
    {
      title: "Business",
      value: "business",
      active: false,
    },
    {
      title: "Individual",
      value: "individual",
      active: false,
    },
  ]);
  const [showRedirectToMobileModal, setshowRedirectToMobileModal] =
    useState(false);
  const handleTypeOfUserSelection = (value: any) => {
    if (value == "individual") {
      settypeOfUser((prev: any) =>
        prev.map((itm: any) => ({ ...itm, active: false }))
      );
      setshowRedirectToMobileModal(true);
    } else {
      settypeOfUser((prev: any) =>
        prev.map((itm: any) =>
          itm.value == value
            ? { ...itm, active: true }
            : { ...itm, active: false }
        )
      );
    }
  };
  const [systemos, setsystemos] = useState("");

  const setParams = () => {
    if (queryURL.get("email")) {
      setEmail(queryURL.get("email"));
      const data: any = {
        email: queryURL.get("email"),
        phone: queryURL.get("phone"),
        country: queryURL.get("country"),
        redirect_uri: queryURL.get("redirect_uri"),
      };
      dispatch(set_external_user(data));
    }
  };

  useEffect(() => {}, [email]);

  useLayoutEffect(() => {
    setParams();
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    function getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
      }

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent)) {
        return "iOS";
      }

      return "unknown";
    }

    const systemOs = getMobileOperatingSystem();
    if (systemOs !== "unknown") {
      setsystemos(systemOs);
    }
  }, []);

  const redirectionButtonClasses =
    "text-sm rounded-lg flex justify-center items-center gap-2 btn flex-1";

  return (
    <div className="w-screen h-screen m-0 overflow-hidden row">
      {/* redirect to mobile app modal */}
      <Modal
        open={showRedirectToMobileModal}
        onClose={() => setshowRedirectToMobileModal(false)}
      >
        <div className="m-auto flex justify-center row">
          <div className="mt-20 col-md-6 col-12 col-sm-8 col-lg-5 col-xl-4">
            <div className="p-3 bg-white rounded-md">
              <div className="flex flex-row items-center justify-between mb-2">
                <div>
                  <b>{t("registrationModalTitle")}</b>
                </div>
                <button
                  onClick={() => setshowRedirectToMobileModal(false)}
                  style={{ height: 40, width: 40, borderRadius: 20 }}
                  className="flex items-center justify-center shadow-lg"
                >
                  <i className="m-0 fa fa-close"></i>
                </button>
              </div>
              <hr className="mb-2 text-gray-400" />
              <small className="my-3 text-gray-400">
                {t("registrationModalMessage")}
              </small>

              <div className="flex wrap gap-2 justify-center mt-3 my-2">
                {systemos !== "iOS" && (
                  <AndroidButton
                    t={t}
                    onClick={() =>
                      window.location.replace(
                        "https://play.google.com/store/search?q=danapay&c=apps&pli=1"
                      )
                    }
                    redirectionButtonClasses={redirectionButtonClasses}
                  />
                )}

                {systemos !== "Android" && (
                  <IOSButton
                    t={t}
                    onClick={() =>
                      window.location.replace(
                        "https://apps.apple.com/ug/app/danapay-transfer/id6451115466"
                      )
                    }
                    redirectionButtonClasses={redirectionButtonClasses}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <div className={`h-full p-0 overflow-scroll flex flex-col col-md-5`}>
        <div className="flex flex-row justify-between p-4">
          <div>
            <LangSwitch />
          </div>
        </div>
        <div className="flex items-center justify-center h-screen sm:py-4 sm:px-4 md:py-4 md:px-4 lg:px-10 lg:py-10">
          <div
            className="p-4 m-auto bg-white rounded-lg"
            style={{ width: "95%" }}
          >
            <h2 className="mb-2 text-2xl font-bold">{t("sutitile")}</h2>
            <p className="mb-4 text-sm">{t("suSubtitle")}</p>
            <Formik
              initialValues={{
                email: email || "",
                password: "",
                confirmPassword: "",
                captcha: "",
              }}
              enableReinitialize={true}
              onSubmit={(data: any, { setSubmitting }) => {
                window?.heap?.identify(data?.email, "email");
                setSubmitting(true);
                showErrorMessage([]);
                data.captcha = captchaToken;
                if (
                  typeOfUser.find((itm: any) => itm.active)?.value ===
                  "individual"
                ) {
                  setshowRedirectToMobileModal(true);
                  return;
                }
                captchaToken !== null &&
                  signUp({
                    ...data,
                    password_confirmation: data.confirmPassword,
                  })
                    .then((result) => {
                      window.heap.track("identification details", {
                        email: data?.email,
                      });
                      setSubmitting(false);
                      dispatch(loginUser(result));
                      setCaptchaToken("");
                      history.push("verify");
                    })
                    .catch((error: any) => {
                      Sentry.captureException(error);
                      setSubmitting(false);
                      const err = extractErrorMessage(error);
                      const errorArray =
                        err.map((errorText: string) =>
                          buildString(errorText?.toLowerCase())
                        ) || [];
                      showErrorMessage(errorArray);
                    });
              }}
              validationSchema={validationSchema}
            >
              {({
                values,
                handleChange,
                handleBlur,
                errors,
                touched,
                isSubmitting,
              }) => (
                <Form className={classes.formBox}>
                  {/* <small className="text-gray-700">
                    {t("Account_Type")}
                  </small> */}
                  {/* <div className="bacnkCheckBoxWrapper bacnkCheckBoxWrapper flex flex-col gap-2 p-2  ">
                    {typeOfUser.map(
                      (usertype: any, index: any) => {
                        return (
                          <div key={index} className="wrapperBankCheckBox m-0 innerBankBox">
                            <div className="wrapperBankCheckBoxwrapper">
                              <input
                                type="checkbox"
                                className="default:ring-2"
                                name={usertype.value}
                                onChange={() => { handleTypeOfUserSelection(usertype.value); }}
                                checked={usertype.active}
                              />
                              <span className="checkmark"></span>
                            </div>
                            <label>
                              {t(
                                usertype?.title
                              )}
                            </label>
                          </div>
                        );
                      }
                    )}
                  </div> */}

                  <InputField
                    extraClasses="mb-3 customInputStyle"
                    name="email"
                    type="text"
                    handleChange={(e: any) => {
                      if (disableSpacePaste("email", e.target.value)) {
                        handleChange("email")(
                          disableSpacePaste("email", e.target.value)
                        );
                      } else {
                        handleChange("email")(e.target.value);
                      }
                    }}
                    onBlur={handleBlur}
                    placeholder={t("Email_Address")}
                    value={values.email}
                    error={t(`${errors.email}`)}
                    touched={touched.email}
                  />

                  <PasswordField
                    name="password"
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t("password")}
                    value={values.password}
                    error={t(`${errors.password}`)}
                    touched={touched.password}
                    type="password"
                  />
                  <PasswordField
                    name="confirmPassword"
                    handleChange={handleChange}
                    placeholder={t("confirmPass")}
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                    error={t(`${errors.confirmPassword}`)}
                    touched={touched.confirmPassword}
                    type="password"
                  />
                  <GoogleReCaptchaProvider
                    reCaptchaKey={
                      process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""
                    }
                  >
                    <GoogleCaptchaVerfication
                      parentCallBack={(e: any) => setCaptchaToken(e)}
                    />
                  </GoogleReCaptchaProvider>
                  <br />

                  <div className="flex flex-row items-center justify-between mt-2">
                    <div className="sm:flex-1 md:flex-1">
                      <button
                        className="rounded-lg btn"
                        style={{ backgroundColor: "rgb(3, 115, 117)" }}
                        type="submit"
                        disabled={captchaToken === "" ? true : isSubmitting}
                      >
                        <span className="text-white capitalize">
                          {t("register")}
                        </span>
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            {errorMessage.length > 0 && (
              <div className="mb-4">
                <Alert severity="error">
                  {errorMessage.map((errorText: string, index: number) => (
                    <p>
                      <small key={index}>{t(errorText)}</small>
                    </p>
                  ))}
                </Alert>
              </div>
            )}
            <div className="my-10 text-center">
              <small>
                {t("click_here_to")}
                <Link
                  to="/login"
                  className="font-bold rounded"
                  style={{ color: "rgb(3, 115, 117)" }}
                >
                  {t("login")}
                </Link>
              </small>
            </div>

            <small
              className="my-2 text-xs text-center text-gray-600"
              style={{ fontSize: 11 }}
            >
              {t("terms_condition_1")}{" "}
              <a
                className="font-bold"
                style={{ color: "rgb(3, 115, 117)" }}
                target="_blank"
                href="https://www.danapay.com/conditions/conditions-g%C3%A9n%C3%A9rales-politique-de-confidentialit%C3%A9"
              >
                {t("privacy_policy")}{" "}
              </a>
              {t("terms_condition_2")}{" "}
              <a
                className="font-bold"
                style={{ color: "rgb(3, 115, 117)" }}
                target="_blank"
                href="https://www.danapay.com/conditions/conditions-g%C3%A9n%C3%A9rales-politique-de-confidentialit%C3%A9"
              >
                {t("terms_and_condition")}
              </a>
            </small>
          </div>
        </div>
      </div>
      {size[0] > 750 && (
        <div
          className="h-full p-0 shadow-lg col-md-7"
          style={{
            backgroundImage: `url(${lang === "en" ? bg_en : bg})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        ></div>
      )}
    </div>
  );
};

export default Join;
