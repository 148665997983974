import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import bg from "../../images/danapay_login.png";
import bg_en from "../../images/danapay_login_en.png";
import { changePassword } from "../../store/features/Auth/Auth";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import * as Sentry from "@sentry/browser";
import { loginUser, editUser } from "../../store/features/Auth/AuthSlice";
import LangSwitch from "../../components/LangSwitch";
import { Formik, Form } from "formik";
import { toast } from "material-react-toastify";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../helpers/i18n";
import * as yup from "yup";
import PasswordField from "../../components/forms/PasswordField";
import { currentBalance } from "../../store/features/Transfer/Transfer";

const useStyles = makeStyles({
  root: {
    width: "100wv)",
    height: "100vh",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
  },
  form__header: {
    backgroundColor: "#fff",
    height: 80,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 20,
    paddingRight: 20,
  },
  form__body: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "calc(100vh - 140px)",
  },
  form__footer: {
    padding: 10,
    height: 60,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  footSPan: {
    fontSize: 13,
    marginRight: 20,
    fontWeight: "bold",
    color: "#666",
  },
  logo: {
    height: 40,
  },
  form__header_brand: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  form__header_links: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    flex: 1,
  },
  brandName: {
    fontWeight: "bold",
    fontSize: 20,
    marginLeft: 5,
  },
  btn: {
    textTransform: "capitalize",
    marginRight: 15,
    paddingLeft: 20,
    paddingRight: 20,
  },

  formBox: {
    marginBottom: 40,
    marginTop: 10,
  },
  formField: {
    width: "70%",
  },
});

const validationSchema = yup.object().shape({
  oldPassword: yup.string().required("password_required"),
  newPassword: yup
    .string()
    .required("password_required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?\-_])[A-Za-z\d#$@!%&*?\-_]{8,30}$/g,
      "P_REG"
    ),
  newPassword_confirmation: yup
    .string()
    .required("password_required")
    .oneOf([yup.ref("newPassword"), null], "PSM"),
});

const ChangePassword = () => {
  const {
    auth: { lang },
    transfer,
  } = useAppSelector((state) => state.persistedReducer);
  const [error, setError] = useState("");
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className="w-screen h-screen overflow-hidden row">
      <div className="p-0 col-md-5">
        <div className="flex flex-row justify-between p-4 shadow-md">
          <LangSwitch />
        </div>
        <div
          style={{ minHeight: 800 }}
          className="flex items-center justify-center"
        >
          <div
            className="p-4 m-auto rounded-lg shadow-lg"
            style={{ width: "80%" }}
          >
            <img src="images/logofull.png" style={{ height: 20 }} alt="" />{" "}
            <br />
            <h2 className="text-2xl font-bold"> {t("changePass")}</h2>
            <p className="my-2">{t("changePassSub")}</p>
            <Formik
              initialValues={{
                newPassword_confirmation: "",
                oldPassword: "",
                newPassword: "",
              }}
              onSubmit={(data, { setSubmitting }) => {
                setSubmitting(true);
                setError("");
                try {
                  changePassword(data)
                    .then((res: any) => {
                      if (res.access_token) {
                        localStorage.setItem("user:key", res.access_token);
                        dispatch(loginUser(res));
                        currentBalance()
                          .then((result: any) => {
                            dispatch(editUser(result));
                            if (
                              result.client.type.toLowerCase() ===
                              "temporary-customer"
                            ) {
                              setSubmitting(false);
                              history.push("/register");
                              // check if user added company
                            } else if (
                              result.company === null &&
                              !result.is_individual
                            ) {
                              setSubmitting(false);
                              history.push("/register");
                            } else {
                              // check if user was from external APi
                              setSubmitting(false);
                              if (transfer.transferFromType === "internal") {
                                history.push("/dashboard/home");
                              } else {
                                history.push("/complete_form");
                              }
                            }
                          })
                          .catch((error: any) => {
                            setError(error.data.message);

                            Sentry.captureException(error);
                          });
                      } else {
                        setError("");
                      }
                    })
                    .catch((error: any) => {
                      setSubmitting(false);
                      setError(error.data.message);
                      toast.warning(t(error?.data?.message));
                      Sentry.captureException(error);
                    });
                } catch (error: any) {
                  Sentry.captureException(error);
                }
              }}
              validationSchema={validationSchema}
            >
              {({
                values,
                handleChange,
                handleBlur,
                errors,
                touched,
                isSubmitting,
              }) => (
                <Form className={classes.formBox}>
                  <PasswordField
                    name="oldPassword"
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t("oldPass")}
                    value={values.oldPassword}
                    label={t("oldPass")}
                    error={t(`${errors.oldPassword}`)}
                    type="password"
                    touched={touched.oldPassword}
                  />
                  <PasswordField
                    name="newPassword"
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t("newPass")}
                    value={values.newPassword}
                    label={t("newPass")}
                    error={t(`${errors.newPassword}`)}
                    type="password"
                    touched={touched.newPassword}
                  />
                  <PasswordField
                    name="newPassword_confirmation"
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t("confirmPass")}
                    value={values.newPassword_confirmation}
                    label={t("confirmPass")}
                    error={t(`${errors.newPassword_confirmation}`)}
                    type="password"
                    touched={touched.newPassword_confirmation}
                  />

                  <div className="flex flex-row items-center justify-between mt-10">
                    <button
                      className="rounded-lg btn"
                      style={{ backgroundColor: "rgb(3, 115, 117)" }}
                      disabled={isSubmitting}
                      type="submit"
                    >
                      <small className="text-white">
                        <b> {t("Change_Password")}</b>
                      </small>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
            {error !== "" && (
              <div className="p-2 my-2 bg-red-100">
                <p className="text-red-700">{error}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="h-screen p-0 col-md-7"
        style={{
          backgroundImage: `url(${lang === "en" ? bg_en : bg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      ></div>
    </div>
  );
};

export default ChangePassword;
