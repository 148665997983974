import { useTranslation } from "react-i18next";

const PaymentMethodDescriptions = (props: any) => {
  const { value, paymentMethod, disabled } = props;
  const { t } = useTranslation();
  return (
    <div className={disabled ? "opacity-50" : ""}>
      {value.cash_in_method?.name.toLowerCase() === paymentMethod && (
        <div className="text-sm mb-2 innerBankBox">
          {t("max_limit")}
          {value?.cash_in_method?.max_amount} EUR
        </div>
      )}

      {value?.cash_in_method?.name?.toLowerCase() === "bank transfer" &&
        value.cash_in_method?.name.toLowerCase() === paymentMethod && (
          <div className="text-sm innerBoxDesc mb-2 innerBankBox">
            <b>{t("bank_transfer_instructions_title")}</b>
            {t("bank_transfer_instructions")}
          </div>
        )}
      {value?.cash_in_method?.name?.toLowerCase() === "balance" &&
        value.cash_in_method?.name.toLowerCase() === paymentMethod && (
          <div className="text-sm innerBoxDesc mb-2 innerBankBox">
            <b>{t("balance_instructions_title")}</b>
            {t("balance_instructions")}
          </div>
        )}
      {value?.cash_in_method?.name?.toLowerCase() === "bank card" &&
        value.cash_in_method?.name.toLowerCase() === paymentMethod && (
          <div className="text-sm innerBoxDesc mb-2 innerBankBox">
            <b>{t("bank_card_instructions_title")}</b>{" "}
            {t("bank_card_instructions")}
          </div>
        )}
    </div>
  );
};

export default PaymentMethodDescriptions;
