import { useState } from "react";
import { sendResendCode } from "../../store/features/Transfer/Transfer";
import { translateError } from "../../utilities/help";
import { extractError } from "../../utility";
import * as Sentry from "@sentry/browser";
import { toast } from "material-react-toastify";

const WithdrawVerificationModal = (props: any) => {
  const {
    size,
    steps,
    t,
    c_channel,
    setCommunicationChannel,
    setOTP,
    lang,
    handlePrev,
    cashoutResponse,
    confirmOTP,
  } = props;

  const [resending, setResending] = useState<boolean>(false);

  const resendCode = () => {
    try {
      setResending(true);
      sendResendCode({ channel: c_channel }, cashoutResponse?.cashout.id)
        .then((res: any) => {
          setResending(false);
          toast.info(t("code_sent"));
        })
        .catch((error: any) => {
          setResending(false);
          toast.warning(t(error?.data?.message));
          Sentry.captureException(error);
          // toast.error(translateError(extractError(error), t));
        });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  return (
    <div className="container">
      <div className="row">
        {size[0] < 900 && (
          <div className="py-4 col-md-12 text-center">
            <h2>{steps[2]}</h2>
          </div>
        )}
        <div className="my-3 text-center rounded-lg col-md-8 col-lg-6 mx-auto">
          <div style={{ marginTop: 10 }} className="p-10 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold">
              {t("Type_verification_code")}
            </h2>
            <div className="py-4 flex-center gap-3">
              <div
                className={`cursor-pointer text-sm ${
                  c_channel === "mail" ? "border-b-4 border-green-700" : ""
                } ${
                  c_channel === "mail"
                    ? "font-bold text-green-700"
                    : "text-gray-600"
                }`}
                onClick={() => setCommunicationChannel("mail")}
              >
                {t("send_by_mail")}
              </div>

              <div
                className={`cursor-pointer text-sm ${
                  c_channel === "sms" ? "border-b-4 border-green-700" : ""
                } ${
                  c_channel === "sms"
                    ? "font-bold text-green-700"
                    : "text-gray-600"
                }`}
                onClick={() => setCommunicationChannel("sms")}
              >
                {t("send_by_sms")}
              </div>
            </div>

            <div className="flex md:flex-row max-sm:flex-col flex-wrap justify-center items-center bg-gray-200 rounded-lg">
              <input
                type="text"
                className="max-sm:placeholder:text-center max-sm:text-center flex flex-1 placeholder-gray-500 bg-gray-200 border-none rounded-lg focus:outline-none form-control placeholder:text-sm laceholder:italic"
                required
                maxLength={6}
                onChange={(e: any) => setOTP(e.target.value)}
                placeholder="code"
              />
              {resending ? (
                <small className="px-3 mr-4 text-green-800">
                  {t("sending")}
                </small>
              ) : (
                <button
                  className="px-4 my-3 rounded-lg btn font-bold text-white text-[12.6px]"
                  style={{ backgroundColor: "rgb(3, 115, 117)" }}
                  onClick={() => resendCode()}
                >
                  {t("send_code")}
                </button>
              )}
            </div>

            {lang === "fr" ? (
              <small className="text-gray-700">
                Cliquez sur <b>Envoyer le code</b> pour recevoir un nouveau
                code.
              </small>
            ) : (
              <small className="text-gray-700">
                Click on <b>Send code</b> to receive a new code
              </small>
            )}

            {resending ? (
              <div className="flex items-center justify-center p-4">
                <small className="font-bold">{t("resending")}...</small>
              </div>
            ) : (
              <>
                <div className="flex gap-3 mt-3 max-sm:flex-col flex-wrap">
                  <button
                    className="border flex-1 rounded-lg btn text-[12.6px] font-bold text-black"
                    onClick={handlePrev}
                  >
                    {t("back")}
                  </button>
                  <button
                    className="rounded-lg btn flex-1 font-bold text-white text-[12.6px]"
                    style={{ backgroundColor: "rgb(3, 115, 117)" }}
                    onClick={() => confirmOTP()}
                  >
                    {t("ConfirmCashout")}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawVerificationModal;
