import { Modal } from "@material-ui/core";
import { Formik } from "formik";
import FormComp from "./form";
import {
  updateUserBankAccount,
  updateUserMMAccount,
} from "../../store/features/Auth/Auth";
import { extractErrorMessage } from "../../helpers";
import { buildString, getCountry } from "../../utilities/help";
import * as Sentry from "@sentry/browser";

import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from "libphonenumber-js";
import ScrollableModal from "../ScrollableModal";
import { toast } from "material-react-toastify";

const EditAccountModal = (props: any) => {
  const {
    open,
    onClose,
    sethub2BankAccounts,
    t,
    selected_account,
    hub2BankAccounts,
    setProcessing,
    transfer,
    type,
    fetchBankAccount,
    yup,
    fetchMMAccount,
    fetchingBankNames,
    fetchHub2BanksAccordingToCountry,
    processing,
  } = props;

  const validationSchema = yup.object().shape({
    title: yup.string().required("title_required"),
    customBankName:
      type === "bank"
        ? yup
            .string()
            .test(
              "yourTestCondition",
              "bank_name_required",
              function (value: any, context: any) {
                return (
                  (this.parent.bank_name !== "Other" &&
                    this.parent.bank_name !== "" &&
                    this.parent.bank_name !== undefined) ||
                  (this.parent.bank_name == "Other" &&
                    this.parent.customBankName !== undefined &&
                    this.parent.customBankName !== "")
                );
              }
            )
        : yup.string().nullable(),
    bank_name:
      type === "bank"
        ? yup.string().required("bank_name_required")
        : yup.string().nullable(),
    bic:
      type === "bank"
        ? yup.string().required("bic_required")
        : yup.string().nullable(),
    iban:
      type === "bank"
        ? yup.string().required("iban_required")
        : yup.string().nullable(),
    owner: yup.string().required("owner_required"),
    phone_number:
      type === "bank"
        ? yup.string().nullable()
        : yup
            .string()
            .test(
              "phone_validation",
              "invalid_phone_number",
              function (val: any) {
                try {
                  const country = getCountry(transfer, this.parent?.country);

                  if (country && country?.country_code && val && val !== "") {
                    const receipentNumber =
                      "+" +
                      country?.country_code?.toString() +
                      " " +
                      val?.toString();
                    return (
                      isValidPhoneNumber(receipentNumber) &&
                      isPossiblePhoneNumber(receipentNumber) &&
                      validatePhoneNumberLength(receipentNumber) == undefined
                    );
                  } else {
                    return true;
                  }
                } catch (error) {
                  Sentry.captureException(error);
                }
              }
            )
            .required("phone_number_required"),
    // operator:
    //   type === "bank"
    //     ? yup.string().nullable()
    //     : yup.string().required("operator_required"),
    operator:
      type === "bank"
        ? yup.string().nullable()
        : transfer?.getAllMobileOperator?.find(
            (val: any) =>
              val.cash_out_method.name ===
              transfer?.getAllMobileOperator?.find((val: any) => {
                if (
                  val?.cash_out_method?.name === "Mobile Money" ||
                  val.cash_out_method?.name === "Mobile Money by Hub2"
                ) {
                  return val;
                }
              })?.cash_out_method?.name
          )?.providers !== undefined &&
          yup.string().required("operator_required"),
    Other:
      type === "bank"
        ? yup.string().nullable()
        : transfer?.getAllMobileOperator?.find(
            (val: any) =>
              val.cash_out_method.name ===
              transfer?.getAllMobileOperator?.find((val: any) => {
                if (
                  val?.cash_out_method?.name === "Mobile Money" ||
                  val.cash_out_method?.name === "Mobile Money by Hub2"
                ) {
                  return val;
                }
              })?.cash_out_method?.name
          )?.providers === undefined && yup.string(),
    country: yup.string().required(),
  });

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        sethub2BankAccounts([]);
      }}
    >
      <ScrollableModal>
        <div className="p-3 bg-white rounded col-11 col-md-8 col-lg-6 addaccountmodal mx-auto mb-4">
          <div className="flex flex-row items-center justify-between">
            <h3>{t("Edit_Account")}</h3>
            <button
              onClick={() => onClose()}
              style={{ height: 40, width: 40, borderRadius: 20 }}
              className="flex items-center justify-center shadow-lg"
            >
              <i className="m-0 fa fa-close"></i>
            </button>
          </div>
          <Formik
            enableReinitialize
            initialValues={{
              title: selected_account?.account?.title || "",
              bank_name: selected_account?.bank_name || "",
              bic: selected_account?.bic || "",
              iban: selected_account?.iban || "",
              owner: selected_account?.account?.owner_name || "",
              phone_number: selected_account?.phone_number || "",
              operator: selected_account?.operator || "",
              country: selected_account?.account?.country || "",
              Other: "",
              my_account: "Account",
              customBankName:
                hub2BankAccounts.find(
                  (itm: any) => itm.name == selected_account?.bank_name
                ) == undefined
                  ? selected_account?.bank_name
                  : "",
            }}
            onSubmit={(data: any) => {
              try {
                setProcessing(true);
                let selected_country = null;
                if (data.country.length === 0) {
                  selected_country = {
                    name: selected_account.country,
                    country_code: selected_account?.country_code,
                  };
                } else {
                  try {
                    selected_country = JSON.parse(data.country);
                  } catch (error: any) {
                    selected_country = transfer?.danaPayCountries.find(
                      (val: any) =>
                        val?.name.toString() === data?.country.toString()
                    );
                  }
                }

                if (type === "bank") {
                  delete data.operator;
                  delete data.phone_number;

                  const account = {
                    title: data?.title,
                    country: selected_country?.name,
                    iban: data?.iban,
                    bic: data?.bic,
                    owner_name: data?.owner,
                    // bank_name: data?.bank_name,
                    bank_name:
                      data?.bank_name == "Other"
                        ? data?.customBankName
                        : data?.bank_name,
                    country_code: selected_country?.country_code,
                  };

                  updateUserBankAccount(account, selected_account.id)
                    .then((response: any) => {
                      setProcessing(false);
                      toast.success(t("Changed_successfully"));
                      onClose();
                      fetchBankAccount();
                    })
                    .catch((error: any) => {
                      Sentry.captureException(error);

                      setProcessing(false);
                      const err = extractErrorMessage(error);
                      const errorArray = err.map((errorText: string) =>
                        buildString(errorText?.toLowerCase())
                      );
                      toast.error(errorArray);
                    })
                    .finally(() => sethub2BankAccounts([]));
                } else {
                  delete data.bic;
                  delete data.iban;
                  delete data.bank_name;
                  const account = {
                    title: data.title,
                    country: selected_country.name,
                    owner_name: data.owner,
                    operator: data.operator,
                    phone_number: Number(data.phone_number),
                    country_code: selected_country?.country_code,
                  };
                  updateUserMMAccount(account, selected_account.id)
                    .then((response: any) => {
                      setProcessing(false);
                      toast.success(t("Changed_successfully"));
                      onClose();
                      fetchMMAccount();
                    })
                    .catch((error: any) => {
                      Sentry.captureException(error);

                      setProcessing(false);
                      const err = extractErrorMessage(error);
                      const errorArray = err.map((errorText: string) =>
                        buildString(errorText?.toLowerCase())
                      );
                      toast.error(errorArray.join(" "));
                    });
                }
              } catch (error: any) {
                Sentry.captureException(error);
              }
            }}
            validationSchema={validationSchema}
          >
            {({
              values,
              handleChange,
              handleBlur,
              errors,
              touched,
              isValid,
            }) => (
              <FormComp
                isValid={isValid}
                selected_account={selected_account}
                fetchingBankNames={fetchingBankNames}
                fetchHub2BanksAccordingToCountry={
                  fetchHub2BanksAccordingToCountry
                }
                hub2BankAccounts={hub2BankAccounts}
                values={values}
                handleBlur={handleBlur}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                type={type}
                t={t}
                action="edit"
                processing={processing}
              />
            )}
          </Formik>
        </div>
      </ScrollableModal>
    </Modal>
  );
};

export default EditAccountModal;
