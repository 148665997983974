import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import * as Sentry from "@sentry/browser";

const Contact: React.FC<any> = ({
  contact,
  moveToTransfer,
  is_active = false,
  keyprop,
}) => {
  const [name, setName] = useState("");
  useEffect(() => {
    try {
      if (contact?.favorite.is_individual) {
        const { first_name, last_name, phone_number } = contact?.favorite;
        if (first_name !== "" && first_name !== null) {
          const user_name = first_name?.split("")?.splice(0, 5)?.join("");
          const last_name_initial = last_name[0];
          setName(`${user_name}.${last_name_initial}`);
        } else if (last_name !== "" && last_name !== null) {
          const user_name = last_name?.split("")?.splice(0, 5)?.join("");
          setName(user_name);
        } else if (first_name == null && last_name == null) {
          const result =
            phone_number?.toString()?.substr(0, 4) +
            "...." +
            phone_number
              ?.toString()
              ?.substr(phone_number?.length - 4, phone_number?.length);
          setName(result);
        }
      } else {
        const { company } = contact?.favorite;
        const company_name = company?.name?.split("")?.splice(0, 7)?.join("");
        setName(company_name);
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, [contact]);

  return (
    <div
      style={{ minHeight: 90, width: 90 }}
      key={keyprop}
      className={`flex flex-col items-center justify-center px-3 cursor-pointer`}
      onClick={() => moveToTransfer()}
    >
      <Avatar name={name} size="55" round textSizeRatio={3} />
      <small
        className={` text-center capitalize ${
          is_active ? "text-green-800 font-bold" : "text-gray-700"
        }`}
        style={{ fontSize: 11, marginTop: 5 }}
      >
        {name}
      </small>
    </div>
  );
};

export default Contact;
