import { Modal } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const CustomModal = (props: any) => {
  const {
    containerClasses,
    titleClasses,
    title,
    contentClasses,
    content,
    modalClasses,
    backBtnClasses,
    nextBtnClasses,
    onNext,
    onBack,
    onBackTitle = "back",
    onNextTitle = "next",
    buttonContainerClasses,
    contentComponent,
    open,
    onClose,
  } = props;

  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={onClose && onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={containerClasses}>
        <div className={modalClasses}>
          <div className={titleClasses}>{t(title)}</div>
          <div className={contentClasses}>
            {contentComponent ? contentComponent : t(content)}
          </div>

          <div className={buttonContainerClasses}>
            {onBack && (
              <button
                className={`w-full rounded-lg btn btn-block rounded-lg-primary text-white md:text-md max-sm:!text-sm ${backBtnClasses}`}
                style={{ backgroundColor: "rgb(3, 115, 117)" }}
                onClick={onBack}
              >
                {t(onBackTitle)}
              </button>
            )}
            {onNext && (
              <button
                className={`w-full rounded-lg btn btn-block rounded-lg-primary text-white md:text-md max-sm:!text-sm ${nextBtnClasses}`}
                style={{ backgroundColor: "rgb(3, 115, 117)" }}
                onClick={onNext}
              >
                {t(onNextTitle)}
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;
