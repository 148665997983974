import { useEffect } from "react";

const CustomerIOTracker: React.FC = () => {
  useEffect(() => {
    const _cio = (window as any)._cio || [];
    (window as any)._cio = _cio;

    let c: number;

    const a: (f: string) => (...args: any[]) => void = function (f) {
      return function (...args: any[]) {
        _cio.push([f].concat(args));
      };
    };

    const b: string[] = [
      "load",
      "identify",
      "sidentify",
      "track",
      "page",
      "on",
      "off",
    ];
    // "on" and "off" are necessary if you want to display in-app messages

    for (c = 0; c < b.length; c++) {
      _cio[b[c]] = a(b[c]);
    }

    const t = document.createElement("script"),
      s = document.getElementsByTagName("script")[0];
    t.async = true;
    t.id = "cio-tracker";
    t.setAttribute("data-site-id", process.env.REACT_APP_CUSTOMERIO_SITE_ID!);
    t.src = "https://assets.customer.io/assets/track-eu.js";
    s.parentNode!.insertBefore(t, s);
  }, []);

  return null;
};

export default CustomerIOTracker;
