import { useTranslation } from "react-i18next";

const CashInMethodDescriptions = (props: any) => {
  const { value, paymentMethod, disabled } = props;
  const { t } = useTranslation();
  return (
    <div className={disabled ? "opacity-50" : ""}>
      {value?.cash_in_method?.name.toLowerCase() == "bank transfer" &&
        value.cash_in_method?.payment_type?.name.toLowerCase() ===
          paymentMethod && (
          <div className="innerBoxDesc my-2 innerBankBox">
            <p>
              <b>{t("bank_transfer_instruction_title")} : </b>{" "}
              {t("bank_transfer_instruction_description")}
            </p>
          </div>
        )}

      {value?.cash_in_method?.name.toLowerCase() == "bank card" &&
        value.cash_in_method?.payment_type?.name.toLowerCase() ===
          paymentMethod && (
          <div className="innerBoxDesc my-2 innerBankBox">
            <p>
              <b>{t("bank_card_instruction_title")} : </b>{" "}
              {t("bank_card_instruction_description")}
            </p>
          </div>
        )}
    </div>
  );
};

export default CashInMethodDescriptions;
