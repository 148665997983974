import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../store/hooks";
import Country_comp from "./Country_comp";
import * as Sentry from "@sentry/browser";
import { getTransferCountries } from "../store/features/Transfer/Transfer";
import { toast } from "material-react-toastify";
import { updateDanaPayCountries } from "../store/features/Transfer/TransferSlice";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { isEmpty } from "../utilities/help";

type Props = {
  setCountry: any;
  country: any;
  withPhoneInput?: boolean;
  onChange?: any;
  value?: string;
  children?: any;
  withChildren?: boolean;
  country_selected?: boolean;
  placeholder?: string;
  textChange?: boolean;
  cash_Out_Method_Name: String;
  setSelectedCountry?: any;
  selected_mobile_code?: any;
  setselectedMobileOperator?: any;
  tab?: any;
};

const CountryPicker: React.FC<Props> = ({
  setCountry,
  selected_mobile_code,
  setselectedMobileOperator,
  country,
  withPhoneInput = false,
  onChange = null,
  value = "",
  children,
  withChildren = false,
  country_selected = false,
  placeholder,
  textChange,
  cash_Out_Method_Name,
  setSelectedCountry,
  tab,
}) => {
  const { transfer } = useAppSelector((state) => state.persistedReducer);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isMobileOperator, setIsMobileOperatorOpen] = useState(false);
  const [countrySelected, setCountrySelected] = useState(country_selected);
  const [all_countries, setAllCountries] = useState<any>([]);
  const [selected, setSelected] = useState<any>(null);
  const [checkedBox, setCheckedBox] = useState<boolean>(false);
  const [singleItem, setSingleItem] = useState<any>("");
  const openModal = () =>
    textChange === true
      ? setIsMobileOperatorOpen((prev) => !prev)
      : setIsOpen((prev) => !prev);

  useEffect(() => {
    if (tab !== undefined) {
      setSelected(null);
    }
  }, [tab]);

  // calculating countries
  const [calculatingCountries, setcalculatingCountries] = useState(true);
  const create_country_list = () => {
    try {
      const regions = [
        { content: "Europe", numbers: [3, 4, 5] },
        { content: "Africa", numbers: [2] },
        { content: "Middle East", numbers: [9, 8] },
      ];
      const all = regions.map((country: any) => {
        if (
          transfer?.loggedInUserCountry === undefined ||
          transfer?.loggedInUserCountry?.country_code === null
        ) {
          const dana_countries = transfer?.danaPayCountries
            .filter((val: any) => {
              const first_number = val?.country_code?.split("")[0];
              return country?.numbers?.includes(parseInt(first_number));
            })
            .map((value: any, index: any) => {
              return {
                name: value?.name,
                code: value?.code,
                country_code: value?.country_code,
                mobileCode: `+${value?.country_code}`,
                flag: `${value?.country_code}.png`,
                requires_post_code: value?.requires_post_code,
              };
            });
          return {
            ...country,
            countries: dana_countries,
          };
        }

        const dana_countries = transfer?.danaPayCountries
          .filter((val: any) => {
            const first_number = val?.country_code?.split("")[0];
            return country?.numbers?.includes(parseInt(first_number));
          })
          .map((value: any, index: any) => {
            return {
              name: value?.name,
              code: value?.code,
              country_code: value?.country_code,
              mobileCode: `+${value?.country_code}`,
              flag: `${value?.country_code}.png`,
              requires_post_code: value?.requires_post_code,
            };
          });
        return {
          ...country,
          countries: dana_countries,
        };
      });
      getDefaultCountries(all);
      setAllCountries(all);
    } catch (error: any) {
      setcalculatingCountries(false);
      Sentry.captureException(error);
    }
  };

  // set country by mobile code
  useEffect(() => {
    try {
      if (selected_mobile_code !== undefined && all_countries.length > 0) {
        const c: any = all_countries.reduce((sum: any, current: any) => {
          return [...sum, ...current.countries];
        }, []);
        const selectedCountry = c.find(
          (country: any) => country.mobileCode == selected_mobile_code
        );
        if (selectedCountry !== undefined) {
          setCountry(selectedCountry);
          setSelected(selectedCountry);
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [selected_mobile_code, all_countries, country]);

  const getDefaultCountries = (countries: any) => {
    try {
      setcalculatingCountries(false);
      if (country) {
        const c: any = countries.reduce((sum: any, current: any) => {
          return [...sum, ...current.countries];
        }, []);

        if (typeof country === "string") {
          const result: any = c.find(
            (cc: any) => cc?.name?.toLowerCase() === country?.toLowerCase()
          );
          setCountry(result ? result : null);
          setSelected(result ? result : null);
        } else {
          const result = c.find(
            (cc: any) =>
              cc?.name?.toLowerCase() === country?.name?.toLowerCase()
          );
          setCountry(result ? result : null);
          setSelected(result ? result : null);
        }
      }
    } catch (error: any) {
      Sentry.captureException(error);
      setcalculatingCountries(false);
    }
  };

  useEffect(() => {
    try {
      if (
        transfer?.danaPayCountries &&
        transfer?.danaPayCountries?.length > 0
      ) {
        create_country_list();
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [transfer?.loggedInUserCountry?.country_code, transfer?.danaPayCountries]);

  const numberInputInvalidChars = ["-", "+", "e"];

  const repeat = {
    name:
      transfer?.transactionToRepeat?.operator !== ""
        ? transfer?.transactionToRepeat?.operator
        : "",
  };

  useEffect(() => {
    if (transfer?.transactionToRepeat?.operator !== undefined) {
      setSelected(repeat);
      handleChangeBox(
        transfer?.transactionToRepeat?.operator === "" ? false : true,
        "",
        repeat
      );
    }
  }, [transfer?.transactionToRepeat?.operator]);

  const handleChangeBox = (event: any, value: any, providerItem: any) => {
    setselectedMobileOperator && setselectedMobileOperator(providerItem.name);
    setCheckedBox(
      event?.target?.checked === undefined ? event : event.target.checked
    );
    setSingleItem(
      event === true
        ? providerItem
        : event?.target?.checked === false
        ? ""
        : providerItem
    );
  };

  const [loadingCountries, setloadingCountries] = useState(true);
  // get countries on modal opening
  useEffect(() => {
    if (isOpen) {
      setloadingCountries(true);
      getTransferCountries()
        .then((response: any) => {
          dispatch(updateDanaPayCountries(response.data));
        })
        .catch((error: any) => {
          Sentry.captureException(error);
          toast.warning(t(error?.data?.message));
        })
        .finally(() => {
          setloadingCountries(false);
        });
    }
  }, [isOpen]);

  return (
    <>
      {calculatingCountries && (
        <div className="overflow-y-scroll" style={{ marginTop: 10 }}>
          <div className="p-2 w-full bg-[#fafafa] h-[40px] rounded flex justify-center items-center col-md-6 mx-auto">
            <CircularProgress size={10} />
          </div>
        </div>
      )}
      {!calculatingCountries && (
        <div
          className="flex flex-row items-center flex-wrap gap-2 justify-between w-full p-2 mt-2 mb-2 border-2 rounded-2"
          style={{
            background: "#fafafa",
            color: "#080707",
            border: "0px",
            padding: "15px 16px",
            margin: "7px 0px",
          }}
        >
          {selected &&
            selected?.name !== undefined &&
            selected?.name !== "" && (
              <div className="flex flex-row">
                {textChange !== true && (
                  <img
                    src={`../flags/${selected?.flag}`}
                    style={{
                      height: 20,
                      width: 20,
                    }}
                    className="mr-4"
                    alt=""
                  />
                )}
                <Country_comp eng_country_name={selected?.name} />
              </div>
            )}
          <a
            onClick={openModal}
            className="font-semibold cursor-pointer"
            style={{ color: "#037375" }}
          >
            <small className="mr-2" style={{ color: "rgb(3, 115, 117)" }}>
              {textChange === true
                ? t("Select_Mobile_Operator")
                : t("Select_Country")}
            </small>
          </a>
        </div>
      )}
      {withChildren && children}
      {withPhoneInput && countrySelected && (
        <div
          className="flex flex-row items-center p-1 border-2 rounded-2"
          style={{
            background: "#fafafa",
            color: "#080707",
            border: "0px",
            padding: "15px 16px",
            margin: "7px 0px",
          }}
        >
          <div className="px-1">
            <p className="text-sm font-bold">
              {textChange === true
                ? transfer?.beneficiary?.country_code
                : selected?.mobileCode}
            </p>
          </div>
          <input
            onChange={(text) => onChange(text.target.value)}
            value={value}
            className="flex-1 w-full p-2 text-sm font-bold "
            placeholder={placeholder}
            type="number"
            onKeyDown={(e) => {
              if (numberInputInvalidChars.includes(e.key)) {
                e.preventDefault();
              }
            }}
            style={{
              background: "#fafafa",
              color: "#080707",
              border: "0px",
              padding: "15px 16px",
              margin: "7px 0px",
            }}
          />
        </div>
      )}

      {/* mobile operator modal */}
      {textChange === true ? (
        <Modal open={isMobileOperator} onClose={openModal}>
          <div
            className={
              transfer.getAllMobileOperator.filter(
                (val: any) => val.providers
              ) > 0
                ? "overflow-y-scroll row h-1/2"
                : "overflow-y-scroll row"
            }
            style={{ marginTop: 10 }}
          >
            <div className="col-md-3"></div>
            <div
              className={
                transfer.getAllMobileOperator.filter(
                  (val: any) => val.providers
                ) > 0
                  ? "p-4 bg-white rounded col-md-6"
                  : "p-4 bg-white rounded flex flex-column col-md-6"
              }
            >
              <div className="my-2 text-center">
                <p>{t("Select_Mobile_Operator")}</p>
              </div>
              <div
                className={
                  transfer.getAllMobileOperator.filter(
                    (val: any) => val.providers
                  ) > 0
                    ? "row"
                    : "row flex my-3 justify-center align-items-center flex-grow-1"
                }
              >
                {transfer.getAllMobileOperator?.map(
                  (item: any, index: number) => (
                    <>
                      {item.cash_out_method.name === cash_Out_Method_Name &&
                        item?.providers?.map((item: any, indx: number) => (
                          <>
                            <div className="bacnkCheckBoxWrapper pad-10">
                              <div className="wrapperBankCheckBox innerBankBox marg-0">
                                <div className="wrapperBankCheckBoxwrapper">
                                  <input
                                    type="checkbox"
                                    className="default:ring-2"
                                    onChange={(e) =>
                                      handleChangeBox(e, item.name, item)
                                    }
                                    checked={item.name === singleItem.name}
                                    // checked={indx === 0 ? checkedBox : checkedMobileBox}
                                  />
                                  <span className="checkmark"></span>
                                </div>
                                <label>
                                  <Country_comp eng_country_name={item?.name} />
                                </label>
                              </div>
                            </div>
                          </>
                        ))}
                    </>
                  )
                )}
                {transfer.getAllMobileOperator.find(
                  (val: any) =>
                    val.cash_out_method.name === cash_Out_Method_Name
                ).providers === undefined ||
                transfer.getAllMobileOperator.find(
                  (val: any) =>
                    val.cash_out_method.name === cash_Out_Method_Name
                ).providers.length === 0 ? (
                  <small
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "20%",
                    }}
                    className="font-bold text-gray-600"
                  >
                    {t("No_Operator_Found")}
                  </small>
                ) : (
                  // <div className="overflow-y-scroll row">
                  //   <div className="p-4 bg-white rounded flex flex-column col-md-6"> */}
                  //     <div className="flex flex my-3 justify-content-center align-items-center flex-grow-1">

                  //       </div>
                  //     </div>
                  // </div>

                  <div className="mt-2 col-md-4">
                    <button
                      disabled={checkedBox === true ? false : true}
                      className="w-full rounded-lg btn btn-block"
                      style={{
                        backgroundColor: "rgb(3, 115, 117)",
                        color: "white",
                      }}
                      onClick={() => {
                        setCountry(singleItem);
                        setSelected(singleItem);
                        setCountrySelected(true);
                        setSelectedCountry(true);
                        openModal();
                      }}
                    >
                      {t("done")}
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-3"></div>
          </div>
        </Modal>
      ) : (
        <Modal open={isOpen} onClose={() => setIsOpen(false)}>
          {loadingCountries ? (
            <div
              className="overflow-y-scroll row h-1/2"
              style={{ marginTop: 10 }}
            >
              <div className="p-4 bg-white rounded flex justify-center items-center col-md-6 mx-auto">
                <CircularProgress />
              </div>
            </div>
          ) : (
            <div
              className="overflow-y-scroll row h-1/2"
              style={{ marginTop: 10 }}
            >
              <div className="p-4 bg-white rounded col-md-8 col-lg-6 mx-auto">
                <div className="my-2 text-center">
                  <p>{t("Select_Country")}</p>
                </div>
                <div className="row">
                  {all_countries?.map((country: any, index: number) => (
                    <div className=" col-md-4 col-sm-6 col-xs-6" key={index}>
                      <p className="my-3 text-sm font-bold">
                        {country.content}
                      </p>
                      {country?.countries?.map((item: any, indx: number) => (
                        <a
                          className="flex flex-row items-center my-4 cursor-pointer"
                          onClick={() => {
                            setCountry(item);
                            setSelected(item);
                            setCountrySelected(true);
                            openModal();
                          }}
                          key={indx}
                        >
                          <img
                            src={`../flags/${item.flag}`}
                            style={{
                              height: 20,
                              width: 20,
                              borderRadius: 15,
                            }}
                            className="mr-2"
                            alt=""
                          />
                          <small className="font-bold text-gray-600 hover:text-green-700">
                            <Country_comp eng_country_name={item?.name} />
                          </small>
                        </a>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default CountryPicker;
