import React, { useEffect, useMemo, useState } from "react";
import * as Sentry from "@sentry/browser";
import { useAppSelector } from "../../store/hooks";

const VerifiedUI = (props: any) => {
  const { redirectToWhatsapp, t } = props;
  return (
    <div
      onClick={() => redirectToWhatsapp()}
      className="flex gap-4 justify-between items-center w-full max-md:flex-col max-sm:items-center "
    >
      <div>
        <b className="block">
          {t("sumsub_individual_application_reviewed_title")}
        </b>
        {t("sumsub_individual_application_reviewed_1")}
        <b>{t("sumsub_individual_application_reviewed_2")}</b>
        {t("sumsub_individual_application_reviewed_3")}
      </div>
      <button
        onClick={() => redirectToWhatsapp()}
        className="px-3 text-white verifyBtn btn btn-sm max-sm:text-sm min-w-[200px]"
        style={{ backgroundColor: "#45AA3D" }}
      >
        {t("whatsapp_redirection")}
      </button>
    </div>
  );
};

const SubmittedUI = (props: any) => {
  const { user, setOpen, t, redirectToWhatsapp } = props;
  const isRedRetry = user?.verification_result == "RED-RETRY";
  if (isRedRetry) {
    return (
      <div
        className="flex gap-4 justify-between items-center w-full max-md:flex-col max-sm:items-center "
        onClick={() => setOpen(true)}
      >
        <div
          style={{ color: "#3F3F3F" }}
          className="text-wrap max-sm:text-sm flex flex-col min-md:w-[70%] max-md:text-center"
        >
          <div className="font-bold">
            {t("sumsub_individual_application_retry_title")}
          </div>
          <div>
            {t("sumsub_individual_application_retry_text_1")}{" "}
            {t("sumsub_individual_application_retry_text_2")}{" "}
            {t("sumsub_individual_application_retry_text_3")}
          </div>
        </div>
        <button
          onClick={() => setOpen(true)}
          className="px-3 text-white verifyBtn btn btn-sm max-sm:text-sm min-w-[200px] min-md:w-[30%]"
          style={{ backgroundColor: "#E19507" }}
        >
          {t("sumsub_individual_application_retry_cta")}
        </button>
      </div>
    );
  } else {
    return (
      <div
        className="flex gap-4 max-sm:flex-col max-sm:items-center "
        onClick={() => redirectToWhatsapp()}
      >
        <div style={{ color: "#3F3F3F" }} className="text-wrap max-sm:text-sm">
          <div className="font-bold">
            {t("sumsub_verification_applicant_submitted_title")}
          </div>
          {t("sumsub_verification_applicant_submitted_text_1")}
          <b>{t("sumsub_verification_applicant_submitted_text_bold_1")}</b>
          {t("sumsub_verification_applicant_submitted_text_2")}
          <b>{t("sumsub_verification_applicant_submitted_text_bold_2")}</b>
          {t("sumsub_verification_applicant_submitted_text_3")}
        </div>
        <button
          onClick={() => redirectToWhatsapp()}
          className="px-3 text-white verifyBtn btn min-w-[200px] btn-sm max-sm:text-sm"
          style={{ backgroundColor: "#E19507" }}
        >
          {t("whatsapp_redirection")}
        </button>
      </div>
    );
  }
};

const PendingUI = (props: any) => {
  const { setOpen, t } = props;
  return (
    <div
      onClick={() => setOpen(true)}
      className="flex gap-4 justify-between items-center w-full max-md:flex-col max-sm:items-center "
    >
      <div>
        <b className="block">
          {t("sumsub_individual_application_created_title")}
        </b>
        {t("sumsub_individual_application_created")}
      </div>
      <button
        onClick={() => setOpen(true)}
        className="px-3 text-white verifyBtn btn btn-sm min-w-[200px]"
        style={{
          backgroundColor: "#A67A28",
        }}
      >
        <small>{t("complete_verification")}</small>
      </button>
    </div>
  );
};

const RejectedUI = (props: any) => {
  const { t } = props;
  return (
    <div className="flex gap-4 justify-between items-center w-full max-md:flex-col max-sm:items-center ">
      <div>
        <b className="block">{t("sumsub_reject_title")}</b>
        <div>{t("sumsub_reject_body")}</div>
      </div>
    </div>
  );
};

const IndividualBanners = (props: any) => {
  const { setOpen, t, redirectToWhatsapp } = props;
  const [individualStatus, setindividualStatus] = useState("");
  const {
    auth: { user },
  } = useAppSelector((state) => state.persistedReducer);

  useEffect(() => {
    try {
      if (user && user.on_boarding_status) {
        setindividualStatus(user.on_boarding_status);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [user]);

  const handleBannerBackgroundColor = (status: any) => {
    if (status == "pending") {
      return "#D69D36";
    } else if (status == "submitted") {
      return "#FDE6BD";
    } else {
      return "#C7F3C3";
    }
  };
  const handleBannerTextColor = (status: any) => {
    if (status == "pending") {
      return "white";
    } else {
      return "#504D4D";
    }
  };

  const backgroundColorForBanner = useMemo(() => {
    if (user?.on_boarding_status) {
      return handleBannerBackgroundColor(user?.on_boarding_status);
    } else {
      return "";
    }
  }, [user?.on_boarding_status]);
  const textColorForBanner = useMemo(() => {
    if (user?.on_boarding_status) {
      return handleBannerTextColor(user?.on_boarding_status);
    } else {
      return "";
    }
  }, [user?.on_boarding_status]);

  return (
    <div
      className="flex cursor-pointer text-base bannercontainer sm:flex-row max-sm:flex-col gap-3 items-center justify-between px-3 py-3 rounded shadow-sm"
      style={{
        backgroundColor: backgroundColorForBanner,
        color: textColorForBanner,
      }}
    >
      {individualStatus == "verified" && (
        <VerifiedUI t={t} redirectToWhatsapp={redirectToWhatsapp} />
      )}
      {individualStatus == "pending" && <PendingUI t={t} setOpen={setOpen} />}
      {individualStatus == "submitted" && (
        <SubmittedUI
          t={t}
          setOpen={setOpen}
          user={user}
          redirectToWhatsapp={redirectToWhatsapp}
        />
      )}
      {individualStatus == "rejected" && <RejectedUI t={t} />}
    </div>
  );
};

export default IndividualBanners;
