const BalanceCashinModal = (props: any) => {
  const { t, onClick, email, countryCode, phoneNumber } = props;
  return (
    <div className="row flex justify-center balanceModal">
      <div
        className={`flex max-md:flex-col relative items-center gap-3 h-min justify-center p-10 bg-white rounded-lg shadow-lg col-md-8 col-lg-6 col-10`}
      >
        {/* icon on bigger screen */}
        <div
          className={`hidden max-md:flex mobileicon bg-green-600`}
          style={{
            width: "50px",
            height: "50px",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            color: "#ffffff",
            fontSize: "100px",
          }}
        >
          <i
            style={{ fontSize: 20, margin: 24 }}
            className="text-4xl fa fa-check"
          ></i>
        </div>

        {/* messages */}
        <div className="flex md:ml-20 flex-col max-md:items-center gap-4">
          <>
            <div className="maininfo flex gap-3 items-center">
              <div
                className={`absolute left-10 flex max-md:hidden bg-green-600`}
                style={{
                  width: "50px",
                  height: "50px",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  color: "#ffffff",
                  fontSize: "100px",
                }}
              >
                <i
                  style={{ fontSize: 20, margin: 24 }}
                  className="text-4xl fa fa-check"
                ></i>
              </div>
              <p className="py-2  sm:text-xl text-left max-md:text-center font-bold">
                {t("balance_main_info")}
              </p>
            </div>

            <div className="md:text-base text-sm">
              {t("danapay_bank_transfer_main_info_1")}
              &nbsp;
              <b className="underline">{`+${countryCode} ${phoneNumber}`}</b>
              &nbsp;
              {t("danapay_bank_transfer_main_info_2")}
              &nbsp;
              <b className="underline">{email}</b>
              &nbsp;
              <br />
              <div className="mb-4">
                <p className="my-2 font-bold">
                  {t("danapay_bank_transfer_instruction")}
                </p>
                <ul className="list-disc">
                  <li
                    className=" underline text-blue-600 hover:text-blue-800 hover:cursor-pointer"
                    onClick={() =>
                      window.open(
                        "https://intercom.help/cartezi-technology-5ce69f6b7243/fr/articles/9215906-acceder-a-son-compte-danapay-quand-on-a-recu-des-fonds",
                        "_blank"
                      )
                    }
                  >
                    {t("danapay_bank_transfer_instruction_1")}
                  </li>
                  <li
                    className=" underline text-blue-600 hover:text-blue-800 hover:cursor-pointer"
                    onClick={() =>
                      window.open(
                        "https://intercom.help/cartezi-technology-5ce69f6b7243/fr/articles/9215915-retirer-ses-fonds-vers-un-compte-mobile",
                        "_blank"
                      )
                    }
                  >
                    {t("danapay_bank_transfer_instruction_2")}
                  </li>
                  <li
                    className=" underline text-blue-600 hover:text-blue-800 hover:cursor-pointer"
                    onClick={() =>
                      window.open(
                        "https://intercom.help/cartezi-technology-5ce69f6b7243/fr/articles/9215918-retirer-ses-fonds-vers-un-compte-bancaire",
                        "_blank"
                      )
                    }
                  >
                    {t("danapay_bank_transfer_instruction_3")}
                  </li>
                </ul>
              </div>
            </div>
          </>

          {/* buttons */}

          <button
            type="button"
            style={{
              backgroundColor: "rgb(3, 115, 117)",
              width: "200px",
              padding: "10px",
              borderRadius: 5,
            }}
            className="cursor-pointer md:text-base text-sm"
            onClick={onClick}
          >
            <small className="font-bold text-white">{t("backHome")}</small>
          </button>
        </div>
      </div>
    </div>
  );
};

export default BalanceCashinModal;
