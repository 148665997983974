import instance from "../../../apis/axiosInstance";

export const login = (obj: any) => {
  return instance.post("/auth/byEmail", JSON.stringify(obj));
};

export const checkIfUserExists = (obj: any) => {
  return instance.get(
    `/customers/exists?phone_number=${obj.phone_number}&country_code=${obj?.country_code}`
  );
};

export const signUp = (obj: any) => {
  return instance.post("/users/new", JSON.stringify(obj)); //needs changes
};

export const changePassword = (obj: any) => {
  return instance.post("/changePassword", JSON.stringify(obj));
};

export const resendEmail = (obj: any) => {
  return instance.get(`/email/resend?email=${obj.email}`);
};

export const addUser = (obj: any, userType: string) => {
  if (userType === "business") {
    return instance.post("/users/companyOwner/register", JSON.stringify(obj));
  } else {
    return instance.post("/users/register", JSON.stringify(obj));
  }
};

// remove company in case user comes back from creating a company account and follows individual flow for sign up

export const detachCompany = () => {
  return instance.delete("/users/companies");
};

export const discovery = (obj: any) => {
  return instance.post("/meta", JSON.stringify(obj));
};
export const discoveryDelete = () => {
  return instance.delete("/meta/user_origin");
};

export const addCompany = (obj: any) => {
  return instance.post("/companies", JSON.stringify(obj));
};

export const editCompany = (obj: any, company_id: string) => {
  return instance.put("/companies/" + company_id, JSON.stringify(obj));
};

export const completeCompanySubmission = () => {
  return instance.get("/company/complete");
};

export const addDocuments = (obj: any) => {
  return instance.post("/users/companyOwner/new", JSON.stringify(obj));
};

export const uploadFiles = (formData: any, company_id: any) => {
  return instance.post(`/companies/${company_id}/documents`, formData);
};

export const saveFCMToken = (fcmToken: any, token: string) => {
  return instance.put(
    "auth/fcmToken",
    JSON.stringify({
      fcm_token: fcmToken,
      platform: "web",
      application: "Danapay webpro app",
    })
  );
};

export const editContact = async (data: any, id: string) => {
  return instance.put(`/auth/contacts/${id}`, JSON.stringify(data));
};

export const requestPasswordResetLink = (data: any) => {
  return instance.post("/password/email", JSON.stringify(data));
};

export const passwordReset = (data: any) => {
  return instance.post("/password/reset", JSON.stringify(data));
};

export const getCountryData = (key: any) => {
  return fetch(`https://api.ipdata.co?api-key=${key}`).then((res) =>
    res.json()
  );
};

export const broadcastingAuth = (socketId: any, channel: any) => {
  return instance.post(`${process.env.REACT_APP_BASE_URL}/broadcasting/auth`, {
    socket_id: socketId,
    channel_name: channel.name,
  });
};

// export const discovery = (obj: any) => {
//   return instance.post("/meta", JSON.stringify(obj));
// };

//fetch generated access token for sumsub functionality
export const getKYCSumSubToken = async () => {
  return instance.post(`/userKYC/accessToken`);
};
export const getSumSubToken = async (data: any) => {
  return instance.post(`/userKYC/accessToken`, JSON.stringify(data));
};
export const getSumSubKYBToken = async () => {
  return instance.post(`/userKYB/accessToken`, {});
};

//adding and fetching users added contacts...
export const getFavorites = async () => {
  return instance.get(`transactions/favorites`);
};

export const addContact = async (data: any) => {
  //
  return instance.post("/auth/contacts", JSON.stringify(data));
};

// not used
export const fetchUserData = () => {
  return instance.get(`/auth/current`);
};

export const addUserBankAccount = async (data: any) => {
  return instance.post("/userBankAccounts", JSON.stringify(data));
};

// export const updateUserBankAccount = async (data: any, id: any) => {
//   return instance.put(`/userBankAccounts/${id}`, JSON.stringify(data));
// };

// export const deleteUserBankAccount = async (id: any) => {
//   return instance.delete(`/userBankAccounts/${id}`);
// };

// export const fetchUserBankAccounts = () => {
//   return instance.get(`/userBankAccounts/all`);
// };

export const addMMAccount = async (data: any) => {
  return instance.post("/userMMAccounts", JSON.stringify(data));
};

export const updateUserMMAccount = async (data: any, id: any) => {
  return instance.put(`/userMMAccounts/${id}`, JSON.stringify(data));
};

export const deleteUserMMAccount = async (id: any) => {
  return instance.delete(`/userMMAccounts/${id}`);
};

export const fetchMMAccounts = () => {
  return instance.get(`/userMMAccounts/all`);
};
export const fetchMMAccountsById = (id: string) => {
  return instance.get(`/userMMAccounts/all/${id}`);
};

// export const logout = () => {
//   return instance.post(`/auth/logout`, {});
// };

export const updateUserBankAccount = async (data: any, id: any) => {
  return instance.put(`/userBankAccounts/${id}`, JSON.stringify(data));
};

export const deleteUserBankAccount = async (id: any) => {
  return instance.delete(`/userBankAccounts/${id}`);
};

export const fetchUserBankAccounts = () => {
  return instance.get(`/userBankAccounts/all`);
};

export const logout = () => {
  return instance.post(`/auth/logout`, {});
};

// edit email
export const addRevenue = (revenue_data: any) => {
  return instance.put(`/users/updateProfile`, JSON.stringify(revenue_data));
};

// get otp on mobile
export const get_phone_otp = (data: any) => {
  return instance.post(`/phoneNumber/sendCode`, JSON.stringify(data));
};

//verify phone code
export const verify_phone_otp = (data: any) => {
  return instance.post(`/phoneNumber/verifyCode`, JSON.stringify(data));
};

// check if phone number already exists
export const doesPhoneExists = (data: any) => {
  return instance.post(`/phoneNumber/exists`, JSON.stringify(data));
};

//edit user profile
export const edit_user = (data: any) => {
  return instance.put(`/users/updateProfile`, JSON.stringify(data));
};

export const edit_active_user_profile = (data: any, user_id: string) => {
  return instance.put(`/users/${user_id}`, JSON.stringify(data));
};

export const verify_email_address = (data: any) => {
  return instance.post(`/phoneNumber/verifyCode`, JSON.stringify(data));
};

export const upload_transaction_docs = (data: any, id: string) => {
  return instance.post(`/transfers/${id}/documents`, data);
};

export const delete_uploaded_files = (
  transfer_id: string,
  document_id: string
) => {
  return instance.delete(`transfers/${transfer_id}/documents/${document_id}`);
};

export const download_file = (id: string) => {
  return instance.get(`/documents/${id}`, { responseType: "blob" });
};

// get job/activity listing
export const fetchJobListing = () => {
  return instance.get(`/individual-activities`);
};

export const sendOTPForLogin = (payload: any) => {
  return instance.post(`/mobile/phoneNumber/sendCode`, JSON.stringify(payload));
};

export const verifyOTPForLogin = (payload: any) => {
  return instance.post(`/phoneNumber/verifyCode`, JSON.stringify(payload));
};

export const loginWithPhone = (payload: any) => {
  return instance.post(`/auth/byMobileApp`, JSON.stringify(payload));
};

export const fetchCompanyRevenue = async (type = "company") => {
  const response = await instance.get(`revenue-levels?type=${type}`);
  return response.data;
};

export const checkUserExistsPhoneLogin = (obj: any) => {
  return instance.get(
    `/mobile/customers/exists?country_code=${obj.country_code}&phone_number=${obj.phoneNumber}`
  );
};

// save registration progress
export const saveRegistrationProgress = async (payload: any) => {
  return instance.post(`/progression`, JSON.stringify(payload));
};

export const deleteFavouriteUser = async (id: any) => {
  return instance.delete(`/auth/contacts/${id}`);
};

export const sendIp = async (obj: any) => {
  return instance.post(`/save_ip`, JSON.stringify(obj));
};

export const checkUserNameIfReal = async (payload: any) => {
  return instance.post(
    `/auth/contacts/check-username`,
    JSON.stringify(payload)
  );
};
