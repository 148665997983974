import { Backdrop } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const SumsubRejectModal = (props: any) => {
  const { onClick, open } = props;
  const { t } = useTranslation();
  return (
    <div>
      <Backdrop style={{ color: "#fff", zIndex: 99999 }} open={open}>
        <div className="lg:w-[566px] lg:h-[566px] md:w-[400px] md:h-[400px] max-sm:w-11/12 max-sm:h-min box-border rounded-lg bg-white text-black">
          <div className="p-4 lg:!px-20  md:!px-10 flex flex-col gap-4">
            <i
              className="fa fa-info-circle text-red-500 !text-5xl inline h-min mx-auto p-4"
              aria-hidden="true"
            ></i>
            <div className="font-bold text-center lg:text-2xl text-base">
              {t("sumsub_reject_title")}
            </div>
            <div className="my-3 text-sm text-center lg:text-xl text-sm">
              {t("sumsub_reject_body")}
            </div>
            <button
              className="w-[217px] mx-auto rounded-lg btn"
              style={{ backgroundColor: "rgb(3, 115, 117)" }}
              data-transfer-payment-details="paymentdetails"
              onClick={onClick}
            >
              <span
                className="text-white"
                data-transfer-payment-details="paymentdetails"
              >
                {t("sumsub_return_home")}
              </span>
            </button>
          </div>
        </div>
      </Backdrop>
    </div>
  );
};

export default SumsubRejectModal;
