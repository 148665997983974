import { Backdrop } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const NotificationModalOverlay = (props: any) => {
  const { open, onClick } = props;
  const { t } = useTranslation();

  return (
    <div>
      <Backdrop style={{ color: "#fff", zIndex: 99999 }} open={open}>
        <div className="p-3 max-sm:!w-5/6 w-1/2 2xl:w-1/4 xl:w-1/3 lg:w-1/2  rounded-lg bg-white text-black">
          <div className=" font-bold">
            {t("default_notification_update_title")}
          </div>
          <hr />
          <div className="my-3 text-sm">
            {t("default_notification_update_message")}
          </div>
          <button
            className="w-full rounded-lg btn"
            style={{ backgroundColor: "rgb(3, 115, 117)" }}
            data-transfer-payment-details="paymentdetails"
            onClick={onClick}
          >
            <span
              className="text-white"
              data-transfer-payment-details="paymentdetails"
            >
              {t("update")}
            </span>
          </button>
        </div>
      </Backdrop>
    </div>
  );
};

export default NotificationModalOverlay;
