import { useTranslation } from "react-i18next";

const InfoMessage = (props: any) => {
  const {
    message = "",
    underlineMessage,
    underlineMessageOnclick = () => {},
    extraClasses = null,
    customMessageComponent = null,
  } = props;
  const { t } = useTranslation();
  return (
    <div
      className={
        extraClasses
          ? `border mt-4 !border-[#2196F3] p-2 pr-4 rounded ${extraClasses}`
          : `border mt-4 !border-[#2196F3] p-2 pr-4 rounded`
      }
    >
      <div className="flex items-start">
        <div className="flex flex-0">
          <svg
            width={40}
            height={40}
            className="px-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              d="M10.0833 6.41667H11.9167V8.25H10.0833V6.41667ZM10.0833 10.0833H11.9167V15.5833H10.0833V10.0833ZM11 1.83333C5.94001 1.83333 1.83334 5.94 1.83334 11C1.83334 16.06 5.94001 20.1667 11 20.1667C16.06 20.1667 20.1667 16.06 20.1667 11C20.1667 5.94 16.06 1.83333 11 1.83333ZM11 18.3333C6.95751 18.3333 3.66668 15.0425 3.66668 11C3.66668 6.9575 6.95751 3.66667 11 3.66667C15.0425 3.66667 18.3333 6.9575 18.3333 11C18.3333 15.0425 15.0425 18.3333 11 18.3333Z"
              fill="#0288D1"
            />
          </svg>
        </div>
        <div className="text-sm">
          {customMessageComponent ? (
            customMessageComponent
          ) : (
            <>
              {message && t(message)}
              {underlineMessage && (
                <b onClick={underlineMessageOnclick}>{t(underlineMessage)}</b>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default InfoMessage;
