import React from "react";
import "./style.css";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setSelected } from "../../store/features/Transfer/TransferSlice";
import { useTranslation } from "react-i18next";
import "../../helpers/i18n";
import MobileList from "./MobileList";
import useResize from "../../hooks/useResize";
import WebList from "./WebList";

// import moment
interface Props {
  transactions: any;
}

const TransactionListItem: React.FC<Props> = ({ transactions }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const dimensions = useResize();
  const {
    auth: { user },
  } = useAppSelector((state) => state.persistedReducer);

  const OpenModal = (transaction: any) => {
    dispatch(setSelected(transaction));
  };

  return (
    <>
      {transactions?.map((transaction: any, index: number) => (
        <div key={index}>
          {dimensions[0] < 1070 ? (
            <MobileList
              transaction={transaction}
              t={t}
              OpenModal={OpenModal}
              user={user}
              key={index}
            />
          ) : (
            <WebList
              transaction={transaction}
              t={t}
              OpenModal={OpenModal}
              user={user}
              key={index}
            />
          )}
        </div>
      ))}
    </>
  );
};

export default TransactionListItem;
