import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import * as Sentry from "@sentry/browser";
import { useAppDispatch } from "../../store/hooks";
import {
  cancelTransfer,
  getTransferDetails,
} from "../../store/features/Transfer/Transfer";
import {
  addBeneficiary,
  openBulkTransferDialog,
  openDeposit,
  setBeneFound,
  setStep,
  setTransferDialogOpen,
} from "../../store/features/Transfer/TransferSlice";
import { toast } from "material-react-toastify";
import { CircularProgress } from "@material-ui/core";
import { clearLocalStorageValues } from "../../utilities/help";

const BridgePaymentStatus = () => {
  // instant sepa
  const [Status, setStatus] = useState("");
  const [cancelling, setCancelling] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  // withdraw method, and other data
  const withdrawMethod = window.localStorage.getItem("withdrawMethod");
  const mfiAccountNumber = window.localStorage.getItem("mfiAccountNumber");
  const iban = window.localStorage.getItem("iban");

  // transfer data
  const iniitalTransferData = {
    loading: true,
    data: {
      recipient_number: "",
      email: "",
      recipient_mobile_money_number: "",
    },
  };
  const [transferData, settransferData] = useState<any>(iniitalTransferData);

  useEffect(() => {
    try {
      const queryParameters = new URLSearchParams(window.location.search);
      const online_payment_id = localStorage.getItem("online_payment_id");

      // get transfer details
      settransferData((prev: any) => ({ ...prev, loading: true }));
      getTransferDetails(online_payment_id)
        .then((response: any) => {
          settransferData({
            loading: false,
            data: {
              recipient_number: `+${response?.destination_user?.country_code} ${response?.destination_user?.phone_number}`,
              email: response.destination_user.email,
              recipient_mobile_money_number:
                withdrawMethod == "Mobile Money"
                  ? `+${response?.destination_user?.country_code} ${response.data.phone_number}`
                  : "",
            },
          });
        })
        .catch((error: any) => {
          toast.warning(t(error?.data?.message));
          Sentry.captureException(error);
        })
        .finally(() =>
          settransferData((prev: any) => ({ ...prev, loading: false }))
        );

      const status: any = queryParameters.get("status");
      setStatus(status);
      if (status === "success") {
        goHome();
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, []);

  const goHome = () => {
    try {
      // clear localstorage values
      clearLocalStorageValues([
        "withdrawMethod",
        "full_phone_number",
        "mfiAccountNumber",
        "iban",
        "reciepentMobile",
        "email",
      ]);

      const payment_id = localStorage.getItem("payment_id");
      const payment_details = localStorage.getItem("payment_details");
      dispatch(setBeneFound(false));
      dispatch(addBeneficiary(null));
      dispatch(setStep(0));
      dispatch(setTransferDialogOpen(false));
      dispatch(openBulkTransferDialog("close"));
      dispatch(openDeposit(false));
      if (Status === "success") {
        if (payment_id && payment_details) {
          localStorage.removeItem("online_payment_id");
          localStorage.removeItem("payment_id");
          localStorage.removeItem("payment_details");
          const _payment_details = JSON.parse(payment_details);
          window.location?.replace(
            `${_payment_details.data.redirect_url}?payment_id=${payment_id}`
          );
        } else {
          history.push("/dashboard/home");
        }
      } else {
        cancelCurrentTransfer();
      }
    } catch (error: any) {}
  };

  const cancelCurrentTransfer = () => {
    setCancelling(true);
    const online_payment_id: any = localStorage.getItem("online_payment_id");
    try {
      cancelTransfer(online_payment_id)
        .then((res) => {
          localStorage.removeItem("online_payment_id");
          history.push("/dashboard/home");
          setCancelling(false);
        })
        .catch((error: any) => {
          // toast.error(JSON.stringify(error?.response?.data));
          setCancelling(false);
          toast.warning(t(error?.data?.message));
          Sentry.captureException(error);
        });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <div
        className="flex flex-row justify-between px-4 py-3 items-center"
        style={{ backgroundColor: "rgb(3, 115, 117)" }}
      >
        <p className="font-bold text-white">Payment Status</p>

        <a className="font-bold text-white cursor-pointer" onClick={goHome}>
          Close
        </a>
      </div>
      <div className="flex flex-1 md:my-20 my-10 justify-center row">
        {/* modal */}
        {transferData.loading && (
          <div className="flex flex-col items-center justify-center p-10 bg-white rounded-lg shadow-lg col-md-6">
            <CircularProgress />
          </div>
        )}
        {!transferData.loading && (
          <div
            className={`flex max-md:flex-col col-lg-6 col-10 relative items-center gap-3 h-min justify-center p-10 bg-white rounded-lg shadow-lg ${
              Status === "success" ? "col-md-8" : "max-w-[500px] col-md-6"
            }`}
          >
            {/* icon */}
            <div
              className={`hidden max-md:flex mobileicon ${
                Status === "success" ? "bg-green-600" : "bg-red-600"
              }`}
              style={{
                width: "50px",
                height: "50px",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                color: "#ffffff",
                fontSize: "100px",
              }}
            >
              {Status === "success" && (
                <i
                  style={{ fontSize: 20, margin: 24 }}
                  className="text-4xl fa fa-check"
                ></i>
              )}
              {Status !== "success" && (
                <i
                  style={{ fontSize: 20, margin: 0 }}
                  className="text-4xl fa fa-close"
                ></i>
              )}
            </div>

            {/* messages */}
            <div className="flex w-full md:ml-20 flex-col max-md:items-center gap-4">
              <>
                <div className="maininfo flex gap-3 items-center">
                  <div
                    className={`absolute left-10 flex max-md:hidden ${
                      Status === "success" ? "bg-green-600" : "bg-red-600"
                    }`}
                    style={{
                      width: "50px",
                      height: "50px",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "50%",
                      color: "#ffffff",
                      fontSize: "100px",
                    }}
                  >
                    {Status === "success" && (
                      <i
                        style={{ fontSize: 20, margin: 24 }}
                        className="text-4xl fa fa-check"
                      ></i>
                    )}
                    {Status !== "success" && (
                      <i
                        style={{ fontSize: 20, margin: 0 }}
                        className="text-4xl fa fa-close"
                      ></i>
                    )}
                  </div>
                  <p className="py-2  sm:text-xl text-left max-md:text-center font-bold">
                    {Status === "cancelled" && t("bridgeapi_cancelled")}
                    {Status === "success" && t("instant_sepa_main_info")}
                    {Status !== "success" &&
                      Status !== "cancelled" &&
                      t("bridgeapi_failed")}
                  </p>
                </div>

                <p className="py-2 md:text-base text-sm text-left max-md:text-center  text-gray-600">
                  {Status === "cancelled" && t("bridgeapi_cancelled_text")}
                  {Status !== "cancelled" &&
                    Status !== "success" &&
                    t("bridgeapi_failed_text")}
                  {withdrawMethod == "Mobile Money" && Status === "success" && (
                    <>
                      {t("instant_sepa_mobileMoney_info_1")}
                      &nbsp;
                      <b className="underline">
                        {transferData.data.recipient_mobile_money_number
                          ? transferData.data.recipient_mobile_money_number
                          : ""}
                      </b>
                      &nbsp;
                      {t("instant_sepa_mobileMoney_info_2")}
                    </>
                  )}
                  {withdrawMethod == "Bank Account" && Status === "success" && (
                    <>
                      {t("instant_sepa_bankAccount_info_1")}
                      &nbsp;
                      <b className="underline">{iban}</b>
                      &nbsp;
                      {t("instant_sepa_bankAccount_info_2")}
                    </>
                  )}
                  {withdrawMethod == "Microfinance" && Status === "success" && (
                    <>
                      {t("instant_sepa_microfinance_info_1")}
                      &nbsp;
                      <b className="underline">{mfiAccountNumber}</b>
                      &nbsp;
                      {t("instant_sepa_microfinance_info_2")}
                    </>
                  )}
                </p>

                {(withdrawMethod == null || withdrawMethod == undefined) &&
                  Status === "success" && (
                    <div className="md:text-base text-sm">
                      {t("danapay_bank_transfer_main_info_1")}
                      &nbsp;
                      <b className="underline">
                        {transferData.data.recipient_number}
                      </b>
                      &nbsp;
                      {t("danapay_bank_transfer_main_info_2")}
                      &nbsp;
                      <b className="underline">
                        {transferData.data.email ? transferData.data.email : ""}
                      </b>
                      &nbsp;
                      <br />
                      <div className="mb-4">
                        <p className="my-2 font-bold">
                          {t("danapay_bank_transfer_instruction")}
                        </p>
                        <ul className="list-disc">
                          <li
                            className=" underline text-blue-600 hover:text-blue-800 hover:cursor-pointer"
                            onClick={() =>
                              window.open(
                                "https://intercom.help/cartezi-technology-5ce69f6b7243/fr/articles/9215906-acceder-a-son-compte-danapay-quand-on-a-recu-des-fonds",
                                "_blank"
                              )
                            }
                          >
                            {t("danapay_bank_transfer_instruction_1")}
                          </li>
                          <li
                            className=" underline text-blue-600 hover:text-blue-800 hover:cursor-pointer"
                            onClick={() =>
                              window.open(
                                "https://intercom.help/cartezi-technology-5ce69f6b7243/fr/articles/9215915-retirer-ses-fonds-vers-un-compte-mobile",
                                "_blank"
                              )
                            }
                          >
                            {t("danapay_bank_transfer_instruction_2")}
                          </li>
                          <li
                            className=" underline text-blue-600 hover:text-blue-800 hover:cursor-pointer"
                            onClick={() =>
                              window.open(
                                "https://intercom.help/cartezi-technology-5ce69f6b7243/fr/articles/9215918-retirer-ses-fonds-vers-un-compte-bancaire",
                                "_blank"
                              )
                            }
                          >
                            {t("danapay_bank_transfer_instruction_3")}
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
              </>

              {/* buttons */}
              {cancelling ? (
                <button
                  type="button"
                  style={{
                    backgroundColor: "rgb(3, 115, 117)",
                    width: "200px",
                    padding: "10px",
                    borderRadius: 5,
                    marginTop: 20,
                  }}
                  disabled
                  className="md:text-base text-sm"
                >
                  <small className="font-bold text-white">
                    please wait ...
                  </small>
                </button>
              ) : (
                <button
                  type="button"
                  style={{
                    backgroundColor: "rgb(3, 115, 117)",
                    width: "200px",
                    padding: "10px",
                    borderRadius: 5,
                  }}
                  className="cursor-pointer md:text-base text-sm"
                  onClick={goHome}
                >
                  <small className="font-bold text-white">
                    {t("backHome")}
                  </small>
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BridgePaymentStatus;
