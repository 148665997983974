import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "material-react-toastify";
import * as Sentry from "@sentry/browser";
import {
  deleteUserBankAccount,
  deleteUserMMAccount,
  fetchMMAccounts,
  fetchUserBankAccounts,
} from "../../store/features/Auth/Auth";
import {
  setAccounts,
  setMFIAccounts,
  setMMAccounts,
} from "../../store/features/Auth/AuthSlice";
import "./style.css";
import { getCountryNameFromCode } from "../../utilities/help";
import {
  deleteMFIAccount,
  getUsersMFIAccounts,
} from "../../store/features/Transfer/Transfer";

type Props = {
  account: any;
  setEditModalOpen: any;
  type: string;
  isDanapay?: boolean;
  setActive?: any;
  setcarousalListing?: any;
};

const IndividualAccount = ({
  account,
  setEditModalOpen,
  type,
  isDanapay = false,
  setActive,
  setcarousalListing,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    transfer,
    auth: { selected_account, mobile_accounts },
  } = useAppSelector((state) => state.persistedReducer);

  const useStyles = makeStyles({
    title: {
      color: "#037375",
      fontWeight: 500,
    },
  });
  const classes = useStyles();

  const handleAccountDeletion = () => {
    if (type === "bank") {
      deleteUserBankAccount(account.id)
        .then(() => {
          toast.error(t("Deleted_successfully"));
          fetchUserBankAccounts()
            .then((response) => {
              dispatch(setAccounts(response.data));
              setcarousalListing(response.data);
            })
            .catch((error: any) => {
              Sentry.captureException(error);
              toast.error(t(error?.data?.message));
            });
        })
        .catch((error: any) => {
          Sentry.captureException(error);
          toast.error(t(error?.data?.message));
        });
    } else if (type === "mobile") {
      deleteUserMMAccount(account.id)
        .then(() => {
          toast.error(t("Deleted_successfully"));
          fetchMMAccounts()
            .then((response) => {
              dispatch(setMMAccounts(response.data));
              setcarousalListing(response.data);
            })
            .catch((error: any) => {
              Sentry.captureException(error);
              toast.error(t(error?.data?.message));
            });
        })
        .catch((error: any) => {
          Sentry.captureException(error);
          toast.error(t(error?.data?.message));
        });
    } else {
      deleteMFIAccount(account.id)
        .then(() => {
          toast.error(t("Deleted_successfully"));
          getUsersMFIAccounts().then((res) => {
            dispatch(setMFIAccounts(res.data));
            setcarousalListing(res.data);
          });
        })
        .catch((error: any) => {
          Sentry.captureException(error);
          toast.error(t(error?.data?.message));
        });
    }
  };

  const onDelete = () => {
    setActive(account);
    confirmAlert({
      title: t("confirm"),
      message: t("Are_you_want_to_delete"),
      overlayClassName: "overlay-custom-class-name",
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: t("yes"),
          onClick: () => {
            handleAccountDeletion();
          },
        },
        {
          label: t("no"),
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <div
      className="w-full p-3 text-gray-600 individualaccount cursor-pointer"
      style={{
        maxWidth: "650px",
      }}
    >
      <div
        className="flex pt-3 pb-8 pl-8 pr-8 mr-3 bg-white rounded-lg shadow-md flex-column"
        style={{
          borderWidth: selected_account?.id === account?.id ? 2 : 0,
          borderColor: "rgb(3, 115, 117)",
        }}
      >
        <div className="flex justify-end">
          {!isDanapay && (
            <>
              <button
                className="pr-8 "
                onClick={(e) => {
                  setActive(account);
                  setEditModalOpen();
                }}
              >
                <small className="font-bold text-blue-400 hover:text-blue-600">
                  {t("edit")}
                </small>
              </button>
              <button className="hover:text-red-500" onClick={onDelete}>
                <small className="font-bold text-red-400 hover:text-red-600">
                  {t("delete")}
                </small>
              </button>
            </>
          )}
        </div>
        <div
          className="flex w-full contentContainer"
          onClick={() => {
            setActive(account);
          }}
        >
          {type === "bank" && (
            <div className="w-2/3">
              <div>
                <small className={classes.title}>{t("title")}</small>
                <br />
                <small>{account?.title}</small>
              </div>

              <div className="pt-2">
                <small className={classes.title}>{t("Bank_Name")}</small>
                <br />
                <small>{account?.bank_name}</small>
              </div>
              <div>
                <small className={classes.title}>{t("owner_name")}</small>
                <br />
                <small>{account?.owner_name}</small>
              </div>
              <div className="pt-2">
                <small className={classes.title}>IBAN</small>
                <br />
                <small className="ibanNumber">{account?.iban}</small>
              </div>
            </div>
          )}
          {type === "mobile" && (
            <div className="w-2/3">
              <div className="pt-2">
                <small className={classes.title}>{t("Country")}</small>
                <br />
                <small>
                  {getCountryNameFromCode(
                    transfer,
                    account?.account?.country_code
                  )}
                </small>
              </div>
              <div className="pt-2">
                <small className={classes.title}>{t("title")}</small>
                <br />
                <small>{account?.account?.title}</small>
              </div>
              <div className="pt-2">
                <small className={classes.title}>{t("Operator")}</small>
                <br />
                <small>{account?.operator}</small>
              </div>
            </div>
          )}

          {type == "mfi" && (
            <div className="w-2/3">
              <div className="pt-2">
                <small className={classes.title}>{t("Title")}</small>
                <br />
                <small>{account?.title}</small>
              </div>
              <div className="pt-2">
                <small className={classes.title}>{t("Account Number")}</small>
                <br />
                <small>{account?.owner_id}</small>
              </div>
              <div className="pt-2">
                <small className={classes.title}>{t("Account Name")}</small>
                <br />
                <small>{account?.owner_name}</small>
              </div>
              <div className="pt-2">
                <small className={classes.title}>{t("MFI Name")}</small>
                <br />
                <small>{account?.mfi_name}</small>
              </div>
            </div>
          )}

          {/* right part */}
          {(type === "bank" || type == "mobile") && (
            <div className="w-1/3 mt-12 rightPart">
              <div>
                <small className={classes.title}>
                  {type === "bank" ? t("Country") : t("Owner_Name")}
                </small>
                <br />
                <small>
                  {type === "bank"
                    ? account?.country
                    : account?.account?.owner_name}
                </small>
              </div>
              <div className="pt-2">
                <small className={classes.title}>
                  {type === "bank" ? "BIC" : t("Phone_Number")}
                </small>
                <br />
                <small>
                  {type === "bank" ? account?.bic : account?.phone_number}
                </small>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IndividualAccount;
