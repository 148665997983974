import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import "../helpers/i18n";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { toast } from "material-react-toastify";
import * as Sentry from "@sentry/browser";
import {
  addBeneficiary,
  addTransferCountry,
  closeModal,
  getAllMobileOperatorWithCode,
  openBulkTransferDialog,
  openDeposit,
  openWithdraw,
  setBeneFound,
  setDepositData,
  setStep,
  setTransactionToRepeat,
  setTransferDialogOpen,
  setWithDrawData,
  updateBulkTData,
} from "../store/features/Transfer/TransferSlice";
import { CircularProgress } from "@material-ui/core";
import { buildString, getCountryByCode } from "../utilities/help";
import {
  cancelTransfer,
  getAllMobileOperatorWithCountryCode,
} from "../store/features/Transfer/Transfer";
import { useHistory } from "react-router";
import useResize from "../hooks/useResize";
import { extractError } from "../utility";
import { setSelectedBank } from "../store/features/Auth/AuthSlice";

interface Props {
  transfer: any;
  transcationsObject: any;
}

// const status_for_cancel = [
//   "pending",
//   // "payment_started",
//   // "funds_received",
//   // "deposit_in_progress",
//   // "deposit_started",
//   "deposit_failed",
//   "transfer_failed",
//   "withdraw_requested",
//   "payment_failed",
//   "withdraw_failed",
//   // "transfer_in_progress"
// ];
const status_for_cancel = ["pending", "error", "failed"];

const StatusButtons: React.FC<Props> = ({ transfer, transcationsObject }) => {
  const [w, h] = useResize();
  const { t } = useTranslation();
  const {
    auth: { user, bank_accounts, mobile_accounts, mfi_accounts },
    transfer: { isTransferDialogOpen, danaPayCountries, loggedInUserCountry },
  } = useAppSelector((state) => state.persistedReducer);
  const dispatch = useAppDispatch();
  const isSender = user?.id === transfer?.source_user?.id;

  const { status, payment_id, demanded_at } = transfer || {};
  const [asking, setAsking] = useState(false);
  const [canceling, setCancelling] = useState(false);
  const [allMobileOperator, setAllMobileOperator] = useState<any>([]);
  const history = useHistory();
  const directFeeSObject = transfer?.transfer?.data
    ? JSON.parse(transfer.transfer.data)
    : null;

  const repeatBulky = () => {
    try {
      const { reason, bulk_transfer_recipient_details } = transfer.transfer;
      if (bulk_transfer_recipient_details?.length > 0) {
        // const cashArray = eval(amounts_in_euros);
        // const selectedUsers: any = {};
        // destination_users.map((info: any, index: number) => {
        //   selectedUsers[info.id] = {
        //     id: info.id,
        //     full_name: info.full_name,
        //     enteredValue: cashArray[index],
        //   };
        // });
        dispatch(updateBulkTData({ users: bulk_transfer_recipient_details }));
        dispatch(
          updateBulkTData({
            country: bulk_transfer_recipient_details[0]?.country,
            reason,
            paymentMethod: transfer?.type || transfer?.transfer?.type,
          })
        );
        dispatch(openBulkTransferDialog("open"));
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const repeat = () => {
    try {
      const beneficiary =
        user?.id === transfer.source_user?.id
          ? transfer.destination_user
          : transfer.source_user?.id;
      const beneficiary_country = getCountryByCode(
        danaPayCountries,
        beneficiary?.country_code
      );
      const { operation_type } = transfer;

      const filterOperator: any = transcationsObject?.danaPayCountries.filter(
        (e: any) =>
          e.country_code?.toString() === beneficiary?.country_code.toString()
      );

      // get all payment methods
      setAsking(true);
      getAllMobileOperatorWithCountryCode(filterOperator[0]?.code)
        .then((res: any) => {
          setAllMobileOperator(res);
          dispatch(getAllMobileOperatorWithCode(res));

          // set widthdraw method on repeat
          if (operation_type === "withdraw") {
            // combine all accounts
            const allProviderList = bank_accounts
              .concat(mobile_accounts)
              .concat(mfi_accounts);

            if (
              transfer?.data !== undefined &&
              transfer?.data !== null &&
              allProviderList.length > 0
            ) {
              // find account according to id
              let findAccountToRepeat: any;
              let isCashoutMethodPresent =
                loggedInUserCountry?.cashout_methods.find((method: any) => {
                  return (
                    method?.cashout_method?.id ==
                      transfer?.data?.cashout_method_id ||
                    method?.cashout_method?.id == transfer?.cashout_method?.id
                  );
                });
              if (transfer?.type == "mobile_money") {
                findAccountToRepeat = allProviderList.find(
                  (itm: any) =>
                    itm?.id == transfer?.data?.mobile_money_account_id
                );
              } else if (
                transfer?.type == "manual_bank_transfer" ||
                "bank_transfer"
              ) {
                findAccountToRepeat = allProviderList.find(
                  (itm: any) => itm?.id == transfer?.data?.bank_account_id
                );
              } else if (transfer?.type == "mfi") {
                findAccountToRepeat = allProviderList.find(
                  (itm: any) => itm?.id == transfer?.data?.mfi_account_id
                );
              }

              if (
                findAccountToRepeat !== undefined &&
                isCashoutMethodPresent !== undefined
              ) {
                dispatch(setSelectedBank(findAccountToRepeat));
              }
            }
          }
        })
        .catch((error: any) => {
          toast.error(t(extractError(error)));
          Sentry.captureException(error);
        })
        .finally(() => {
          if (operation_type === "withdraw") {
            dispatch(openWithdraw(true));
          }
          setAsking(false);
        });

      // set hub2 operators according to reciepent country code
      // if (transfer?.destination_user?.country_code !== undefined) {
      //   getHub2BankAccounts(transfer?.destination_user?.country_code)
      //     .then((res: any) => {
      //       if (res?.data !== undefined && res?.data?.length > 0) {
      //         dispatch(setHub2Operators(res.data));
      //       } else {
      //         dispatch(setHub2Operators([]));
      //       }
      //     })
      //     .catch((error: any) => {
      //       Sentry.captureException(error);
      //     });
      // }

      const { reason, local_amount, amount_without_fee } = transfer.transfer;

      const repeat_payload = {
        reason,
        amountInCFA: +local_amount,
        amountInEuros:
          amount_without_fee === undefined
            ? transfer?.amount_without_fee
            : +amount_without_fee,
        paymentMethod:
          transfer?.type == "card"
            ? "bank card"
            : transfer?.type?.toLowerCase() == "instant_bank_transfer"
            ? "instant sepa"
            : transfer?.type?.toLowerCase()?.replace("_", " ")?.toLowerCase() ||
              transfer?.transfer?.type,
        fees:
          +transfer?.applied_fees?.value ||
          +transfer?.transfer?.applied_fees?.value,
        rate: transfer.exchange_rate_applied,
        operator:
          directFeeSObject?.operator !== undefined
            ? directFeeSObject?.operator
            : "",
        data: transfer?.data,
      };

      if (operation_type === "deposit") {
        dispatch(
          setDepositData({
            paymentMethod: transfer?.type || transfer?.transfer?.type,
            amountInEURos:
              amount_without_fee === undefined
                ? transfer?.amount_without_fee
                : +amount_without_fee,
            amountInCFA: +local_amount,
          })
        );
        dispatch(openDeposit(true));
        return;
      }
      if (operation_type === "withdraw") {
        // set withdraw method and send withdraw data from api to store
        dispatch(
          setWithDrawData({
            toWithDraw:
              amount_without_fee === undefined
                ? transfer?.amount_without_fee
                : +amount_without_fee,
            withDrawMethod: transfer?.type || transfer?.transfer?.type,
            data: transfer?.data,
            cashoutMethod: transfer?.cashout_method,
          })
        );
        dispatch(openWithdraw(true));

        return;
      }

      if (
        operation_type === "instant_transfer" ||
        directFeeSObject?.operator !== undefined
      ) {
        dispatch(addBeneficiary(beneficiary));
        dispatch(setBeneFound(true));
        dispatch(setStep(1));
        dispatch(setTransactionToRepeat(repeat_payload));
        dispatch(addTransferCountry(beneficiary_country));
        dispatch(setTransferDialogOpen(!isTransferDialogOpen));
        return;
      }

      if (
        operation_type === "bulk_transfer" ||
        operation_type === "direct_transfer"
      ) {
        repeatBulky();
        return;
      }
    } catch (error: any) {
      setAsking(false);
      Sentry.captureException(error);
    }
  };

  const cancelPayOut = () => {
    try {
      setCancelling(true);
      cancelTransfer(payment_id)
        .then((res: any) => {
          toast.info(t(`${buildString(res?.message.toLowerCase())}`));
          setTimeout(() => {
            setCancelling(false);
            dispatch(closeModal());
            history.push("/");
          }, 500);
        })
        .catch((error: any) => {
          setCancelling(false);
          Sentry.captureException(error);
          toast.error(t(error?.data?.message));
        });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const copy_pay = () => {
    try {
      const your_destination_user = transfer.destination_user?.id === user?.id;
      if (your_destination_user) {
        navigator.clipboard.writeText(
          `${window.location.origin}/payment/${payment_id}`
        );
        toast.info(t("link_copied"));
      } else {
        const link = `${window.location.origin}/payment/${payment_id}`;
        window.location?.replace(link);
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  // disable deposit for both
  // allow withdraw for company only
  const allowRepeat = useMemo(() => {
    if (transfer?.operation_type == "deposit") {
      return false;
    } else if (
      transfer?.operation_type == "withdraw" &&
      (user?.is_individual || user?.is_individual == 1)
    ) {
      return false;
    } else {
      return true;
    }
  }, [transfer, user]);

  return (
    <>
      <div className="btn-group flex items-center w-max">
        {asking && (
          <div
            className="flex justify-center items-center"
            style={{ width: "74.39px", height: "31px" }}
          >
            <CircularProgress color={"secondary"} size={20} />
          </div>
        )}
        {isSender && !asking && allowRepeat && (
          <>
            <button
              onClick={repeat}
              className="btn btn-sm "
              style={{
                backgroundColor: "#037375",
              }}
            >
              <i
                className="m-0 text-white fa fa-repeat"
                style={{ fontSize: 10 }}
              />
              <small
                className="ml-2 text-xs font-bold text-white"
                style={{ fontSize: 11 }}
              >
                {t("Repeat")}
              </small>
            </button>

            {status_for_cancel.includes(transfer?.transfer?.status) && (
              <>
                {canceling ? (
                  <div
                    className="flex justify-center items-center"
                    style={{ width: "35.44px", height: "31px" }}
                  >
                    <CircularProgress size={10} />
                  </div>
                ) : (
                  <button
                    onClick={cancelPayOut}
                    className="btn btn-danger btn-sm deleteIconUpload"
                  >
                    <i
                      className="m-0 text-white fa fa-ban px-1"
                      style={{
                        fontSize: 12,
                      }}
                    />
                  </button>
                )}
              </>
            )}
          </>
        )}

        {status === "payment_requested" && (
          <button
            onClick={copy_pay}
            className="flex flex-row items-center flex-1 btn btn-sm btn-block"
            style={{
              backgroundColor: "#037375",
            }}
          >
            {isSender ? (
              <>
                <i
                  className="m-0 text-white fa fa-clone"
                  style={{ fontSize: 10 }}
                />
                <small
                  className="ml-2 text-xs font-bold text-white"
                  style={{ fontSize: 11 }}
                >
                  {t("Copy")}
                </small>
              </>
            ) : (
              <>
                <i
                  className="m-0 fa fa-credit-card-alt"
                  style={{ fontSize: 10 }}
                />
                <small
                  className="ml-2 text-xs font-bold"
                  style={{ fontSize: 11 }}
                >
                  {t("Pay")}
                </small>
              </>
            )}
          </button>
        )}
      </div>
    </>
  );
};

export default StatusButtons;
