import React from "react";
import { useAppSelector } from "../store/hooks";
import { countries_in_french } from "../utilities/french_countries";

const Country_comp: React.FC<any> = ({ eng_country_name, size = 12 }) => {
  const lang = useAppSelector((state) => state.persistedReducer.auth.lang);
  return (
    <>
      {lang !== "fr" ? (
        <small>{eng_country_name}</small>
      ) : (
        <>
          {eng_country_name &&
          countries_in_french.hasOwnProperty(eng_country_name.toLowerCase()) ? (
            <small style={{ fontSize: size }}>
              {countries_in_french[eng_country_name.toLowerCase()]}
            </small>
          ) : (
            <small style={{ fontSize: size }}>{eng_country_name}</small>
          )}
        </>
      )}
    </>
  );
};

export default Country_comp;
