import { Dialog } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const BulkUploadSuccessModal = (props: any) => {
  const {
    openSucessDialog,
    handleClose,
    dataSucessCSV,
    setopenSucessDialog,
    setshowTableCSV,
    setFileCSV,
  } = props;
  const { t } = useTranslation();
  return (
    <Dialog open={openSucessDialog} onClose={() => handleClose}>
      <div
        className=" flex-col flex p-4 col-md-12 justify-center success-box-model"
        style={{ alignItems: "center" }}
      >
        <div className="flex items-center justify-center mb-6">
          <p className="font-bold text-center text-2xl">
            {t("upload_beneficiaries_details")}
          </p>
        </div>
        <img
          src="/images/sucess-check-circle.png"
          alt="sucess-check-circle.png"
          width={90}
        />
        <p className="font-bold text-center my-2">
          {`${
            dataSucessCSV?.data === undefined
              ? dataSucessCSV.length
              : dataSucessCSV?.data?.length
          }`}{" "}
          {t("Upload_Rows")}
        </p>
        <button
          onClick={() => {
            setopenSucessDialog(false);
            setFileCSV(dataSucessCSV);
            setshowTableCSV(true);
          }}
          className="ml-2 mt-4 w-2/4 px-10 rounded-md btn py-2.5 w-100"
          style={{ backgroundColor: "#037375" }}
        >
          <small className="mx-2 font-bold text-white">{t("continue")}</small>
        </button>
      </div>
    </Dialog>
  );
};

export default BulkUploadSuccessModal;
