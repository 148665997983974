import React from "react";
import "./style.css";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import {
  addBeneficiary,
  addTransferCountry,
  addTransferResult,
  openBulkTransferDialog,
  openDeposit,
  openWithdraw,
  setBeneFound,
  setExternalApiPaymentId,
  setTLError,
  setTransactionToRepeat,
  setTransferDialogOpen,
  setTransferFromType,
  updateBulkTData,
  updateEscrowState,
} from "../../store/features/Transfer/TransferSlice";
import { useTranslation } from "react-i18next";
import "../../helpers/i18n";
import CashLayout from "../CashLayout";
import BulkPayment from "../BulkPayment";
import PayByLink from "../PayByLink";
import NotActiveComp from "../NotActiveComp";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MiniProfile: React.FC<any> = ({}) => {
  const {
    auth: { userBalance },
    transfer,
  } = useAppSelector((state) => state.persistedReducer);
  const [payByLink, openPayByLink] = React.useState(false);
  const [size, setSize] = React.useState([0, 0]);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const startTransfer = (type: any) => {
    dispatch(setTransferFromType("internal"));
    dispatch(setExternalApiPaymentId(null));
    dispatch(addBeneficiary(null));
    dispatch(setBeneFound(false));
    dispatch(setTLError(null));
    dispatch(addTransferCountry(null));
    dispatch(addTransferResult(null));
    dispatch(setTransactionToRepeat(null));
    dispatch(setTransferDialogOpen(!transfer.isTransferDialogOpen));
    if (type === "escrow") {
      dispatch(updateEscrowState(true));
    } else {
      dispatch(updateEscrowState(false));
    }
  };

  const startBulkPayments = () => {
    dispatch(updateBulkTData({ users: {} }));
    dispatch(updateBulkTData({ country: "france", reason: "" }));
    dispatch(openBulkTransferDialog("open"));
  };

  React.useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <>
      <section className="rounded-lg profile">
        <small className="font-bold text-gray-600">
          {t("Your_current_balance")}
        </small>

        <div
          className={
            size[0] > 700
              ? `balanceBox mr-10 flex-row w-full`
              : `flex justify-center items-center text-center flex-col w-full`
          }
        >
          <h1 className="text-2xl mb-4 font-bold text-gray-600">
            <CashLayout cash={userBalance} size="text-2xl" />
          </h1>
          <NotActiveComp
            showLoader={true}
            t={t}
            setWithDrawOpen={() => dispatch(openWithdraw(true))}
            startBulkPayments={() => {
              startBulkPayments();
            }}
            startTransfer={startTransfer}
            size={size}
            openPayByLink={openPayByLink}
            startDeposit={() => dispatch(openDeposit(true))}
          />
        </div>

        <Dialog
          fullScreen
          open={transfer.isBulkTransferDialogOpen}
          onClose={startBulkPayments}
          TransitionComponent={Transition}
        >
          <BulkPayment setBulkPaymentModal={startBulkPayments} t={t} />
        </Dialog>

        <Dialog
          fullScreen
          open={payByLink}
          onClose={() => openPayByLink(false)}
        >
          <PayByLink closePayByLInk={() => openPayByLink(false)} t={t} />
        </Dialog>
      </section>
    </>
  );
};

export default MiniProfile;
