import * as Sentry from "@sentry/browser";

const handlePaymentAndLastPageHeapEvents = (activeStep, hideWithdrawModal, widthdrawMethod, settransferFailData, paymentMethod) => {
    try {
        if (
            activeStep == 1 &&
            hideWithdrawModal == false &&
            widthdrawMethod !== undefined &&
            widthdrawMethod !== null &&
            widthdrawMethod !== ""
        ) {
            settransferFailData((prev) => {
                return { ...prev, "withdraw method": widthdrawMethod };
            });
        } else {
            settransferFailData((prev) => {
                let newState = prev;
                delete newState["withdraw method"];
                return { ...newState };
            });
        }
        if (
            activeStep == 1 &&
            paymentMethod !== undefined &&
            paymentMethod !== null &&
            paymentMethod !== ""
        ) {
            settransferFailData((prev) => {
                return {
                    ...prev,
                    "payment method": paymentMethod,
                    "last page viewed": "Amount confirmation page",
                };
            });
        } else {
            settransferFailData((prev) => {
                let newState = prev;
                delete newState["payment method"];
                delete newState["last page viewed"];
                return {
                    ...newState,
                    "last page viewed": "Beneficiary confirmation page",
                };
            });
        }
    } catch (error) {
        Sentry.captureException(error)
    }
}

const handleHeapRecipientData = (transfer, settransferFailData) => {
    try {
        if (
            transfer?.beneficiary !== undefined &&
            transfer?.beneficiary !== null &&
            transfer?.beneficiary !== ""
        ) {
            settransferFailData((prev) => {
                return {
                    ...prev,
                    "recipient first name": transfer?.beneficiary?.first_name,
                    "recipient last name": transfer?.beneficiary?.last_name,
                    "recipient email": transfer?.beneficiary?.last_name,
                    "recipient phone number": transfer?.beneficiary?.full_phone_number,
                };
            });
        } else {
            settransferFailData((prev) => {
                let newState = prev;
                delete newState["recipient first name"];
                delete newState["recipient phone number"];
                delete newState["recipient last name"];
                delete newState["recipient email"];
                return { ...newState };
            });
        }
    } catch (error) {
        Sentry.captureException(error)
    }
}

const handleWithdrawForHeap = (hideWithdrawModal, widthdrawMethod, settransferFailData) => {
    try {
        if (
            hideWithdrawModal == false &&
            widthdrawMethod !== undefined &&
            widthdrawMethod !== null &&
            widthdrawMethod !== ""
        ) {
            settransferFailData((prev) => {
                return { ...prev, "withdraw method": widthdrawMethod };
            });
        } else {
            settransferFailData((prev) => {
                let newState = prev;
                delete newState["withdraw method"];
                return { ...newState };
            });
        }
    } catch (error) {
        Sentry.captureException(error)
    }
}
const handleAmoutForHeap = (amountInEuros, settransferFailData) => {
    try {
        if (
            amountInEuros !== "" &&
            amountInEuros !== 0 &&
            amountInEuros !== null &&
            amountInEuros !== undefined
        ) {
            settransferFailData((prev) => {
                return { ...prev, amount: amountInEuros };
            });
        } else {
            settransferFailData((prev) => {
                const { amount, ...rest } = prev;
                return { ...rest };
            });
        }
    } catch (error) {
        Sentry.captureException(error)
    }
}



export {
    handlePaymentAndLastPageHeapEvents,
    handleHeapRecipientData,
    handleWithdrawForHeap,
    handleAmoutForHeap
}