import { Alert } from "@material-ui/lab";
import InputField from "../forms/InputField";
import * as Sentry from "@sentry/browser";

const CashInputComponent = ({ data, next }: any) => {
  const {
    t,
    errorGot,
    setLimitError,
    transfer,
    setAmountInEURos,
    RATE,
    setAmountInCfa,
    amountInEURos,
    amountInCfa,
    limitError,
    processingPayment,
  } = data;

  return (
    <>
      <div className="mb-1 outerfieldBox">
        <InputField
          name="Sent amount (EUR)"
          placeholder={t("Sent_Amount_Eur_Only")}
          label={t("Sent_amount_EUR")}
          handleChange={(value: any) => {
            try {
              setLimitError(null);
              if (
                parseFloat(value.target.value) >
                transfer.transferCountry?.sending_max_amount
              ) {
                setLimitError(
                  `${t("You_can_send_between")} ${
                    transfer.transferCountry?.sending_min_amount
                  } and ${transfer.transferCountry?.sending_max_amount}`
                );
              }
              if (
                parseFloat(value.target.value) <
                transfer.transferCountry?.sending_min_amount
              ) {
                setLimitError(
                  `${t("You_can_send_between")}  ${
                    transfer.transferCountry?.sending_min_amount
                  } and ${transfer.transferCountry?.sending_max_amount}`
                );
              }
              setAmountInEURos(value.target.value);
              if (value.target.value) {
                const totalAmount = parseFloat(value.target.value) * RATE;
                setAmountInCfa(totalAmount);
              } else {
                setAmountInCfa(0);
              }
            } catch (error: any) {
              Sentry.captureException(error);
            }
          }}
          value={amountInEURos}
          type="number"
        />
      </div>
      {errorGot && <small className="text-xs text-red-500">{errorGot}</small>}
      <div>{limitError && <Alert severity="error">{limitError}</Alert>}</div>
      <br />
      <div className="mb-1 outerfieldBox">
        <InputField
          name="Amount received (Cfa)"
          placeholder={t("Sent_Amount_Cfa_Only")}
          label={t("Sent_amount_Cfa")}
          handleChange={(value: any) => {
            try {
              setAmountInCfa(value.target.value);
              if (value.target.value) {
                const totalAmount = parseFloat(value.target.value) / RATE;
                setAmountInEURos(totalAmount);
              } else {
                setAmountInEURos(0);
              }
            } catch (error: any) {
              Sentry.captureException(error);
            }
          }}
          value={amountInCfa}
          type="number"
        />
      </div>
      <button
        className="mt-3 btn"
        style={{ backgroundColor: "rgb(3, 115, 117)" }}
        onClick={() => {
          if (amountInEURos) {
            next();
          }
        }}
      >
        <small className="px-10 font-bold text-white">
          {processingPayment ? t("processing") : t("continue")}
        </small>
      </button>
    </>
  );
};

export default CashInputComponent;
