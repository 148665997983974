import "react-app-polyfill/stable";
import Navigation from "./Navigation";
import { Provider } from "react-redux";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { store, persistor } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./components/ErrorFallback";
import { IntercomProvider } from "react-use-intercom";
import { ConfigProvider } from "react-avatar";
import CustomerIOTracker from "./components/CustomerIOTracker";
import { useEffect } from "react";
const INTERCOM_APP_ID = "xuz76by7";

const theme = createTheme({
  palette: {
    primary: {
      main: "#017375",
    },
  },
  typography: {
    fontFamily: "Poppins",
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
  shape: {},
});

const App = () => {
  const resetAttemps = () => {
    localStorage.removeItem("depositattempts");
    localStorage.removeItem("maketransferattempts");
  };

  useEffect(() => {
    window.addEventListener("beforeunload", resetAttemps);

    return () => {
      window.removeEventListener("beforeunload", resetAttemps);
    };
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <PersistGate loading={null} persistor={persistor}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
              <ConfigProvider
                colors={["#da627d", "#55a630", "#2ec4b6", "#dd614a", "#724cf9"]}
              >
                <Navigation />
              </ConfigProvider>
            </IntercomProvider>
          </ErrorBoundary>
        </PersistGate>
      </ThemeProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
      <CustomerIOTracker />
    </Provider>
  );
};

export default App;
