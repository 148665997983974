const ScrollableModal = (props: any) => {
  return (
    <div className="flex h-screen w-full items-center justify-center ">
      <div className=" m-auto max-h-full min-w-full overflow-auto">
        <div className="flex justify-center items-center h-full">
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default ScrollableModal;
