import * as Sentry from "@sentry/browser";
const DepositBankSelector = (props: any) => {
  const {
    selectedBankState,
    setselectedBankState,
    setSelectedBank,
    dana_pay_bank_accounts,
    t,
    setNextStep,
    setActiveStep,
    full_name,
    full_phone_number,
    email,
    country,
    selectedBank,
  } = props;
  return (
    <div className="container">
      <div className="row flex justify-center">
        <div className="p-4 max-sm:mt-20 rounded-lg shadow-lg col-md-6">
          <div className="my-4 mb-4 text-center">
            <h3 className="text-2xl font-bold">{t("payment_instruction")}</h3>
            <p>{t("pi_text")}</p>
          </div>
          <div className="bacnkCheckBoxWrapper p-2 flex flex-col gap-2">
            {selectedBankState &&
              selectedBankState.length > 0 &&
              selectedBankState.map((bank: any, index: number) => {
                return (
                  <div
                    className="wrapperBankCheckBox innerBankBox m-0"
                    key={"depositbankselector" + index}
                  >
                    <div className="wrapperBankCheckBoxwrapper">
                      <input
                        type="checkbox"
                        className="default:ring-2"
                        onChange={(e: any) => {
                          try {
                            setSelectedBank(dana_pay_bank_accounts[index]);
                            setselectedBankState((prev: any) =>
                              prev.map((itm: any, ind: any) => {
                                if (ind == index) {
                                  return { ...itm, checked: true };
                                } else {
                                  return { ...itm, checked: false };
                                }
                              })
                            );
                          } catch (error: any) {
                            Sentry.captureException(error);
                          }
                        }}
                        name={bank.bank_name}
                        checked={bank.checked}
                      />
                      <span className="checkmark"></span>
                    </div>
                    <label>{bank.bank_name}</label>
                  </div>
                );
              })}
          </div>

          <button
            data-deposit-initiation="initiation"
            onClick={() => {
              setNextStep("bank");
              setActiveStep(3);
              window.heap.track("Deposit payment successfully initiated", {
                name: full_name,
                email,
                phone: full_phone_number,
                country,
              });
            }}
            className="w-full rounded-md btn"
            style={{ backgroundColor: "rgb(3, 115, 117)" }}
            disabled={selectedBank === null}
          >
            <small
              className="mx-10 font-bold text-white"
              data-deposit-initiation="initiation"
            >
              {t("continue")}
            </small>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DepositBankSelector;
