import { useTranslation } from "react-i18next";

const CheckBoxListingComponent = (props: any) => {
  const {
    isSingle = false,
    isMulti = true,
    list = [],
    onChange = () => {},
  } = props;
  const { t } = useTranslation();
  return (
    <>
      <div className={`bacnkCheckBoxWrapper p-2 flex flex-col gap-2 relative`}>
        {list &&
          list.length > 0 &&
          list.map((listItem: any, listItemIndex: any) => {
            return (
              <div
                key={listItem.name + listItemIndex}
                className="m-0 wrapperBankCheckBox innerBankBox"
              >
                <div className="wrapperBankCheckBoxwrapper">
                  <input
                    type="checkbox"
                    className="default:ring-2"
                    name={listItem.name}
                    onChange={(e) => {
                      onChange(e, listItem.id);
                    }}
                    checked={listItem.active}
                  />
                  <span className="checkmark"></span>
                </div>
                <label className="md:text-md max-sm:!text-sm">
                  {t(listItem?.name)}
                </label>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default CheckBoxListingComponent;
