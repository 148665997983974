import moment from "moment";
import React from "react";
import { useAppDispatch } from "../store/hooks";
import { buildString } from "../utilities/help";
interface Props {
  body: any;
  t: any;
  transaction?: any;
  user?: any;
}

const StatusComp: React.FC<Props> = ({
  body,
  t,
  transaction = null,
  user = null,
}) => {
  return (
    <>
      {body.map((status: any, index: number) => {
        return (
          <div
            className="flex flex-row py-1 border-white border-1"
            key={index}
          >
            <div style={{ width: "40%", textAlign: "left" }}>
              <small>
                {moment(status?.datetime).format("DD/MM/YYYY, H:mm:ss")}
              </small>
            </div>
            <div
              className="px-2 border-l-4 border-blue-400"
              style={{ borderLeftColor: "rgb(3, 115, 117)", width: "70%" }}
            >
              <small>
                {buildString(status?.message) === "receiver_requested_a_payment"
                  ? transaction?.destination_user.id === user?.id
                    ? t("You_requested_for_pay")
                    : t(buildString(status?.message))
                  : t(buildString(status?.message))}
              </small>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default StatusComp;
