import { Modal } from "@material-ui/core";
import ReactDatePicker from "react-datepicker";
import CountryPicker from "../CountryPicker";
import NormalSelectField from "../forms/NormalSelectField";
import * as Sentry from "@sentry/browser";

const HistoryFilterModal = (props: any) => {
  const {
    open,
    onClose,
    t,
    fromDate,
    toDate,
    onDateChange,
    setFilters,
    filters,
    apply_filters,
    setSelectedCountryReset,
    data,
    favorites,
    setValue,
    getAllUserTransfer,
    perPage,
  } = props;
  return (
    <Modal open={open} onClose={onClose}>
      <div className="m-auto flex overflow-scroll h-screen historyfiltermodal justify-center row">
        <div className="my-20 col-md-6">
          <div className="p-3 py-2 bg-white rounded-md">
            <div className="flex flex-row items-center justify-between p-2 border-b">
              <h2 className="font-bold">{t("filter")}</h2>
              <button
                onClick={onClose}
                style={{ height: 40, width: 40, borderRadius: 20 }}
                className="flex items-center justify-center shadow-lg"
              >
                <i className="m-0 fa fa-close"></i>
              </button>
            </div>

            <div className="my-2 row">
              <div className="col-md-6">
                <div className="w-full mb-1">
                  <label className="mb-1 text-gray-600">{t("from")}</label>
                  <div className="w-full px-2 py-2 text-sm bg-white rounded-2 gray-datepicker">
                    <ReactDatePicker
                      selected={fromDate}
                      onChange={(e: any) => onDateChange("from", e)}
                      maxDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="DD/MM/YYYY"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="w-full mb-1">
                  <label className="mb-1 text-gray-600">{t("to")}</label>
                  <div className="w-full px-2 py-2 text-sm bg-white rounded-2 gray-datepicker">
                    <ReactDatePicker
                      selected={toDate}
                      onChange={(e: any) => onDateChange("to", e)}
                      minDate={new Date(fromDate)}
                      maxDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="DD/MM/YYYY"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <CountryPicker
                setCountry={(text: any) => {
                  try {
                    setFilters((prev: any) => {
                      return { ...prev, country: text.name };
                    });
                    // setPreComChannel(
                    //   getPreferredComChannel(
                    //     transfer.danaPayCountries,
                    //     text.mobileCode
                    //   )
                    // );
                  } catch (error: any) {
                    Sentry.captureException(error);
                  }
                }}
                cash_Out_Method_Name={""}
                country={filters?.country}
                onChange={(text: string) => {
                  setFilters((prev: any) => {
                    return { ...prev, country: text };
                  });
                }}
                value={filters?.country}
                country_selected={true}
                setSelectedCountry={(e: any) => setSelectedCountryReset(e)}
              />
            </div>

            <div className="my-2">
              <NormalSelectField
                data={
                  data &&
                  Array.from(new Set(data.names)).map((val) => {
                    return { type: val };
                  })
                }
                setData={(text: any) => {
                  try {
                    if (text !== "all") {
                      const user_data = favorites.find(
                        (fav: any) => fav.favorite.full_name === text
                      );
                      setFilters((prev: any) => {
                        return {
                          ...prev,
                          contact: text,
                          contact_id: user_data?.favorite?.id,
                        };
                      });
                    } else {
                      setFilters((prev: any) => {
                        return {
                          ...prev,
                          contact: "",
                          contact_id: null,
                        };
                      });
                    }
                  } catch (error: any) {
                    Sentry.captureException(error);
                  }
                }}
                value={filters.contact}
                width
                label="Contact_Name"
                canShowAll
                classApply={false}
              />
            </div>

            <div className="my-2">
              <NormalSelectField
                data={Array.from(new Set(data.pay_methods)).map((val) => {
                  return { type: val };
                })}
                setData={(text: any) => {
                  setFilters((prev: any) => {
                    return { ...prev, type: text };
                  });
                }}
                value={filters.type}
                width
                label="Payment_Mode"
                canShowAll
                classApply={false}
              />
            </div>

            <div className="mx-1 row">
              <div className="my-2 col-md-6">
                <button
                  onClick={() => {
                    setFilters({
                      from: null,
                      to: null,
                      contact: null,
                      country: null,
                      type: null,
                      search: null,
                      contact_id: null,
                      transferType: null,
                    });
                    setValue("All");
                    getAllUserTransfer(1, perPage, "reset");
                    onClose();
                  }}
                  style={{
                    backgroundColor: "black",
                    color: "#fff",
                  }}
                  className="flex items-center justify-center w-full p-2 font-bold rounded-md shadow-lg btn btn-sm"
                >
                  {t("reset")}
                </button>
              </div>
              <div className="my-2 col-md-6">
                <button
                  onClick={() => {
                    onClose();
                    apply_filters(filters);
                  }}
                  style={{
                    backgroundColor: "rgb(3, 115, 117)",
                    color: "#fff",
                  }}
                  className="flex items-center justify-center w-full p-2 font-bold rounded-md shadow-lg btn btn-sm"
                >
                  {t("filter")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default HistoryFilterModal;
