import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import Button from "@material-ui/core/Button";
import bg from "../../images/bgImg.jpeg";
import * as Sentry from "@sentry/browser";
import InputField from "../../components/forms/InputField";
import { useAppSelector } from "../../store/hooks";
import { resendEmail } from "../../store/features/Auth/Auth";
import LangSwitch from "../../components/LangSwitch";
import { useTranslation } from "react-i18next";
import "../../helpers/i18n";
import { toast } from "material-react-toastify";

const style = {
  form: {
    height: "100vh",
    backgroundColor: "#fff",
  },
  image: {
    backgroundImage: "url(" + bg + ")",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
};

const useStyles = makeStyles({
  root: {
    width: "100wv)",
    height: "100vh",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
  },
  form__header: {
    backgroundColor: "#fff",
    height: 80,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 20,
    paddingRight: 20,
  },
  form__body: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "calc(100vh - 140px)",
  },
  form__footer: {
    padding: 10,
    height: 60,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  footSPan: {
    fontSize: 13,
    marginRight: 20,
    fontWeight: "bold",
    color: "#666",
  },
  logo: {
    height: 30,
  },
  form__header_brand: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  form__header_links: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    flex: 1,
  },
  brandName: {
    fontWeight: "bold",
    fontSize: 20,
    marginLeft: 5,
  },
  btn: {
    textTransform: "capitalize",
    marginRight: 15,
    paddingLeft: 20,
    paddingRight: 20,
  },

  formBox: {
    marginBottom: 40,
    marginTop: 10,
  },
  formField: {
    width: "70%",
  },
});

const LinkStale = () => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.persistedReducer.auth.user);
  const [email, setEmail] = useState(user?.email);
  const classes = useStyles();
  const history = useHistory();

  const HandleBtnClick = () => {
    alert(email);
    resendEmail({ email })
      .then(() => {
        history.push("verify");
      })
      .catch((error: any) => {
        toast.warning(t(error?.data?.message));
        Sentry.captureException(error);
      });
  };

  return (
    <div className="w-screen h-screen m-0 overflow-y-scroll row">
      <div className="p-0 col-md-5">
        <div className="flex flex-row justify-between p-4 shadow-md">
          <img src="images/logofull.png" style={{ height: 30 }} alt="" />
          <div>
            <Link to="/login" className="px-8 py-2 mx-2 rounded">
              {t("login")}
            </Link>
            <LangSwitch />
          </div>
        </div>
        <div
          style={{ minHeight: 800 }}
          className="flex items-center justify-center"
        >
          <div
            className="p-4 m-auto rounded-lg shadow-lg"
            style={{ width: "80%" }}
          >
            <br />
            <h2 className="text-2xl font-bold">{t("link_expired")}</h2>
            <p className="mb-8">{t("askNewLink")}</p>
            <form className={classes.formBox}>
              <InputField
                extraClasses="mb-3 customInputStyle"
                placeholder={t("EmailAddress")}
                name={t("EmailAddress")}
                handleChange={(value: any) => setEmail(value)}
                value={email}
                disabled={true}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={HandleBtnClick}
              >
                <span className="text-white capitalize ">
                  {t("Send_New_Link")}
                </span>
              </Button>
            </form>
          </div>
        </div>
      </div>
      <div className="p-0 col-md-7" style={{ ...style.image }}></div>
    </div>
  );
};

export default LinkStale;
