import { CircularProgress } from "@material-ui/core";
import CashInMethodDescriptions from "./CashInMethodDescriptions";

const PaymentMethodComponent = ({ data, prev }: any) => {
  const {
    t,
    isUserRestricted,
    removeBalance,
    checkingForRestriction,
    paymentMethod,
    processingPayment,
    feesCalculated,
    errorGot,
    makePayIn,
    handleCashMethod,
    isUserActivationLevelYellow,
  } = data;

  return (
    <>
      <div className="mt-2 mb-3 form-group">
        <p className="withdrawTitle">{t("Payment_Mode")}</p>
        <div
          className={`${
            checkingForRestriction
              ? "pointer-events-none"
              : "pointer-events-auto"
          } bacnkCheckBoxWrapper p-2 flex flex-col gap-2`}
        >
          {checkingForRestriction && (
            <div className="flex justify-center items-center z-50 w-full h-48">
              <CircularProgress />
            </div>
          )}
          {!checkingForRestriction &&
            removeBalance
              ?.filter((cashin: any) => {
                if (isUserRestricted) {
                  return (
                    cashin?.cash_in_method?.payment_type?.name ==
                    "bank_transfer"
                  );
                } else {
                  return true;
                }
              })
              .map((value: any, index: any) => {
                return (
                  <div key={"deposit" + index}>
                    <div
                      className={
                        isUserActivationLevelYellow &&
                        (value.cash_in_method?.payment_type.name.toLowerCase() ==
                          "bank_transfer" ||
                          value.cash_in_method?.payment_type.name.toLowerCase() ==
                            "manual_bank_transfer")
                          ? "wrapperBankCheckBox m-0 innerBankBox"
                          : isUserActivationLevelYellow
                          ? "wrapperBankCheckBox m-0 innerBankBox opacity-50"
                          : "wrapperBankCheckBox m-0 innerBankBox"
                      }
                    >
                      <div className="wrapperBankCheckBoxwrapper">
                        <input
                          type="checkbox"
                          data-deposit-method="method"
                          className="default:ring-2"
                          onChange={(e: any) =>
                            handleCashMethod(
                              e.target.checked,
                              value.cash_in_method?.payment_type?.name.toLowerCase()
                            )
                          }
                          name={value?.cash_in_method?.name}
                          checked={
                            value.cash_in_method?.payment_type?.name.toLowerCase() ===
                            paymentMethod
                          }
                        />
                        <span className="checkmark"></span>
                      </div>
                      <label>
                        {t(
                          value?.cash_in_method?.name
                            ?.toLowerCase()
                            .split(" ")
                            .join("_")
                        )}
                      </label>
                    </div>

                    {isUserActivationLevelYellow &&
                      value.cash_in_method?.payment_type?.name.toLowerCase() ===
                        paymentMethod &&
                      paymentMethod !== "bank_transfer" && (
                        <div className="text-sm text-red-500 innerBankBox my-2">
                          {t("temp_restriction")}
                        </div>
                      )}

                    <CashInMethodDescriptions
                      value={value}
                      disabled={
                        isUserActivationLevelYellow &&
                        paymentMethod !== "bank_transfer"
                      }
                      paymentMethod={paymentMethod}
                    />
                  </div>
                );
              })}
        </div>
      </div>
      <button
        className="mt-3 btn mr-2"
        style={{ backgroundColor: "rgb(2,2,2)" }}
        onClick={() => {
          prev();
        }}
      >
        <small className="px-4 font-bold text-white">{t("back")}</small>
      </button>
      <button
        className="mt-3 btn"
        style={{ backgroundColor: "rgb(3, 115, 117)" }}
        onClick={() => makePayIn()}
        disabled={
          (isUserActivationLevelYellow && paymentMethod !== "bank_transfer") ||
          processingPayment ||
          feesCalculated ||
          errorGot !== "" ||
          checkingForRestriction ||
          paymentMethod == ""
        }
      >
        <small className="px-10 font-bold text-white">
          {processingPayment ? t("processing") : t("continue")}
        </small>
      </button>
    </>
  );
};

export default PaymentMethodComponent;
