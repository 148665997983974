import { isEmpty } from "../../utilities/help";
import BeneficiarySummary from "../BeneficiarySumary";
import CashLayout from "../CashLayout";
import { useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import TransferInfo from "../TransferPaymentWithdrawModal/TransferInfo";
import { useAppSelector } from "../../store/hooks";
import AmountAndReason from "./AmountAndReason";
import PaymentMethods from "./PaymentMethods";

const TransferPaymentModal = (props: any) => {
  const {
    transfer,
    userBalance,
    t,
    paymentMethod,
    amountInEuros,
    spread_config,
    fetchingFees,
    withdrawalFees,
    fees,
    widthdrawMethod,
    size,
    steps,
    setChecked,
    hasUserReachedPaymentPage,
    handleChangeHasUserReachedPaymentPage,
    noWithdrawMethodsFound,
  } = props;

  const [internalStep, setInternalStep] = useState(0);
  const [cashInMethod, setcashInMethod] = useState(null);
  const {
    auth: { user },
  } = useAppSelector((state) => state.persistedReducer);

  // const bonus = useMemo(() => {
  //   return widthdrawMethod !== "Mobile Money"
  //     ? 0
  //     : spread_config.received_amount * 0.005;
  // }, [spread_config, widthdrawMethod]);

  // find and set selected cash in method for the error showing for the amount, from payment method
  useEffect(() => {
    try {
      if (
        paymentMethod &&
        paymentMethod !== "" &&
        transfer.loggedInUserCountry?.cash_in_methods
      ) {
        const cashinmethod = transfer.loggedInUserCountry?.cash_in_methods.find(
          (cashinmethodItem: any) =>
            cashinmethodItem?.cash_in_method?.name?.toLowerCase() ==
            paymentMethod.toLowerCase()
        );
        if (cashinmethod) {
          setcashInMethod(cashinmethod);
        } else {
          setcashInMethod(null);
        }
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, [paymentMethod, transfer.loggedInUserCountry?.cash_in_methods]);

  useEffect(() => {
    try {
      // enable recipient withdraw toggle if receiver is not active and verified company
      if (
        transfer?.beneficiary?.is_individual ||
        transfer?.beneficiary?.company == null ||
        transfer?.beneficiary?.company == undefined ||
        (!transfer?.beneficiary?.is_individual &&
          !transfer?.beneficiary?.is_verified)
      ) {
        setChecked(true);
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, [transfer?.beneficiary?.id]);

  return (
    <div className="container">
      {size[0] < 900 && (
        <div className="w-full py-3 mb-4 bg-white">
          <h3>{steps[1]}</h3>
        </div>
      )}
      <div className="flex my-3 justify-center h-full">
        <div className="col-md-10">
          <div className="shadow-lg row">
            <div className="flex flex-row items-center justify-between border-b-2 col-md-12">
              <div className="flex-1">
                <BeneficiarySummary
                  name={transfer?.beneficiary?.full_name}
                  company={
                    transfer?.beneficiary?.company?.name ||
                    transfer?.beneficiary?.email
                  }
                />
              </div>
              <div style={{ textAlign: "right" }}>
                <p>{t("Balance")}</p>
                <CashLayout cash={userBalance} />
              </div>
            </div>

            {internalStep === 0 && (
              <AmountAndReason
                sethasUserReachedPaymentPage={
                  handleChangeHasUserReachedPaymentPage
                }
                cashInMethod={cashInMethod}
                props={props}
                next={() => setInternalStep((prev) => prev + 1)}
              />
            )}

            {internalStep === 1 && (
              <PaymentMethods
                props={props}
                cashInMethod={cashInMethod}
                noWithdrawMethodsFound={noWithdrawMethodsFound}
                previous={() => setInternalStep((prev) => prev - 1)}
              />
            )}

            {/* transfer info */}
            <TransferInfo
              noWithdrawMethodsFound={noWithdrawMethodsFound}
              hasUserReachedPaymentPage={
                !isEmpty(transfer?.transactionToRepeat) ||
                hasUserReachedPaymentPage
              }
              amountInEuros={amountInEuros}
              fees={fees}
              fetchingFees={fetchingFees}
              withdrawalFees={withdrawalFees}
              t={t}
              spread_config={spread_config}
              paymentMethod={paymentMethod}
              transfer={transfer}
              widthdrawMethod={widthdrawMethod}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransferPaymentModal;
