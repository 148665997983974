import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface StateProps {
  user: any;
  isTokenFresh: boolean;
  access_token: any;
  isLoggedIn: boolean;
  company: any;
  lang: string;
  fcmToken: any;
  currency: string;
  rate: number;
  favorites: any;
  userBalance: any;
  active_menu: string;
  bank_accounts: any;
  mfi_accounts: any;
  selected_account: any;
  mobile_accounts: any;
  dana_pay_bank_accounts: any;
  external_user: any;
  refreshCaptcha: boolean;
  notificationState: { title: String; message: String; show: Boolean };
  signUpData: any;
}

const initialState: StateProps = {
  user: null,
  isTokenFresh: false,
  access_token: null,
  isLoggedIn: false,
  company: null,
  lang: "fr",
  fcmToken: null,
  currency: "EUR",
  rate: 1,
  favorites: [],
  userBalance: 0,
  active_menu: "home",
  bank_accounts: [],
  mfi_accounts: [],
  mobile_accounts: [],
  selected_account: null,
  dana_pay_bank_accounts: [],
  external_user: null,
  refreshCaptcha: false,
  notificationState: { title: "", message: "", show: false },
  signUpData: {},
};

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginUser(state, actions: PayloadAction<any>) {
      state.user = actions.payload.user;
      state.access_token = actions.payload.access_token;
      state.isLoggedIn = true;
    },
    editUser(state, actions: PayloadAction<any>) {
      state.user = { ...state.user, ...actions.payload };
    },
    addCompanyToState(state, actions: PayloadAction<any>) {
      state.user.company = actions.payload;
    },
    setLanguage(state, actions: PayloadAction<any>) {
      state.lang = actions.payload;
    },
    setFCMToken(state, actions: PayloadAction<any>) {
      state.fcmToken = actions.payload;
    },
    updateCompanyState(state) {
      state.user.company.is_active = !state.user?.company.is_active;
    },
    resetAuth(state) {
      state.user = null;
      state.isTokenFresh = false;
      state.access_token = null;
      state.isLoggedIn = false;
      state.company = {};
      state.fcmToken = null;
      state.favorites = [];
      state.userBalance = 0;
      state.bank_accounts = [];
      state.mobile_accounts = [];
      state.selected_account = null;
      state.mfi_accounts = [];
      state.notificationState = { title: "", message: "", show: false };
      state.signUpData = {};
    },
    resetAuthForNewVersion(state) {
      state.user = null;
      state.isTokenFresh = false;
      state.isLoggedIn = false;
      state.company = {};
      state.fcmToken = null;
      state.favorites = [];
      state.userBalance = 0;
      state.bank_accounts = [];
      state.mobile_accounts = [];
      state.selected_account = null;
      state.mfi_accounts = [];
      state.notificationState = { title: "", message: "", show: false };
      state.signUpData = {};
    },
    changeCurrency(state, actions: PayloadAction<any>) {
      state.currency = actions.payload;
    },
    setRate(state, actions: PayloadAction<any>) {
      state.rate = actions.payload;
    },
    saveUserFavorites(state, actions: PayloadAction<any>) {
      state.favorites = actions.payload;
    },
    updateBalance(state, actions: PayloadAction<any>) {
      state.userBalance = actions.payload;
    },
    setActiveMenu(state, actions: PayloadAction<any>) {
      state.active_menu = actions.payload;
    },
    deleteBankAccount(state, actions: PayloadAction<any>) {
      state.bank_accounts = state.bank_accounts.filter(
        (account: any) => account.id !== actions.payload
      );
    },
    setSelectedBank(state, actions: PayloadAction<any>) {
      state.selected_account = actions.payload;
    },
    clearSelectedBank(state) {
      state.selected_account = null;
    },
    setAccounts(state, actions: PayloadAction<any>) {
      state.bank_accounts = actions.payload;
    },
    setMFIAccounts(state, actions: PayloadAction<any>) {
      state.mfi_accounts = actions.payload;
    },
    setMMAccounts(state, actions: PayloadAction<any>) {
      state.mobile_accounts = actions.payload;
    },
    deleteMMAccount(state, actions: PayloadAction<any>) {
      state.mobile_accounts = state.mobile_accounts.filter(
        (account: any) => account.id !== actions.payload
      );
    },
    updateDanaPayBankAccounts(state, actions: PayloadAction<any>) {
      state.dana_pay_bank_accounts = actions.payload;
    },
    set_external_user(state, actions: PayloadAction<any>) {
      state.external_user = actions.payload;
    },
    refreshCaptchaToken(state, actions: PayloadAction<boolean>) {
      state.refreshCaptcha = actions.payload;
    },
    setnotificationState(
      state,
      actions: PayloadAction<{
        show: Boolean;
      }>
    ) {
      state.notificationState.show = actions.payload?.show;
    },
    setSignUpData(state, actions: PayloadAction<any>) {
      state.signUpData = { ...actions.payload };
    },
  },
});

export const {
  loginUser,
  editUser,
  addCompanyToState,
  setLanguage,
  setFCMToken,
  resetAuth,
  updateCompanyState,
  changeCurrency,
  setRate,
  saveUserFavorites,
  updateBalance,
  setActiveMenu,
  deleteBankAccount,
  setSelectedBank,
  setAccounts,
  setMFIAccounts,
  setMMAccounts,
  deleteMMAccount,
  updateDanaPayBankAccounts,
  set_external_user,
  refreshCaptchaToken,
  clearSelectedBank,
  setnotificationState,
  resetAuthForNewVersion,
  setSignUpData,
} = AuthSlice.actions;
export default AuthSlice.reducer;
