import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/browser";

interface Props {
  data: any;
  setData: any;
  value: any;
  width?: boolean;
  label?: string;
  type?: string;
  canShowAll?: boolean;
  hasOptionalInput?: boolean;
  classApply?: boolean;
  selectedPaymentMethod?: any;
}

const PayOnDemandSelector: React.FC<Props> = ({
  data,
  setData,
  value,
  selectedPaymentMethod,
  width = false,
  label = "",
  type = null,
  canShowAll = false,
  hasOptionalInput = false,
  classApply = false,
}) => {
  const { t } = useTranslation();
  const [payMethods, setpayMethods] = useState([]);

  // initialize methods
  useEffect(() => {
    try {
      if (
        data !== undefined &&
        data !== null &&
        Object.keys(selectedPaymentMethod).length > 0
      ) {
        setpayMethods(
          data.map((i: any) =>
            i.type.toString().toLowerCase() ==
              selectedPaymentMethod.type.toString().toLowerCase() &&
            i.provider.toString().toLowerCase() ==
              selectedPaymentMethod.provider.toString().toLowerCase()
              ? { ...i, checked: true }
              : { ...i, checked: false }
          )
        );
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, []);

  // handle selection of payment method
  const handlePayMethod = (checkedinput: any, index: any, selectData: any) => {
    setData(selectData as string);
    setpayMethods((prev: any) => {
      return prev.map((itm: any, itmindex: any) => {
        if (itmindex == index) {
          return { ...itm, checked: checkedinput };
        } else {
          return { ...itm, checked: false };
        }
      });
    });
  };

  return (
    <div className="form-group">
      {label !== "" && (
        <label className="mb-1">
          {classApply === true ? (
            <p className="withdrawTitle">{t(label)}</p>
          ) : (
            <small>{t(label)}</small>
          )}
        </label>
      )}
      <div className="bacnkCheckBoxWrapper">
        {payMethods.length > 0 &&
          payMethods
            .filter((option: any) => option.type !== type)
            ?.map((val: any, index: number) => {
              return (
                <div
                  className="wrapperBankCheckBox max-[760px]:!items-start max-[760px]:!gap-4 innerBankBox md:flex-row flex-col"
                  key={`${index}account`}
                >
                  <div className="flex items-center gap-4">
                    <div className="wrapperBankCheckBoxwrapper min-w-[30px]">
                      <input
                        type="checkbox"
                        className="default:ring-2"
                        onChange={(e: any) => {
                          handlePayMethod(
                            e.target.checked,
                            index,
                            val.favorite?.first_name
                              ? val.favorite?.first_name
                              : val?.provider
                          );
                        }}
                        disabled={payMethods.length == 1}
                        checked={val.checked}
                      />
                      <span className="checkmark"></span>
                    </div>
                    <label>
                      {val.favorite?.first_name
                        ? val.favorite?.first_name
                            ?.toLowerCase()
                            .replaceAll(" ", "_")
                        : t(`${val.name?.toLowerCase().replaceAll(" ", "_")}`)}
                    </label>
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default PayOnDemandSelector;
