import { Form, Formik } from "formik";
import { editUser, setSignUpData } from "../../store/features/Auth/AuthSlice";
import CustomDropdown from "../CustomDropdown";
import InputField from "../forms/InputField";
import FormErrorText from "../FormErrorText";
import { Alert } from "@material-ui/lab";
import { saveRegistrationProgress } from "../../store/features/Auth/Auth";
import * as Sentry from "@sentry/browser";

const CompanyActivityRevenue = (props: any) => {
  const {
    t,
    signUpData,
    user,
    jobListing,
    dispatch,
    Yup,
    errorsState,
    setActiveStep,
    setprogressBarData,
    revenueOptions,
  } = props;

  const validationSchema = Yup.object().shape({
    // job: Yup.string().required("activity_err"),
    job: Yup.string().when(["customJob", "activity_id"], {
      is: (customJob: any, activity_id: any) => {
        return (
          (customJob == "" && activity_id == "") ||
          (customJob == null && activity_id == null) ||
          (customJob == undefined && activity_id == undefined)
        );
      },
      then: Yup.string().required("activity_err"),
    }),
    customJob: Yup.string().test(
      "yourTestCondition",
      "activity_err",
      function (value: any, context: any) {
        return (
          (((context.parent.job !== "" && context.parent.job !== undefined) ||
            (context.parent.activity_id !== "" &&
              context.parent.activity_id !== undefined)) &&
            context.parent.job !== "Autres activités") ||
          (context.parent.job == "Autres activités" &&
            value !== "" &&
            value !== undefined)
        );
      }
    ),
    monthly_revenue: Yup.string().required("company_revenue_required"),
  });

  return (
    <div className="p-8 max-sm:px-4 rounded-lg shadow-lg">
      <p className="mb-3 font-bold text-center">
        {t("Enter_revenue_details_company")}
      </p>
      <Formik
        validateOnMount
        enableReinitialize
        validateOnBlur
        validateOnChange
        key="form5"
        initialValues={{
          activity_id:
            signUpData?.activity_id || user?.progression?.activity_id || "",
          job: user?.progression?.job || "",
          monthly_revenue:
            signUpData?.monthly_revenue ||
            user?.monthly_revenue ||
            user?.progression?.monthly_revenue ||
            "",
          customJob: "",
        }}
        onSubmit={(data, { setSubmitting }) => {
          try {
            // activity id in case on non custom job
            const activityId: any = jobListing.find(
              (job: any) => job.name == data.job
            );

            const revenueObject = revenueOptions.find(
              (revenue: any) => revenue.name == data.monthly_revenue
            );

            let payload = {
              ...user?.progression,
              ...data,
              ...signUpData,
              job: data.job == "Autres activités" ? data.customJob : data.job,
              monthly_revenue: data.monthly_revenue,
              revenue_level_id: revenueObject.id,
              reason_for_modification: "Adding revenue details",
            };
            delete payload.customJob;

            // if job is not custom, add activity id
            if (data.job == "Autres activités") {
              delete payload.activity_id;
            } else {
              payload.activity_id = activityId.id;
              delete payload.job;
            }
            dispatch(setSignUpData(payload));
            saveRegistrationProgress({ ...payload, step: 2 }).finally(() => {
              window.heap.track("Company Revenue information set", {
                ...data,
                email: user?.email,
              });

              setActiveStep("companyEnvisionedCountries");
              setprogressBarData({
                progress: 75,
                title: "envisioned_countries",
              });
              setSubmitting(false);
            });
          } catch (error: any) {
            Sentry.captureException(error);
          }
        }}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          isSubmitting,
          touched,
          isValid,
        }) => (
          <Form className="flex flex-col gap-3 company-registration">
            <div className="flex flex-col">
              <small className="mb-3 text-gray-700">{t("activity")}</small>
              <CustomDropdown
                placeholder={t("job_select")}
                otherValue={"Autres activités"}
                defaultValue={
                  jobListing.find((job: any) => job.id == values?.activity_id)
                    ?.name
                    ? jobListing.find(
                        (job: any) => job.id == values?.activity_id
                      )?.name
                    : values.job
                }
                customClasses="wrapperBankCheckBox innerBankBox bacnkCheckBoxWrapper wrapperBankCheckBox m-0"
                list={jobListing}
                setCustomProvider={(val: any) =>
                  val !== "" && handleChange("customJob")(signUpData?.job)
                }
                onchange={(val: any) => {
                  handleChange("job")(val);
                }}
              />

              {values.job == "Autres activités" && (
                <InputField
                  extraClasses="mb-3 customInputStyle"
                  name="customJob"
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t("job")}
                  value={values.customJob}
                  error={isSubmitting ? "" : t(`${errors.customJob}`)}
                  touched={touched.customJob}
                />
              )}
            </div>

            <div className="flex flex-col">
              <small className="mb-3 text-gray-700">{t("revenue_level")}</small>
              <CustomDropdown
                placeholder={t("revenue_select")}
                otherValue={
                  revenueOptions.length > 0 && revenueOptions[0]?.name
                }
                defaultValue={values?.monthly_revenue}
                customClasses="wrapperBankCheckBox innerBankBox bacnkCheckBoxWrapper wrapperBankCheckBox m-0"
                list={revenueOptions}
                onchange={(val: any) => {
                  handleChange("monthly_revenue")(val);
                }}
              />

              {errors?.monthly_revenue && (
                <FormErrorText
                  errorMessage={isSubmitting ? "" : errors?.monthly_revenue}
                />
              )}
            </div>

            <div>
              {errorsState.length > 0 && (
                <div className="mb-4">
                  <Alert severity="error">{errorsState.join(" ,")}</Alert>
                </div>
              )}
            </div>
            <div className="flex justify-between gap-3">
              <button
                className="flex-1 rounded-lg btn btn-block"
                style={{ backgroundColor: "#666" }}
                onClick={() => {
                  setActiveStep(1);
                  setprogressBarData({
                    progress: 25,
                    title: "contact_information",
                  });
                }}
              >
                <span className="text-white capitalize md:text-md max-sm:!text-sm">
                  {t("back")}
                </span>
              </button>

              <button
                className="flex-1 rounded-lg btn btn-block"
                style={{ backgroundColor: "rgb(3, 115, 117)" }}
                disabled={isSubmitting || !isValid}
                type="submit"
                data-individual="individual"
              >
                <span
                  className="text-white capitalize md:text-md max-sm:!text-sm"
                  data-individual="individual"
                >
                  {t("next")}
                </span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CompanyActivityRevenue;
