const statues: any = {
  payment_started: { bg: "#eee", text: "#777" },
  payment_completed: { bg: "#e3fcef", text: "#0E8145" },
  funds_received: { bg: "#eee", text: "#777" },
  transfer_in_progress: { bg: "#fff0b3", text: "#8F7200" },
  deposit_failed: { bg: "#feeae6", text: "#9D2007" },
  deposit_completed: { bg: "#e3fcef", text: "#0E8145" },
  deposit_in_progress: { bg: "#fff0b3", text: "#8F7200" },
  transfer_failed: { bg: "#feeae6", text: "#9D2007" },
  transfer_completed: { bg: "#e3fcef", text: "#0E8145" },
  withdraw_requested: { bg: "#eee", text: "#777" },
  withdraw_in_progress: { bg: "#fff0b3", text: "#8F7200" },
  withdraw_failed: { bg: "#feeae6", text: "#9D2007" },
  withdraw_completed: { bg: "#e3fcef", text: "#0E8145" },
  payment_requested: { bg: "#eee", text: "#777" },
  payment_failed: { bg: "#feeae6", text: "#9D2007" },
  withdraw_pending: { bg: "#fff0b3", text: "#8F7200" },
  pending: { bg: "#fff0b3", text: "#8F7200" },
};

const ots: any = {
  instant_transfer: { text: "instant", icon: "fa fa-paper-plane-o" },
  payment_request: { text: "instant", icon: "fa fa-history" },
  withdraw: {
    text: "payout",
    icon: "fa fa-minus",
  },
  deposit: {
    text: "deposit",
    icon: "fa fa-plus",
  },
  bulk_transfer: {
    text: "bulkPayment",
    icon: "fa fa-flash",
  },
  direct_transfer: {
    text: "direct_transfer",
    icon: "fa fa-forward",
  },
  campaign_referral_reward: {
    text: "Campaign_Reward",
    icon: "fa fa-gift",
  },
};

export { statues, ots };
