import moment from "moment";
import React from "react";
import CashLayout from "../CashLayout";
import Country_comp from "../Country_comp";
import * as Sentry from "@sentry/browser";
import StatusButtons from "../StatusButtons";
import { statues, ots } from "./data";
import { useAppSelector } from "../../store/hooks";

const WebList: React.FC<any> = ({ transaction, OpenModal, t, user }) => {
  const { transfer, auth } = useAppSelector((state) => state.persistedReducer);
  const {
    operation_type,
    amount_without_fee,
    status,
    created_at,
    source_user,
    destination_user,
    exchange_rate_applied,
    logged_in_transactor,
    aborted,
  } = transaction;
  const isBulk: boolean = operation_type === "bulk_transfer";
  const isDemanded: boolean = operation_type === "payment_demand";
  const isActiveSender: boolean = user?.id === source_user?.id;
  const cash = isBulk
    ? transaction.transfer?.bulk_transfer_recipient_details.reduce(
        (sum: number, user: any) => sum + parseFloat(user.amount_in_euros),
        0
      )
    : transaction?.transfer?.amount_without_fee === undefined
    ? amount_without_fee
    : transaction?.transfer?.amount_without_fee;

  const returnUser = () => {
    try {
      if (isBulk) {
        if (source_user.id === user.id) {
          return {
            full_name:
              transaction?.transfer?.bulk_transfer_recipient_details.length +
              " Beneficiaries",
            country:
              transaction?.transfer?.bulk_transfer_recipient_details[0].country,
          };
        } else {
          return source_user;
        }
      } else {
        if (isDemanded) {
          if (isActiveSender) {
            return destination_user;
          } else {
            return source_user;
          }
        } else {
          if (operation_type === "pay_out" || operation_type === "deposit") {
            return destination_user;
          } else if (operation_type === "direct_transfer") {
            return destination_user === null
              ? transaction?.transfer?.destination_users[0]
              : destination_user;
          } else {
            if (isActiveSender) {
              return destination_user;
            } else {
              return source_user;
            }
          }
        }
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  return (
    <table className="table">
      <tbody>
        <tr className="cursor-pointer">
          <td style={{ width: "3%" }} onClick={() => OpenModal(transaction)}>
            {transaction?.logged_in_transactor === "receiver" &&
            operation_type === "direct_transfer" ? (
              <span
                className="flex items-center justify-center bg-orange-200"
                style={{ height: 35, width: 35, borderRadius: 20 }}
              >
                <i
                  className={
                    transaction?.transfer?.bulk_transfer_recipient_details
                      ?.length > 0
                      ? "fa fa-flash  m-0 text-green-500"
                      : `fa fa-arrow-down  m-0 text-orange-500`
                  }
                ></i>
              </span>
            ) : transaction?.logged_in_transactor === "sender" &&
              operation_type === "direct_transfer" ? (
              <span
                className="flex items-center justify-center bg-green-200"
                style={{ height: 35, width: 35, borderRadius: 20 }}
              >
                <i
                  className={
                    transaction?.transfer?.bulk_transfer_recipient_details
                      ?.length > 0
                      ? "fa fa-flash  m-0 text-green-500"
                      : `fa fa-arrow-up  m-0 text-green-500`
                  }
                ></i>
              </span>
            ) : operation_type === "instant_transfer" ? (
              <>
                {isActiveSender ? (
                  <span
                    className="flex items-center justify-center bg-green-200"
                    style={{ height: 35, width: 35, borderRadius: 20 }}
                  >
                    <i className={`fa fa-arrow-up  m-0 text-green-500`}></i>
                  </span>
                ) : (
                  <span
                    className="flex items-center justify-center bg-orange-200"
                    style={{ height: 35, width: 35, borderRadius: 20 }}
                  >
                    <i className={`fa fa-arrow-down  m-0 text-orange-500`}></i>
                  </span>
                )}
              </>
            ) : (
              <span
                className="flex items-center justify-center bg-green-200"
                style={{ height: 35, width: 35, borderRadius: 20 }}
              >
                <i
                  className={`${ots[operation_type]?.icon} m-0 text-green-500`}
                ></i>
              </span>
            )}
          </td>

          <td style={{ width: "10%" }} onClick={() => OpenModal(transaction)}>
            <small className="text-gray-800 capitalize">
              {/* {transaction?.logged_in_transactor === 'sender' && transaction?.operation_type === "campaign_referral_reward" && t('Campaign_Reward')} */}
              {transaction?.logged_in_transactor === "receiver" &&
              (transaction?.operation_type === "direct_transfer" ||
                transaction?.operation_type === "instant_transfer")
                ? t("Received_transfer")
                : t(
                    transaction.transfer?.bulk_transfer_recipient_details
                      ?.length > 0
                      ? "bulkPayment"
                      : ots[operation_type]?.text
                  )}
            </small>
          </td>
          <td style={{ width: "10%" }} onClick={() => OpenModal(transaction)}>
            <small className="capitalize">
              {transaction?.logged_in_transactor === "receiver" &&
              transaction?.operation_type !== "withdraw"
                ? moment(transaction?.done_at).format("DD/MM/YYYY   H:mm:ss")
                : moment(created_at).format("DD/MM/YYYY   H:mm:ss")}
            </small>
          </td>
          <td style={{ width: "7%" }} onClick={() => OpenModal(transaction)}>
            <Country_comp eng_country_name={returnUser()?.country} />
          </td>
          <td style={{ width: "10%" }} onClick={() => OpenModal(transaction)}>
            <small className="capitalize">
              {logged_in_transactor === "receiver" &&
              (operation_type === "direct_transfer" ||
                operation_type === "instant_transfer")
                ? transaction?.source_user?.full_name?.trim().toLowerCase()
                : returnUser()?.full_name?.trim().toLowerCase() ||
                  returnUser()?.full_phone_number}
            </small>
          </td>
          <td style={{ width: "10%" }} onClick={() => OpenModal(transaction)}>
            <CashLayout cash={cash} exchange_rate={exchange_rate_applied} />
          </td>
          <td style={{ width: "14%" }} onClick={() => OpenModal(transaction)}>
            <small
              className="px-2 mt-1 capitalize font-semiBold badge rounded-pill"
              style={{
                fontSize: 10,
                color:
                  statues[
                    status.toLowerCase() === "suspicious"
                      ? "transfer_in_progress"
                      : status.toLowerCase()
                  ] &&
                  statues[
                    status.toLowerCase() === "suspicious"
                      ? "transfer_in_progress"
                      : status.toLowerCase()
                  ]?.text,
                backgroundColor:
                  statues[
                    status.toLowerCase() === "suspicious"
                      ? "transfer_in_progress"
                      : status.toLowerCase()
                  ] &&
                  statues[
                    status.toLowerCase() === "suspicious"
                      ? "transfer_in_progress"
                      : status.toLowerCase()
                  ]?.bg,
              }}
            >
              {aborted == true
                ? t("aborted")
                : status === "suspicious"
                ? t("suspicious")
                : t(status)}
            </small>
          </td>
          <td style={{ width: "16%" }}>
            <StatusButtons
              transfer={transaction}
              transcationsObject={transfer}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default WebList;
