import { LinearProgress } from "@material-ui/core";

const SignUpProgressBar = (props: any) => {
  const { progress, label, t } = props;
  return (
    <div className="mb-5 flex flex-col mx-auto">
      <div className="md:text-md max-sm:!text-sm">{t(label)}</div>
      <LinearProgress variant="determinate" value={progress} />
    </div>
  );
};

export default SignUpProgressBar;
