import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute: React.FC<any> = ({ children, ...rest }) => {
  const access_token = localStorage.getItem("user:key");
  //
  useEffect(() => {
    //extract id for the payment/:id route when the request is from an  external
    const id = rest.computedMatch.params.id;
    if (id) {
      localStorage.setItem("payment_id", id);
    }
  }, []);
  return (
    <Route {...rest}>
      {access_token ? children : <Redirect to="/login" />}
    </Route>
  );
};

export default ProtectedRoute;
