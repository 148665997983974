import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useMemo,
} from "react";
import "react-phone-input-2/lib/style.css";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  fetchCompanyRevenue,
  fetchJobListing,
} from "../../store/features/Auth/Auth";
import { editUser, resetAuth } from "../../store/features/Auth/AuthSlice";
import { useHistory } from "react-router";
import {
  resetTransfer,
  updateCountries,
} from "../../store/features/Transfer/TransferSlice";
import { resetDeveloper } from "../../store/features/Developer/DeveloperSlice";
import { useTranslation } from "react-i18next";
import "../../helpers/i18n";
import LangSwitch from "../../components/LangSwitch";
import {
  getTransferCountries,
  getDanaPayCountries,
  currentBalance,
  paymentsAnonymous,
} from "../../store/features/Transfer/Transfer";
import SumSub from "../../components/Sumsub";
import {
  getCurrentDate,
  hasUserCompletedRegistration,
  isValidData,
} from "../../utilities/help";
import { toast } from "material-react-toastify";
import useResize from "../../hooks/useResize";
import { customerIoTrack } from "../../utilities/customeriohelpers";
import SignUpProgressBar from "../../components/SignUpProgressBar";
import DiscoveryAndUsertypeModal from "../../components/SignUp/DiscoveryAndUsertypeModal";
import IndividualTransferInfo from "../../components/SignUp/IndividualTransferInfo";
import ResidencySelection from "../../components/SignUp/ResidencySelection";
import CompanyDocumentsScreen from "../../components/SignUp/CompanyDocumentsScreen";
import PersonalInfoScreen from "../../components/SignUp/PersonalInfoScreen";
import IndividualInfoScreen from "../../components/SignUp/IndividualInfoScreen";
import * as Sentry from "@sentry/browser";
import CompanyActivityRevenue from "../../components/SignUp/CompanyActivityRevenue";
import CompanyEnvisionedCountries from "../../components/SignUp/CompanyEnvisionedCountries";
import { useLocation } from "react-router-dom";

const SignUp = () => {
  // get the active pased page to load that section ofthe screen
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("page");

  const { t } = useTranslation();
  const [w, h] = useResize();
  const {
    auth: { user, external_user, signUpData },
    transfer: { transferFromType, danaPayCountries },
  } = useAppSelector((state: any) => state.persistedReducer);
  const dispatch: any = useAppDispatch();

  const [activeStep, setActiveStep] = useState<number | String>(0);
  const [isIndividualUser, setIsIndividualUser] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [steps, getSteps] = useState<any>([
    "Discovery",
    "Personal_Information",
    "Company_Information",
    "Documents",
    "Verify_User",
  ]);

  // fetch user data in case of reload
  useEffect(() => {
    currentBalance()
      .then((res: any) => {
        dispatch(editUser(res));
      })
      .catch((error: any) => {
        toast.warning(t(error?.data?.message));
        Sentry.captureException(error);
      });
  }, []);

  // handle redirection

  // type of user states
  const [typeOfUser, settypeOfUser] = useState([
    {
      title: "Individual",
      value: "individual",
      active: true,
    },
    {
      title: "Business",
      value: "business",
      active: false,
    },
  ]);

  const uservalue = useMemo(() => {
    const usertype = typeOfUser.find((user: any) => user.active);
    if (usertype) {
      return usertype.value;
    }
  }, [typeOfUser]);

  // usertype selector
  const handleUserType = (title: string) => {
    window.localStorage.setItem("acctype", title);
    settypeOfUser((prev: any) => {
      return prev.map((userProfile: any) => {
        return { ...userProfile, active: userProfile.title == title };
      });
    });
  };

  const [discoveryMethod, setdiscoveryMethod] = useState({});
  const [isUserComingBack, setisUserComingBack] = useState(true);

  // skip first step if discovery is filled already
  useEffect(() => {
    // if (id) {
    //   if (id === "1") {
    //     if (user?.is_individual) {
    //       handleUserType("Individual");
    //     } else {
    //       handleUserType("Business");
    //     }
    //   }
    //   setActiveStep((prev) => {
    //     return Number(id);
    //   });
    if (user !== undefined && user !== null && user?.meta !== undefined) {
      if (Array.isArray(user?.meta) && user?.meta.length < 1) {
        setActiveStep(0);
      } else if (isUserComingBack) {
        setisUserComingBack(false);
        setActiveStep(0);
        setdiscoverySubStep(2);
        setdiscoveryMethod(user?.meta);
      }
    }
  }, []);

  // handle customer io events
  const registrationFailDataRef = useRef<any>();
  const [registrationFailData, setRegistrationFailData] = useState({
    channel: "web",
    email: user?.email,
    abandoned: false,
    "onboarding start date": getCurrentDate(),
    "phone number": user?.full_phone_number,
    "first name": user?.first_name,
    "last name": user?.last_name,
    "last page viewed": "Discovery page",
  });

  useEffect(() => {
    switch (activeStep) {
      case 0:
        setRegistrationFailData((prev: any) => {
          return { ...prev, "last page viewed": "Origin setting page" };
        });
        break;

      case 1:
        setRegistrationFailData((prev: any) => {
          return {
            ...prev,
            "last page viewed": "Personal information setting page",
          };
        });
        break;

      case 2:
        if (isIndividualUser) {
          setRegistrationFailData((prev: any) => {
            return { ...prev, "last page viewed": "Revenue setting page" };
          });
        } else {
          setRegistrationFailData((prev: any) => {
            return {
              ...prev,
              "last page viewed": "Company information setting page",
            };
          });
        }
        break;

      case 3:
        if (!isIndividualUser) {
          setRegistrationFailData((prev: any) => {
            return { ...prev, "last page viewed": "Documents page" };
          });
        }
        break;

      case 4:
        if (isIndividualUser) {
          setRegistrationFailData((prev: any) => {
            return {
              ...prev,
              "last page viewed": "Selfy and document setting page",
            };
          });
        }
        break;

      default:
        break;
    }
  }, [activeStep, isIndividualUser]);

  useEffect(() => {
    registrationFailDataRef.current = registrationFailData;
  }, [registrationFailData]);

  const registrationFailIn10Mins = useRef<any>();
  useEffect(() => {
    registrationFailIn10Mins.current = setTimeout(() => {
      customerIoTrack({
        name: "Registration Unfinished In 20 Mins",
        data: registrationFailDataRef.current,
      });
    }, 1200000);
    return () => {
      clearRegistrationFailNotification();
    };
  }, []);

  const clearRegistrationFailNotification = () => {
    clearTimeout(registrationFailIn10Mins.current);
  };

  useEffect(() => {
    return () => {
      clearRegistrationFailNotification();
    };
  }, []);

  // window or tab close events
  const unloadRegistrationFailNotification = () => {
    customerIoTrack({
      name: "Regsitration Unfinished In 20 Mins",
      data: { ...registrationFailData, abandoned: true },
    });
    localStorage.removeItem("depositattempts");
    localStorage.removeItem("maketransferattempts");
    clearRegistrationFailNotification();
  };

  useEffect(() => {
    window.addEventListener("beforeunload", unloadRegistrationFailNotification);

    return () => {
      window.removeEventListener(
        "beforeunload",
        unloadRegistrationFailNotification
      );
    };
  }, []);

  const styles = {
    header: {
      height: 70,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    main: {
      height: "100vh",
      overflow: "none",
    },
    footer: {
      height: 70,
      display: "flex",
      alignItems: "center",
      padding: 20,
    },
    footerSpan: {
      fontSize: 13,
      marginRight: 20,
      fontWeight: 700,
      color: "#666",
    },
    body: {
      height: "calc(100vh - 150px)",
      width: "100%",
      margin: "auto",
      backgroundColor: "#fff",
    },
    brand: {
      display: "flex",
      flexDirection: "row",
    },
  };

  const [errorsState, setErrorsState] = useState<any>([]);
  const [phone, setPhone] = useState(external_user?.phone || "");
  const [country, setCountry] = useState("");
  const [countryName, setCountryName] = useState("");
  const [external_user_info, setExternalUser] = useState(external_user);
  const [selectedCountryReset, setSelectedCountryReset] =
    useState<boolean>(false);
  const history = useHistory();

  const get_external_user_country = () => {
    try {
      if (external_user_info) {
        const user_country =
          danaPayCountries &&
          danaPayCountries?.find(
            (value: any) => value.name.toLowerCase === external_user.country
          );
        if (user_country) {
          setCountry(user_country?.country_code);
          setCountryName(user_country.name);
        }
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      return Number(prevActiveStep) + 1;
    });
  };

  const fetchImportantData = async () => {
    try {
      const [transferCountries, allCountries]: any = await Promise.all([
        getTransferCountries(),
        getDanaPayCountries(),
      ]);
      if (
        transferCountries &&
        transferCountries.data &&
        transferCountries.data.length > 0
      ) {
        setcountryListing(
          transferCountries.data.map((country: any) => {
            return {
              ...country,
              name: country.name,
              flag: `${country?.country_code}.png`,
            };
          })
        );
      }
      dispatch(updateCountries([transferCountries.data, allCountries]));
      setfetchingCountryListing(false);
    } catch (error: any) {
      setfetchingCountryListing(false);
      toast.warning(t(error?.data?.message));
      Sentry.captureException(error);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => Number(prevActiveStep) - 1);
  };

  const finish = () => {
    try {
      if (transferFromType === "internal") {
        const external_link = localStorage.getItem("ext_mm_process_link");
        currentBalance()
          .then((response: any) => {
            dispatch(editUser(response));
            if (response.is_active && response.is_verified) {
              if (external_link) {
                fetch(external_link)
                  .then((res: any) => {
                    return res.json();
                  })
                  .then((response: any) => {
                    if (response.redirect_uri) {
                      window.location?.replace(response.redirect_uri);
                    } else {
                      history.push("dashboard/home");
                    }
                  })
                  .catch((err: any) => {
                    toast.warning(t(err?.data?.message));
                    Sentry.captureException(err);
                  });
              } else {
                const appref = localStorage.getItem("appref");
                if (appref) {
                  paymentsAnonymous(appref)
                    .then((response: any) => {
                      const id = response.details.id;
                      history.push(`/payment/${id}?status=anonymous`);
                    })
                    .catch((error: any) => {
                      Sentry.captureException(error);

                      toast.warning(t(error?.data?.message));
                      if (error.status === 403) {
                        localStorage.removeItem("appref");

                        history.push("/dashboard/home");
                      }
                    });
                } else {
                  history.push("dashboard/home");
                }
              }
            } else {
              history.push("dashboard/home");
            }
          })
          .catch((error: any) => {
            Sentry.captureException(error);
            toast.warning(t(error?.data?.message));
          });
      } else {
        history.push("/complete_form");
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  // new stepper
  const [discoverySubStep, setdiscoverySubStep] = useState(1);
  const [progressBarData, setprogressBarData] = useState({
    progress: 0,
    title: "",
  });

  // receiving country listing
  const [countryListing, setcountryListing] = useState<any>([]);
  const [fetchingCountryListing, setfetchingCountryListing] = useState(true);
  const [transferReceivingCountries, settransferReceivingCountries] = useState<
    string[]
  >([]);

  // set sending countries from progression
  useEffect(() => {
    try {
      if (
        countryListing &&
        countryListing.length > 0 &&
        user?.progression?.receiving_countries_ids
      ) {
        const filteredCountries = countryListing
          .filter((country: any) =>
            user?.progression?.receiving_countries_ids.includes(country.id)
          )
          .map((country: any) => country.name);
        settransferReceivingCountries(filteredCountries);
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, [user, countryListing]);

  const [residencyLevelName, setresidencyLevelName] = useState("");
  const [residencySelection, setresidencySelection] = useState("");

  const [compnay_revenue_levels, setCompanyRevenueLevels] = useState<any>([]);

  const fetchCompanyRevenueLevels = async () => {
    try {
      const response = await fetchCompanyRevenue();
      if (response?.length > 0) {
        setCompanyRevenueLevels(response);
      }
    } catch (error: any) {
      Sentry.captureException(error);
      toast.warning(t(error?.data?.message || error?.response));
    }
  };

  // job listing
  const [jobListing, setjobListing] = useState<any>([]);
  useEffect(() => {
    fetchCompanyRevenueLevels();
    fetchJobListing()
      .then((jobListingResponse: any) => {
        if (jobListingResponse?.data && jobListingResponse?.data?.length > 0) {
          setjobListing(jobListingResponse?.data);
        }
      })
      .catch((error: any) => {
        toast.warning(t(error?.data?.message));
        Sentry.captureException(error);
      });
  }, []);

  // new company states
  const [
    companySendingEnvisionedCountries,
    setcompanySendingEnvisionedCountries,
  ] = useState([]);
  const [
    companyReceivingEnvisionedCountries,
    setcompanyReceivingEnvisionedCountries,
  ] = useState([]);

  const getStepContent = (stepIndex: number | String, progressBar: any) => {
    switch (stepIndex) {
      case 0:
        return (
          <DiscoveryAndUsertypeModal
            user={user}
            signUpData={signUpData}
            processing={processing}
            discoverySubStep={discoverySubStep}
            discoveryMethod={discoveryMethod}
            t={t}
            setdiscoverySubStep={setdiscoverySubStep}
            setProcessing={setProcessing}
            typeOfUser={typeOfUser}
            activeStep={activeStep}
            handleNext={handleNext}
            handleUserType={handleUserType}
            setprogressBarData={setprogressBarData}
          />
        );
      case 1:
        // personal information
        return (
          <div className="flex justify-center my-5">
            <div className="col-md-6 col-11">
              {progressBar}
              <PersonalInfoScreen
                t={t}
                isIndividualUser={isIndividualUser}
                user={user}
                signUpData={signUpData}
                phone={phone}
                country={country}
                setErrorsState={setErrorsState}
                typeOfUser={typeOfUser}
                dispatch={dispatch}
                setActiveStep={setActiveStep}
                progressBar={progressBar}
                setProcessing={setProcessing}
                uservalue={uservalue}
                Yup={Yup}
                setprogressBarData={setprogressBarData}
                setCountry={setCountry}
                setIsIndividualUser={setIsIndividualUser}
                setisUserComingBack={setisUserComingBack}
                setCountryName={setCountryName}
                setPhone={setPhone}
                errorsState={errorsState}
                setSelectedCountryReset={setSelectedCountryReset}
                handleBack={handleBack}
                processing={processing}
              />
            </div>
          </div>
        );
      case "individualTransferInfo":
        return (
          <div className="flex justify-center my-5">
            <div className="col-md-6 col-11">
              {progressBar}

              <IndividualTransferInfo
                user={user}
                typeOfUser={typeOfUser}
                progressBar={progressBar}
                t={t}
                fetchingCountryListing={fetchingCountryListing}
                transferReceivingCountries={transferReceivingCountries}
                countryListing={countryListing}
                dispatch={dispatch}
                signUpData={signUpData}
                setprogressBarData={setprogressBarData}
                setActiveStep={setActiveStep}
                Yup={Yup}
                settransferReceivingCountries={settransferReceivingCountries}
                setresidencyLevelName={setresidencyLevelName}
              />
            </div>
          </div>
        );
      case "residenceSelection":
        return (
          <div className="flex flex-col  justify-center my-5">
            <div className="col-md-6 mx-auto col-10">{progressBar}</div>
            <div className="col-md-5 mb-5 mx-auto">
              <ResidencySelection
                progressBar={progressBar}
                t={t}
                dispatch={dispatch}
                residencySelection={residencySelection}
                typeOfUser={typeOfUser}
                user={user}
                signUpData={signUpData}
                setActiveStep={setActiveStep}
                setprogressBarData={setprogressBarData}
                Yup={Yup}
                activeStep={activeStep}
                setresidencyLevelName={setresidencyLevelName}
                setresidencySelection={setresidencySelection}
              />
            </div>
          </div>
        );
      case 2:
        return (
          <div className="flex justify-center my-5">
            <div className="col-md-6 col-11">
              {progressBar}
              {isIndividualUser ? (
                <IndividualInfoScreen
                  progressBar={progressBar}
                  signUpData={signUpData}
                  user={user}
                  jobListing={jobListing}
                  t={t}
                  dispatch={dispatch}
                  Yup={Yup}
                  setActiveStep={setActiveStep}
                  errorsState={errorsState}
                  setprogressBarData={setprogressBarData}
                />
              ) : (
                <CompanyActivityRevenue
                  t={t}
                  signUpData={signUpData}
                  user={user}
                  jobListing={jobListing}
                  dispatch={dispatch}
                  Yup={Yup}
                  errorsState={errorsState}
                  setActiveStep={setActiveStep}
                  setprogressBarData={setprogressBarData}
                  revenueOptions={compnay_revenue_levels}
                />
              )}
            </div>
          </div>
        );
      case 3:
        return (
          <CompanyDocumentsScreen
            progressBar={progressBar}
            t={t}
            user={user}
            dispatch={dispatch}
            setprogressBarData={setprogressBarData}
            setActiveStep={setActiveStep}
            finish={finish}
          />
        );
      case "companyEnvisionedCountries":
        return (
          <div className="flex justify-center my-5">
            <div className="col-md-6 col-11">
              {progressBar}
              <CompanyEnvisionedCountries
                t={t}
                countryListing={countryListing}
                dispatch={dispatch}
                Yup={Yup}
                country={country}
                user={user}
                setErrorsState={setErrorsState}
                signUpData={signUpData}
                setprogressBarData={setprogressBarData}
                setActiveStep={setActiveStep}
                companySendingEnvisionedCountries={
                  companySendingEnvisionedCountries
                }
                setcompanyReceivingEnvisionedCountries={
                  setcompanyReceivingEnvisionedCountries
                }
                setcompanySendingEnvisionedCountries={
                  setcompanySendingEnvisionedCountries
                }
                companyReceivingEnvisionedCountries={
                  companyReceivingEnvisionedCountries
                }
              />
            </div>
          </div>
        );
      case 4:
        return (
          <div className="flex flex-col items-center justify-center mx-auto my-5">
            <div className="col-11 col-md-6">{progressBar}</div>
            <div className="p-8 max-sm:!px-4 mb-5 text-center bg-white rounded-lg shadow-lg col-md-8 col-12">
              <SumSub
                isModal={false}
                isKYB={!isIndividualUser}
                phone={phone}
                residencyLevelName={residencyLevelName}
                finish={finish}
                back={() => {
                  if (isIndividualUser) {
                    if (signUpData?.residency == "WAEMU/CEMAC Person") {
                      setActiveStep("individualTransferInfo");
                      setprogressBarData({
                        progress: 60,
                        title: "receiving_countries",
                      });
                    } else {
                      setprogressBarData({
                        progress: 80,
                        title: "residency_details",
                      });
                      setActiveStep("residenceSelection");
                    }
                  } else {
                    setprogressBarData({
                      progress: 75,
                      title: "envisioned_countries",
                    });
                    setActiveStep("companyEnvisionedCountries");
                  }
                }}
              />
            </div>
          </div>
        );
      default:
        return "Unknown stepIndex";
    }
  };

  const handleLogout = () => {
    dispatch(resetAuth());
    dispatch(resetTransfer());
    dispatch(resetDeveloper());
    customerIoTrack({
      name: "Regsitration Unfinished In 20 Mins",
      data: { ...registrationFailData, abandoned: true },
    });
    localStorage.removeItem("depositattempts");
    localStorage.removeItem("maketransferattempts");
    history.push("/login");
  };

  useEffect(() => {
    //check user country to set the country for the company/ if you remove this if statement, the country input will be empty
    if (user) {
      setCountryName(user?.country?.toLowerCase());
    }
  }, [user]);

  useLayoutEffect(() => {
    get_external_user_country();
    if (user) {
      setPhone(user?.phone_number);
      setCountry(user?.country_code);
    }
  }, [user]);

  // dont allow verified users to edit info
  useLayoutEffect(() => {
    if (user && hasUserCompletedRegistration(user)) {
      const access_token = localStorage.getItem("user:key");
      if (activeStep == 0) {
        if (access_token) {
          history.push("/dashboard/home");
        } else {
          history.push("/login");
        }
      }
    }
  }, []);

  // according to url prarms screen title setting
  const handleRedirectProgressBarData = (stage: any, usertype: any) => {
    if (stage == 1) {
      setprogressBarData({
        progress: 25,
        title:
          usertype == "Individual"
            ? "personal_information"
            : "contact_information",
      });
    } else if (stage == 2) {
      setprogressBarData({
        progress: usertype == "Individual" ? 40 : 50,
        title:
          usertype == "Individual"
            ? "revenue_information"
            : "Company_Information",
      });
    } else if (stage == "individualTransferInfo") {
      setprogressBarData({
        progress: 60,
        title: "receiving_countries",
      });
    } else if (stage == "residenceSelection") {
      setprogressBarData({
        progress: 80,
        title: "residency_details",
      });
    } else if (stage == 4) {
      const levelname = window.localStorage.getItem("residencyLevel");
      if (levelname) {
        setresidencyLevelName(levelname);
      }
      setprogressBarData({
        progress: 100,
        title: "Documents",
      });
    } else if (stage == "companyEnvisionedCountries") {
      setprogressBarData({
        progress: 75,
        title: "envisioned_countries",
      });
    }
  };

  // handle screens according to url params redirection
  useEffect(() => {
    try {
      const id: any = new URLSearchParams(search).get("page");
      const usertype: any =
        new URLSearchParams(search).get("usertype") ||
        signUpData?.usertype ||
        window.localStorage.getItem("acctype");

      if (isNaN(id)) {
        setActiveStep(id);
      } else {
        if (!Array.isArray(user?.meta) || !Array.isArray(signUpData?.meta)) {
          setdiscoverySubStep(2);
        }
        setActiveStep(Number(id));
      }
      handleRedirectProgressBarData(id, usertype);

      if (isValidData(usertype)) {
        settypeOfUser((prev: any) =>
          prev.map((item: any) =>
            item.value.toLowerCase() == usertype.toLowerCase()
              ? { ...item, active: true }
              : { ...item, active: false }
          )
        );
        if (usertype == "Individual" || usertype == "individual") {
          setIsIndividualUser(true);
        } else {
          setIsIndividualUser(false);
        }
      }

      get_external_user_country();
      fetchImportantData();
      if (user) {
        setPhone(user?.phone_number);
        setCountry(user?.country_code);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, []);

  return (
    <main style={styles.main}>
      <div className="flex flex-row items-center justify-between px-4 py-2 mb-3 shadow-md">
        <LangSwitch />
        <button
          className="grid items-center px-0 py-2 btn"
          onClick={handleLogout}
        >
          <i
            className="m-0 fa fa-sign-out"
            style={{ fontSize: 23, color: "rgb(3, 115, 117)" }}
          />
        </button>
      </div>

      <div>
        {activeStep === steps.length ? (
          <div className="flex flex-col justify-center h-full">
            <h1 className="mb-4 text-3xl font-bold text-center">Thank You!</h1>
            <p className="ml-4 text-sm text-center">
              We will process your application and <br /> you will receive a
              confirmation email within 24 hours.
            </p>
          </div>
        ) : (
          <>
            {getStepContent(
              activeStep,
              <SignUpProgressBar
                progress={progressBarData.progress}
                label={progressBarData.title}
                t={t}
              />
            )}
          </>
        )}
      </div>
    </main>
  );
};

export default SignUp;
