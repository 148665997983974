import CashLayout from "../CashLayout";
import { CircularProgress } from "@material-ui/core";
import InfoMessage from "../InfoMessage";

const TransferInfo = (props: any) => {
  const {
    amountInEuros,
    fees,
    fetchingFees,
    withdrawalFees,
    t,
    spread_config,
    paymentMethod,
    transfer,
    widthdrawMethod,
    hasUserReachedPaymentPage = true,
    showWithdrawMethod = true,
    isBulkTransfer = false,
    noWithdrawMethodsFound = false,
  } = props;
  return (
    <div className="flex gap-2 p-4 max-sm:!px-3 flex-column col-md-6">
      {noWithdrawMethodsFound && (
        <InfoMessage message={"no_withdraw_modes"} extraClasses="!mt-0" />
      )}

      <div className="flex flex-row items-center justify-between my-2">
        <small>{t("amount")}</small>
        <CashLayout
          cash={amountInEuros === "" ? 0 : parseFloat(amountInEuros).toFixed(3)}
        />
      </div>

      {hasUserReachedPaymentPage && (
        <div className="flex flex-row justify-between my-2">
          <small>{t("TransferFees")}</small>
          {fetchingFees ? (
            <CircularProgress size={14} />
          ) : (
            <small>
              <CashLayout cash={fees} />
            </small>
          )}
        </div>
      )}
      {hasUserReachedPaymentPage && showWithdrawMethod && (
        <div className="flex flex-row justify-between my-2">
          <small>{t("WithdrwalFees")}</small>
          {fetchingFees ? (
            <CircularProgress size={14} />
          ) : (
            <small>
              <CashLayout cash={withdrawalFees} />
            </small>
          )}
        </div>
      )}

      {hasUserReachedPaymentPage && (
        <div
          className="flex flex-row justify-between my-2 "
          style={{ color: "rgb(3, 115, 117)" }}
        >
          <small>{t("totalSent")}</small>
          <CashLayout cash={+amountInEuros + fees + withdrawalFees} />
        </div>
      )}

      <div className="flex flex-row justify-between my-2">
        <small>{t("exchange_rate")}</small>
        <b className="font-bold" style={{ fontSize: 13 }}>
          1 EUR = {spread_config.exchange_rate} CFA
        </b>
      </div>

      {hasUserReachedPaymentPage && (
        <div
          className="flex flex-row items-center justify-between my-2"
          style={{ color: "rgb(3, 115, 117)" }}
        >
          <small>{t("Total_Received")}</small>
          <CashLayout cash={spread_config.received_amount} isCfa={true} />
        </div>
      )}

      {/* {hasUserReachedPaymentPage && (
          <div
            className="flex flex-row items-center justify-between my-2"
            style={{ color: "rgb(3, 115, 117)" }}
          >
            <small>{t("bonus")}</small>
            <CashLayout cash={bonus} isCfa={true} />
          </div>
        )} */}

      {hasUserReachedPaymentPage && (
        <div className="flex flex-row justify-between my-2">
          <small>{t("PaymentMode")}</small>
          <b className="font-bold" style={{ fontSize: 13 }}>
            {t(paymentMethod?.toLowerCase().split(" ").join("_"))}
            {paymentMethod == "" && t("not_specified")}
          </b>
        </div>
      )}
      {paymentMethod === "bank_transfer" && !isBulkTransfer && (
        <div className="flex flex-row justify-between my-2">
          <small>{t("payment_reference_number")}</small>
          <b className="text-sm font-bold" style={{ fontSize: 13 }}>
            {transfer?.beneficiary?.payment_reference_number}
          </b>
        </div>
      )}
      {hasUserReachedPaymentPage && showWithdrawMethod && (
        <div className="flex flex-row justify-between my-2">
          <small>{t("WithdrawMode")}</small>
          <b className="font-bold" style={{ fontSize: 13 }}>
            {widthdrawMethod == "" ? t("not_specified") : t(widthdrawMethod)}
          </b>
        </div>
      )}
      {transfer?.transactionToRepeat
        ? transfer?.transactionToRepeat?.paymentMethod === "bank_transfer" &&
          !isBulkTransfer && (
            <div className="flex flex-row justify-between my-2">
              <small>{t("payment_reference_number")}</small>
              <b className="text-sm font-bold" style={{ fontSize: 13 }}>
                {transfer?.beneficiary?.payment_reference_number}
              </b>
            </div>
          )
        : paymentMethod?.split(" ")?.join("_") === "bank_transfer" &&
          !isBulkTransfer && (
            <div className="flex flex-row justify-between my-2">
              <small>{t("payment_reference_number")}</small>
              <b className="text-sm font-bold" style={{ fontSize: 13 }}>
                {transfer?.beneficiary?.payment_reference_number}
              </b>
            </div>
          )}
    </div>
  );
};

export default TransferInfo;
