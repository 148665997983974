import { useDispatch } from "react-redux";
import InputField from "../forms/InputField";
import * as yup from "yup";

import {
  addBeneficiary,
  addTransferCountry,
  setBeneFound,
} from "../../store/features/Transfer/TransferSlice";
import * as Sentry from "@sentry/browser";

import { toast } from "material-react-toastify";
import { buildString, isEmpty } from "../../utilities/help";
import {
  addContact,
  checkUserNameIfReal,
} from "../../store/features/Auth/Auth";
import CustomModal from "../CustomModal";
import { useMemo, useState } from "react";
import { Form, Formik } from "formik";

// make transfer add contact modal
const AddBeneficiary = (props: any) => {
  const {
    t,
    typeOfUser,
    handleTypeOfUserSelection,
    selectedContactType,
    setContactDetails,
    contacts_details,
    setCompanyContactDetails,
    company_contacts_details,
    loading,
    setPhone,
    setCountry,
    transfer,
    country,
    phone,
    setLoading,
    setEmailError,
  } = props;

  const dispatch = useDispatch();

  const removeBeneficiary = () => {
    dispatch(addBeneficiary(null));
    dispatch(addTransferCountry(null));
    dispatch(setBeneFound(false));
    setPhone("");
    setCountry({ dialCode: "" });
  };

  const [inHumanNamePopupOpen, setinHumanNamePopupOpen] = useState(false);
  const [creatingUser, setCreatingUser] = useState(false);

  const validationSchema = useMemo(() => {
    if (selectedContactType == "individual") {
      return yup.object().shape({
        last_name: yup.string().required("LNR"),
        first_name: yup.string().required("FNR"),
        email: yup.string().email("email_not_valid"),
      });
    } else {
      return yup.object().shape({
        company_name: yup.string().required("company_name_error"),
        last_name: yup.string().required("contact_last_name_error"),
        first_name: yup.string().required("contact_first_name_error"),
        contact_email: yup.string().email("email_not_valid").notRequired(),
      });
    }
  }, [selectedContactType]);

  const handleFormSubmit = (data: any, setSubmitting: any) => {
    try {
      setSubmitting(true);
      const contact_country = transfer.danaPayCountries.find(
        (val: any) => val.country_code === country["dialCode"]
      );

      if (!isEmpty(data.email)) {
        if (
          (contact_country?.preferred_notification_channel === "mail" &&
            !/^\S+@\S+\.\S+$/.test(data.email) &&
            selectedContactType == "individual") ||
          (contact_country?.preferred_notification_channel === "mail" &&
            !/^\S+@\S+\.\S+$/.test(data.email) &&
            selectedContactType != "individual")
        ) {
          toast.error(t("email_not_valid"));
          setSubmitting(false);
          return;
        }
      }

      let payload_data: any;
      if (selectedContactType == "individual") {
        payload_data = {
          ...data,
          is_individual: true,
          country_code: country["dialCode"],
          phone_number: buildString(phone) || "",
        };
      } else {
        payload_data = {
          ...data,
          is_individual: false,
          country_code: country["dialCode"],
          phone_number: buildString(phone) || "",
        };
      }
      if (!payload_data["email"]) {
        delete payload_data["email"];
      }

      addContact(payload_data)
        .then((data: any) => {
          dispatch(setBeneFound(true));
          dispatch(addBeneficiary(data.beneficiary));
          dispatch(addTransferCountry(data.transferCountry));
        })
        .catch((error: any) => {
          Sentry.captureException(error);
          if (error.status === 422 || error.status === 500) {
            const err: any = Object.values(error.data.errors)[0];
            toast.error(t(err[0]));
          }
        });
    } catch (error) {
      setSubmitting(false);
      Sentry.captureException(error);
    }
  };

  const handleUserCreateSubmission = async (data: any, setSubmitting: any) => {
    try {
      setSubmitting(true);

      // check for valid username
      setCreatingUser(true);
      const full_name = data?.first_name + " " + data?.last_name;
      checkUserNameIfReal({ full_name })
        .then((response: any) => {
          if (response.isRealName) {
            setinHumanNamePopupOpen(false);
            handleFormSubmit(data, setSubmitting);
          } else {
            setinHumanNamePopupOpen(true);
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          setinHumanNamePopupOpen(false);
          setSubmitting(false);
          setCreatingUser(false);
          toast.error(t(error?.data?.message));
        });
    } catch (error: any) {
      toast.error(t(error));
      setCreatingUser(false);
      setSubmitting(false);
    }
  };

  return (
    <div className="shadow-lg p-4 mb-4 col-md-4 rounded-lg row mx-auto overflow-y-scroll">
      <div className="flex flex-col mb-1 rounded-lg items-center justify-center">
        <h3 className="text-2xl font-bold">{t("beneficiary_details")}</h3>
      </div>
      {/* <div className="row flex justify-center">
      <div className="shadow-lg p-4 col-md-6">
        <div className="flex flex-col mb-1 rounded-lg items-center justify-center">
          <h3 className="text-2xl font-bold">{t("beneficiary_details")}</h3>
        </div> */}

      <small className="block !text-center text-gray-400">
        {t("new_contact")}
      </small>

      {/*  new ui */}
      <label className="mt-3">
        <small>{t("Account_Type")}</small>
      </label>
      <div className="bacnkCheckBoxWrapper bacnkCheckBoxWrapper flex flex-col gap-2 p-2">
        {typeOfUser.map((usertype: any, index: any) => {
          return (
            <div
              key={index}
              className="wrapperBankCheckBox m-0 h-[50px] innerBankBox"
            >
              <div className="wrapperBankCheckBoxwrapper h-[30px]">
                <input
                  type="checkbox"
                  className="default:ring-2 m-0 p-0"
                  name={usertype.value}
                  onChange={(e: any) => {
                    handleTypeOfUserSelection(usertype.value);
                  }}
                  checked={usertype.active}
                />
                <span className="checkmark"></span>
              </div>
              <label>{t(usertype?.title)}</label>
            </div>
          );
        })}
      </div>

      <Formik
        validateOnMount
        enableReinitialize
        initialValues={
          selectedContactType == "individual"
            ? {
                first_name: "",
                last_name: "",
                email: "",
              }
            : {
                company_name: "",
                first_name: "",
                last_name: "",
                email: "",
              }
        }
        onSubmit={async (data: any, { setSubmitting }) => {
          handleUserCreateSubmission(data, setSubmitting);
        }}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
          isValid,
          handleSubmit,
          isSubmitting,
          setSubmitting,
        }) => (
          <Form>
            {/* company menu */}
            {selectedContactType == "business" && (
              <InputField
                extraClasses="mb-3 customInputStyle"
                name="company_name"
                handleChange={handleChange}
                placeholder={t("CompanyName")}
                value={values.company_name}
                label={t("CompanyName")}
                onBlur={handleBlur}
                error={t(`${errors.company_name}`)}
                touched={touched.company_name}
              />
            )}

            {/* individual menu */}

            <>
              <InputField
                extraClasses="mb-3 customInputStyle"
                name="first_name"
                handleChange={handleChange}
                placeholder={t("FirstName")}
                value={values.first_name}
                label={
                  selectedContactType == "individual"
                    ? t("FirstName")
                    : t("contact_first_name")
                }
                onBlur={handleBlur}
                error={t(`${errors.first_name}`)}
                touched={touched.first_name}
              />
              <InputField
                extraClasses="mb-3 customInputStyle"
                name="last_name"
                handleChange={handleChange}
                placeholder={
                  selectedContactType == "individual"
                    ? t("LastName")
                    : t("contact_last_name")
                }
                value={values.last_name}
                label={
                  selectedContactType == "individual"
                    ? t("LastName")
                    : t("contact_last_name")
                }
                onBlur={handleBlur}
                error={t(errors.last_name)}
                touched={touched.last_name}
              />

              <InputField
                extraClasses="mb-3 customInputStyle"
                name="email"
                handleChange={handleChange}
                placeholder={t("email")}
                value={values.email}
                label={t("Email")}
                onBlur={handleBlur}
                error={t(`${errors.email}`)}
                touched={touched.email}
              />
            </>

            <CustomModal
              open={inHumanNamePopupOpen}
              containerClasses={
                "w-full h-full flex justify-center text-center items-center"
              }
              modalClasses={
                "bg-white m-auto p-4 rounded col-md-6 col-lg-5 col-11"
              }
              titleClasses={"font-bold text-xl max-sm:text-lg mt-2 mb-4"}
              title={t("inhuman_name_error")}
              contentClasses={"mt-4"}
              onBack={() => {
                setinHumanNamePopupOpen(false);
                setSubmitting(false);
                setCreatingUser(false);
              }}
              onNext={() => {
                handleFormSubmit(values, setSubmitting);
                setinHumanNamePopupOpen(false);
              }}
              onBackTitle={"go_back_and_change"}
              onNextTitle={"proceed"}
              buttonContainerClasses={"flex gap-3"}
              backBtnClasses={"bg-white !text-black border"}
            />

            {isSubmitting ? (
              <button
                className="w-full my-4 rounded-lg btn btn-dark"
                disabled
                style={{ backgroundColor: "rgb(3, 115, 117)" }}
              >
                <span className="text-white capitalize">{t("loading")}...</span>
              </button>
            ) : (
              <div className="row">
                <div className="col-md-6">
                  <button
                    onClick={() => removeBeneficiary()}
                    className="w-full px-10 mt-6 rounded-lg btn btn-dark text-white capitalize"
                  >
                    {t("cancel")}
                  </button>
                </div>
                <div className="col-md-6">
                  <button
                    onClick={() => handleSubmit()}
                    disabled={!isValid || creatingUser || isSubmitting}
                    className="w-full px-10 mt-6 rounded-lg btn text-white capitalize"
                    style={{ backgroundColor: "rgb(3, 115, 117)" }}
                  >
                    {t("next")}
                  </button>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddBeneficiary;
