import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "../../helpers/i18n";
import Avatar from "react-avatar";
import Country_comp from "../Country_comp";
import { CircularProgress, Typography } from "@material-ui/core";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { useDispatch } from "react-redux";
import * as Sentry from "@sentry/browser";

import {
  deleteFavouriteUser,
  getFavorites,
} from "../../store/features/Auth/Auth";
import { toast } from "material-react-toastify";
import { saveUserFavorites } from "../../store/features/Auth/AuthSlice";
import {
  setBeneFound,
  setStep,
  setTLError,
  setTransactionToRepeat,
  setTransferDialogOpen,
} from "../../store/features/Transfer/TransferSlice";

const BeneficiaryCard: React.FC<{ beneficiary: any }> = ({ beneficiary }) => {
  const { t } = useTranslation();
  const [isDeletingUser, setisDeletingUser] = useState(false);
  const dispatch = useDispatch();

  const onContactDelete = () => {
    setisDeletingUser(true);
    deleteFavouriteUser(beneficiary.id)
      .then((res: any) => {
        dispatch(setBeneFound(false));
        dispatch(setStep(0));
        dispatch(setTLError(null));
        dispatch(setTransactionToRepeat(null));
        dispatch(setTransferDialogOpen(false));
        setTimeout(() => {
          toast.success(t("remove_favourite_success"));
          getFavorites().then((response: any) => {
            dispatch(saveUserFavorites(response));
          });
        }, 1000);
      })
      .catch((error: any) => {
        Sentry.captureException(error);
        toast.error(t("verification_email_error"));
      })
      .finally(() => setisDeletingUser(false));
  };

  return (
    <div className="p-2 my-4 rounded-lg shadow-lg">
      {beneficiary?.is_individual || beneficiary.company === null ? (
        <>
          <div className="p-3 row">
            <div className="flex flex-row gap-3 items-center pb-3 mb-3 border-b-2 col-md-12">
              <div className="flex-center">
                <Avatar
                  name={`${beneficiary.first_name} ${beneficiary.last_name}`}
                  size={`50`}
                  round
                />
                <div className="ml-2">
                  <p className="font-bold">{`${beneficiary.first_name} ${beneficiary.last_name}`}</p>
                  <Country_comp eng_country_name={beneficiary.country} />
                </div>
              </div>

              {/* delete btn */}
              <div
                className="ml-auto flex cursor-pointer gap-1"
                onClick={onContactDelete}
              >
                {isDeletingUser ? (
                  <p className="flex mr-1 justify-center items-center">
                    <CircularProgress size={14} />
                  </p>
                ) : (
                  <>
                    <DeleteForeverOutlinedIcon className="mr-1" />
                  </>
                )}
                <Typography className="max-sm:hidden" color="textSecondary">
                  {t("remove")}
                </Typography>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <small className="font-bold text-[#037375]">Type</small>
                <br />
                <small>{t("Individual")}</small>
              </div>
              <div className="mb-3">
                <small className="font-bold text-[#037375]">Address</small>
                <br />
                <Country_comp eng_country_name={beneficiary.country} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <small className="font-bold text-[#037375]">
                  Contact Email
                </small>
                <br />
                <small>{beneficiary?.email}</small>
              </div>
            </div>
            <div className="flex items-center justify-center p-3 col-md-12">
              <small className="text-center text-red-500">
                {t("NotReceiver")}
              </small>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="p-3 row">
            <div className="flex flex-row items-center pb-3 mb-3 border-b-2 col-md-12">
              <div className="flex-center">
                <Avatar
                  name={`${beneficiary.first_name} ${beneficiary.last_name}`}
                  size={`50`}
                  round
                />
                <div className="ml-2">
                  <h5 className="font-bold">{`${beneficiary.first_name} ${beneficiary.last_name}`}</h5>
                  <small className="text-gray-500">
                    {beneficiary?.company?.name}
                  </small>
                </div>
              </div>

              {/* delete btn */}
              <div
                className="ml-auto flex cursor-pointer gap-1"
                onClick={onContactDelete}
              >
                {isDeletingUser ? (
                  <p className="flex mr-1 justify-center items-center">
                    <CircularProgress size={14} />
                  </p>
                ) : (
                  <>
                    <DeleteForeverOutlinedIcon className="mr-1" />
                  </>
                )}
                <Typography className="max-sm:hidden" color="textSecondary">
                  Remove
                </Typography>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <small className="font-bold text-[#037375]">Type</small>
                <br />

                <small>{t("company")}</small>
              </div>
              <div className="mb-3">
                <small className="font-bold text-[#037375]">
                  {t("registration_id")}
                </small>
                <br />

                <small>{beneficiary?.company?.registered_id}</small>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <small className="font-bold text-[#037375]">
                  Contact Email
                </small>
                <br />

                <small>{beneficiary?.email}</small>
              </div>

              <div className="mb-3">
                <small className="font-bold text-[#037375]">Address</small>
                <br />
                <small className="capitalize">
                  {beneficiary?.company?.city.toLowerCase()},
                </small>{" "}
                <Country_comp
                  eng_country_name={beneficiary?.company?.country}
                />
              </div>
            </div>

            <div className="flex items-center justify-center p-2 col-md-12">
              <small className="text-red-500">{t("NotReceiver")}</small>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BeneficiaryCard;
