export const countries_in_french: any = {
  afghanistan: "Afghanistan",
  "south africa": "Afrique du Sud",
  albania: "Albanie",
  algeria: "Algérie",
  germany: "Allemagne",
  andorra: "Andorre",
  angola: "Angola",
  "antigua and barbuda": "Antigua-et-Barbuda",
  "saudi arabia": "Arabie saoudite",
  argentina: "Argentine",
  armenia: "Arménie",
  australia: "Australie",
  austria: "Autriche",
  azerbaijan: "Azerbaïdjan",
  bahamas: "Bahamas",
  bahrain: "Bahreïn",
  bangladesh: "Bangladesh",
  barbados: "Barbade",
  belarus: "Biélorussie",
  belgium: "Belgique",
  belize: "Belize",
  benin: "Bénin",
  bhutan: "Bhoutan",
  "bolivia (plurinational state of)": "Bolivie",
  "bosnia and herzegovina": "Bosnie-Herzégovine",
  botswana: "Botswana",
  brazil: "Brésil",
  "brunei darussalam": "Brunei",
  bulgaria: "Bulgarie",
  "burkina faso": "Burkina Faso",
  burundi: "Burundi",
  cambodia: "Cambodge",
  cameroon: "Cameroun",
  canada: "Canada",
  "cabo verde": "Cap-Vert",
  "central african republic": "République centrafricaine",
  chile: "Chili",
  china: "Chine",
  guadeloupe:"Guadeloupe",
  cyprus: "Chypre",
  colombia: "Colombie",
  comoros: "Comores",
  congo: "République du Congo",
  "congo, democratic republic of the": "République démocratique du Congo",
  "korea, republic of": "Corée du Sud",
  "korea (democratic people's republic of)": "Corée du Nord",
  "costa rica": "Costa Rica",
  "ivory coast": "Côte d'Ivoire",
  croatia: "Croatie",
  cuba: "Cuba",
  denmark: "Danemark",
  djibouti: "Djibouti",
  "dominican republic": "République dominicaine",
  dominica: "Dominique",
  egypt: "Égypte",
  "el salvador": "Salvador",
  "united arab emirates": "Émirats arabes unis",
  ecuador: "Équateur",
  eritrea: "Érythrée",
  spain: "Espagne",
  estonia: "Estonie",
  "united states of america": "États-Unis",
  ethiopia: "Éthiopie",
  fiji: "Fidji",
  finland: "Finlande",
  france: "France",
  gabon: "Gabon",
  gambia: "Gambie",
  georgia: "Géorgie",
  ghana: "Ghana",
  greece: "Grèce",
  grenada: "Grenade",
  guatemala: "Guatemala",
  guinea: "Guinée",
  "guinea-bissau": "Guinée-Bissau",
  "equatorial guinea": "Guinée équatoriale",
  guyana: "Guyana",
  haiti: "Haïti",
  honduras: "Honduras",
  hungary: "Hongrie",
  india: "Inde",
  indonesia: "Indonésie",
  "iran (islamic republic of)": "Iran",
  iraq: "Irak",
  ireland: "Irlande",
  iceland: "Islande",
  israel: "Israël",
  italy: "Italie",
  jamaica: "Jamaïque",
  japan: "Japon",
  jordan: "Jordanie",
  kazakhstan: "Kazakhstan",
  kenya: "Kenya",
  kyrgyzstan: "Kirghizistan",
  kiribati: "Kiribati",
  kuwait: "Koweït",
  "lao people's democratic republic": "Laos",
  lesotho: "Lesotho",
  latvia: "Lettonie",
  lebanon: "Liban",
  liberia: "Liberia",
  libya: "Libye",
  liechtenstein: "Liechtenstein",
  lithuania: "Lituanie",
  luxembourg: "Luxembourg",
  "north macedonia": "Macédoine du Nord",
  madagascar: "Madagascar",
  malaysia: "Malaisie",
  malawi: "Malawi",
  maldives: "Maldives",
  mali: "Mali",
  malta: "Malte",
  morocco: "Maroc",
  "marshall islands": "Îles Marshall",
  mauritius: "Maurice",
  mauritania: "Mauritanie",
  mexico: "Mexique",
  "micronesia (federated states of)": "États fédérés de Micronésie",
  "moldova, republic of": "Moldavie",
  monaco: "Monaco",
  mongolia: "Mongolie",
  montenegro: "Monténégro",
  mozambique: "Mozambique",
  myanmar: "Birmanie",
  namibia: "Namibie",
  nauru: "Nauru",
  nepal: "Népal",
  nicaragua: "Nicaragua",
  niger: "Niger",
  nigeria: "Nigeria",
  norway: "Norvège",
  "new zealand": "Nouvelle-Zélande",
  oman: "Oman",
  uganda: "Ouganda",
  uzbekistan: "Ouzbékistan",
  pakistan: "Pakistan",
  palau: "Palaos",
  panama: "Panama",
  "papua new guinea": "Papouasie-Nouvelle-Guinée",
  paraguay: "Paraguay",
  netherlands: "Pays-Bas",
  peru: "Pérou",
  philippines: "Philippines",
  poland: "Pologne",
  portugal: "Portugal",
  qatar: "Qatar",
  romania: "Roumanie",
  "united kingdom of great britain and northern ireland": "Royaume-Uni",
  "russian federation": "Russie",
  rwanda: "Rwanda",
  "saint kitts and nevis": "Saint-Christophe-et-Niévès",
  "san marino": "Saint-Marin",
  "saint vincent and the grenadines": "Saint-Vincent-et-les-Grenadines",
  "saint lucia": "Sainte-Lucie",
  "solomon islands": "Îles Salomon",
  samoa: "Samoa",
  "sao tome and principe": "Sao Tomé-et-Principe",
  senegal: "Sénégal",
  serbia: "Serbie",
  seychelles: "Seychelles",
  "sierra leone": "Sierra Leone",
  singapore: "Singapour",
  slovakia: "Slovaquie",
  slovenia: "Slovénie",
  somalia: "Somalie",
  sudan: "Soudan",
  "south sudan": "Soudan du Sud",
  "sri lanka": "Sri Lanka",
  sweden: "Suède",
  switzerland: "Suisse",
  suriname: "Suriname",
  eswatini: "Eswatini",
  "syrian arab republic": "Syrie",
  tajikistan: "Tadjikistan",
  "tanzania, united republic of": "Tanzanie",
  chad: "Tchad",
  czechia: "Tchéquie",
  thailand: "Thaïlande",
  "timor-leste": "Timor oriental",
  togo: "Togo",
  tonga: "Tonga",
  "trinidad and tobago": "Trinité-et-Tobago",
  tunisia: "Tunisie",
  turkmenistan: "Turkménistan",
  türkiye: "Turquie",
  tuvalu: "Tuvalu",
  ukraine: "Ukraine",
  uruguay: "Uruguay",
  vanuatu: "Vanuatu",
  "venezuela (bolivarian republic of)": "Venezuela",
  "viet nam": "Viêt Nam",
  yemen: "Yémen",
  zambia: "Zambie",
  zimbabwe: "Zimbabwe",
};
