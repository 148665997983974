import { toast } from 'material-react-toastify';
import React from 'react';
import Dropzone from 'react-dropzone';

const FileUpload = (props: any) => {
    let { multiple, onDrop, setRef, accept } = props;
    return (
        <div className="dropzoneCustom">
            <Dropzone accept={accept} multiple={multiple !== undefined ? multiple : false} onDrop={acceptedFiles => { onDrop && onDrop(acceptedFiles); }} ref={setRef}>
                {({ getRootProps, getInputProps }) => {
                    return <div className="txtDropzone" {...getRootProps()}>
                        <input {...getInputProps()} />
                        {props.children}
                    </div>
                }}
            </Dropzone>
        </div>
    )
}

export default FileUpload;