import { Modal } from "@material-ui/core";
import InputField from "../forms/InputField";
import { useEffect, useState } from "react";
import moment from "moment";
import { toast } from "material-react-toastify";
import * as Sentry from "@sentry/browser";
import CheckBoxListingComponent from "../CheckBoxListingComponent";

const list = [
  {
    id: 1,
    name: "SMS",
    active: true,
  },
  {
    id: 2,
    name: "Whatsapp",
    active: false,
  },
];

const OtpModal = (props: any) => {
  const {
    open,
    onClose,
    t,
    otp,
    setotp,
    onSubmit,
    hasResend,
    onResend,
    loading = false,
    showChannel = false,
    showOtpField = true,
    showCustomDescription = false,
    customDescription,
    formhandleChange = () => {},
    submitText = "submit",
    setSubmitting = () => {},
  } = props;

  // resend logic
  const [disableResend, setdisableResend] = useState(false);
  const [seconds, setseconds] = useState(30);
  const [isTimerRunning, setisTimerRunning] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 1) {
        isTimerRunning && setseconds(seconds - 1);
      }

      if (seconds === 1) {
        setdisableResend(false);
        setisTimerRunning(false);
        clearInterval(interval);
        isTimerRunning && setseconds(30);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const handleOTPAbuse = (successCallback: any) => {
    try {
      if (window.localStorage.getItem("otpresendtime") == undefined) {
        const payload = { times: 1, time: moment() };
        window.localStorage.setItem("otpresendtime", JSON.stringify(payload));
        successCallback();
      } else {
        const oldPayload = JSON.parse(
          window.localStorage.getItem("otpresendtime")!
        );

        const isTimeLessThanTwoMin =
          moment.duration(moment().diff(moment(oldPayload.time))).minutes() < 2;

        if (parseInt(oldPayload.times) >= 5 && isTimeLessThanTwoMin) {
          toast.error(t("otp_limit_error"));
          setTimeout(() => {
            window.localStorage.removeItem("otpresendtime");
          }, 2 * 60 * 1000);
        } else {
          const payload = { ...oldPayload, times: oldPayload.times + 1 };
          window.localStorage.setItem("otpresendtime", JSON.stringify(payload));
          successCallback();
        }
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const startTimer = () => {
    const successCallback = () => {
      if (!disableResend) {
        setisTimerRunning(true);
        setdisableResend(true);
        onResend && onResend();
        setseconds(30);
      }
    };
    handleOTPAbuse(successCallback);
  };

  const handleChange = (e: any) => {
    setotp(e.target.value);
  };

  // channel
  const [channelList, setChannelList] = useState(list);

  const handleChannelChange = (e: any, channelId: any) => {
    try {
      setChannelList((prev: any) =>
        prev.map((channelItem: any) => {
          if (channelItem.id == channelId) {
            formhandleChange("channel")(channelItem.name);
            return { ...channelItem, active: true };
          } else {
            return { ...channelItem, active: false };
          }
        })
      );
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className="m-auto h-full overflow-y-scroll justify-center flex items-center row">
        <div className="p-3 bg-white flex flex-col rounded-md col-11 col-sm-8 col-md-6 col-lg-4  max-w-[500px]">
          <div className="flex flex-row items-center justify-between mb-2">
            <div>
              <b>{t("OTP_Verification")}</b>
            </div>
            <button
              onClick={onClose}
              style={{ height: 40, width: 40, borderRadius: 20 }}
              className="flex items-center justify-center shadow-lg"
            >
              <i className="m-0 fa fa-close"></i>
            </button>
          </div>
          <hr className="mb-2" />
          <div className="mb-4">
            {showCustomDescription ? customDescription : t("phone_verify_text")}
          </div>

          {/* channel */}
          {showChannel && (
            <>
              <label className="mb-1 text-gray-600 text-[12.6px]">
                {t("select_channel")}
              </label>
              <CheckBoxListingComponent
                list={channelList}
                onChange={handleChannelChange}
              />
            </>
          )}
          {showOtpField && (
            <InputField
              extraClasses="mb-6 customInputStyle"
              name="otp"
              type="number"
              handleChange={handleChange}
              placeholder={t("OTP")}
              value={otp}
              label={t("OTP")}
              error={otp.length == 6 ? "" : loading ? "" : t("otp_invalid")}
            />
          )}

          <div className="flex items-center justify-between gap-3 mt-2">
            <button
              style={{ backgroundColor: "rgb(3, 115, 117)" }}
              type="submit"
              disabled={
                (/^(\d){6}$/.test(otp) == false && showOtpField) || loading
              }
              className="px-10 w-full rounded-lg btn text-white capitalize"
              onClick={onSubmit}
            >
              {t(submitText)}
            </button>
          </div>
          {hasResend && (
            <button
              className={`my-2 !outline-none cursor-pointer text-[#009a79] text-center focus:underline focus-within:underline ${
                disableResend ? "opacity-50" : ""
              }`}
              onClick={() => {
                !disableResend && startTimer();
              }}
            >
              {disableResend
                ? t("resend_in") + seconds + " seconds"
                : t("send_the_code_again")}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default OtpModal;
