import { CircularProgress } from "@material-ui/core";
import { updateBulkTData } from "../../store/features/Transfer/TransferSlice";
import { calculateSumFromArray, isEmpty } from "../../utilities/help";
import BeneficiarySummary from "../BeneficiarySumary";
import CashLayout from "../CashLayout";
import InputField from "../forms/InputField";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import TransferInfo from "../TransferPaymentWithdrawModal/TransferInfo";

const BulkPaymentModal = (props: any) => {
  const {
    transfer,
    fileCSV,
    euroBalance,
    handleCashMethod,
    payMethod,
    setActiveStep,
    selectedCashInReset,
    submitRequest,
    processingPayment,
    fetchingFees,
    fees,
    spread_config,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <div className="items-center justify-center w-full h-full">
      <div className="row">
        <div className="my-10 col-md-10 mx-auto">
          <div className="shadow-lg row">
            <div className="flex flex-row justify-between border-b-2 col-md-12">
              <div className="flex-1">
                <BeneficiarySummary
                  name={`${
                    Object.keys(transfer.usersForBulkyTransfer).length ||
                    fileCSV?.data === undefined
                      ? fileCSV?.length
                      : fileCSV?.data?.length
                  } ${t("People_selected")}`}
                  company={transfer.bulkTransferData.country}
                />
              </div>

              <div className="flex flex-col items-end justify-center flex-1 w-1/3">
                <small>{t("Balance")}</small>
                <CashLayout cash={euroBalance} size="text-sm" />
              </div>
            </div>

            <div className="p-4 col-md-6">
              <InputField
                extraClasses="bankNameDropDown mb-3"
                handleChange={(text: any) =>
                  dispatch(updateBulkTData({ reason: text.target.value }))
                }
                value={transfer?.bulkTransferData?.reason}
                placeholder={t("reason")}
                label={t("reason")}
                type="text"
                name="reason"
              />

              {/* cash in methods */}
              <div className="mb-3">
                <label className="withdrawTitle">
                  {t("selectCashinMethod")}
                </label>
                <div className="bacnkCheckBoxWrapper flex flex-col gap-2 p-0 m-0 p-2 my-2">
                  {transfer.loggedInUserCountry?.cash_in_methods?.map(
                    (value: any, index: any) => {
                      return (
                        <div className="wrapperBankCheckBox innerBankBox m-0">
                          <div className="wrapperBankCheckBoxwrapper">
                            <input
                              type="checkbox"
                              className="default:ring-2"
                              onChange={(e: any) =>
                                handleCashMethod(
                                  e.target.checked,
                                  value.cash_in_method?.name.toLowerCase(),
                                  false
                                )
                              }
                              name={value?.cash_in_method?.name}
                              checked={
                                selectedCashInReset === true
                                  ? t(
                                      value?.cash_in_method?.name
                                        ?.toLowerCase()
                                        .split(" ")
                                        .join("_")
                                    ) ===
                                    t(payMethod?.toLowerCase())
                                      .substring(0, 1)
                                      .toUpperCase() +
                                      t(payMethod?.toLowerCase())
                                        .substring(1)
                                        .toLowerCase()
                                        .replaceAll("_", " ")
                                  : value.cash_in_method?.name.toLowerCase() ===
                                    payMethod
                              }
                            />
                            <span className="checkmark"></span>
                          </div>
                          <label>
                            {t(
                              value?.cash_in_method?.name
                                ?.toLowerCase()
                                .split(" ")
                                .join("_")
                            )}
                          </label>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>

              <div className="max-sm:!px-3 flex gap-3 max-sm:flex-col">
                <button
                  onClick={() => setActiveStep((prev: any) => prev - 1)}
                  className="w-full rounded-lg btn btn-dark btn-block"
                >
                  {t("back")}
                </button>
                <button
                  id="bulk-payment-initiation"
                  onClick={() => submitRequest(true)}
                  className="w-full rounded-lg btn btn-block text-white capitalize"
                  style={{ backgroundColor: "rgb(3, 115, 117)" }}
                  disabled={processingPayment || fetchingFees}
                >
                  {processingPayment ? t("processing") : t("continue")}
                </button>
              </div>
            </div>

            {/* transfer info */}
            <TransferInfo
              isBulkTransfer={true}
              hasUserReachedPaymentPage={true}
              withdrawalFees={0}
              amountInEuros={
                calculateSumFromArray(
                  fileCSV?.data === undefined ? fileCSV : fileCSV.data
                )?.total
              }
              fees={fees}
              fetchingFees={fetchingFees}
              t={t}
              spread_config={spread_config}
              paymentMethod={payMethod}
              transfer={transfer}
              showWithdrawMethod={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkPaymentModal;
