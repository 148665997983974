import { useCallback, useEffect } from "react";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useAppSelector } from "../../store/hooks";
import { toast } from "material-react-toastify";
import * as Sentry from "@sentry/browser";

const GoogleCaptchaVerfication: React.FC<any> = ({ parentCallBack }) => {
  const {
    auth: { refreshCaptcha },
  } = useAppSelector((state: any) => state.persistedReducer);
  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    try {
      if (!executeRecaptcha) {
        return;
      }
      await executeRecaptcha("yourAction")
        .then(async (tk) => {
          parentCallBack(tk);
        })
        .catch((error: any) => {
          Sentry.captureException(error);
        });
    } catch (error: any) {
      Sentry.captureException(error);
    }
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    setTimeout(() => {
      handleReCaptchaVerify();
    }, 1000);
    const interval = setInterval(() => {
      handleReCaptchaVerify();
    }, 120 * 1000);
    return () => clearInterval(interval);
  }, [handleReCaptchaVerify, refreshCaptcha]);

  useEffect(() => {
    // Your effect

    return () => {
      // Cleanup
    };
  }, []);

  return <button onClick={() => handleReCaptchaVerify()}></button>;
};

export default GoogleCaptchaVerfication;
