import React, { useEffect, useState } from "react";
import InputField from "../forms/InputField";
import { Form } from "formik";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getAllMobileOperatorWithCountryCode } from "../../store/features/Transfer/Transfer";
import { getAllMobileOperatorWithCode } from "../../store/features/Transfer/TransferSlice";
import { extractError } from "../../utility";
import { toast } from "material-react-toastify";
import * as Sentry from "@sentry/browser";
import CustomDropdown from "../CustomDropdown";
import { getCountry } from "../../utilities/help";
import MobileOperatorModal from "./MobileOperatorModal";

type Props = {
  type: string;
  countries?: any;
  values: any;
  errors: any;
  handleChange: any;
  handleBlur: any;
  touched: any;
  t: any;
  action: string;
  processing: boolean;
  hub2BankAccounts: any;
  selected_account: any;
  fetchHub2BanksAccordingToCountry: (arg0: Number) => void;
  fetchingBankNames: boolean;
  isValid?: boolean;
};

const FormComp = ({
  type,
  hub2BankAccounts,
  selected_account,
  fetchHub2BanksAccordingToCountry,
  fetchingBankNames,
  values,
  errors,
  handleChange,
  handleBlur,
  touched,
  t,
  action,
  processing = false,
  isValid,
}: Props) => {
  const { transfer } = useAppSelector((state) => state.persistedReducer);

  const dispatch = useAppDispatch();
  const [isMobileOperator, setIsMobileOperatorOpen] = React.useState(false);
  const [checkedBox, setCheckedBox] = React.useState<boolean>(false);
  const [checkedOtherBox, setCheckedOtherBox] = React.useState<boolean>(false);
  const [singleItem, setSingleItem] = React.useState<any>("");
  const openModal = () => setIsMobileOperatorOpen((prev) => !prev);

  // store all mobile operators
  const [allMobileOperators, setallMobileOperators] = useState([]);
  const [otherOperator, setotherOperator] = useState("");

  // set all mobile operators
  useEffect(() => {
    try {
      if (
        transfer?.getAllMobileOperator !== undefined &&
        transfer?.getAllMobileOperator !== null
      ) {
        let mobileOperatorList: any = [];
        const other = { name: "Other", checked: false };
        // all providers list
        const operatorList = transfer?.getAllMobileOperator.filter(
          (itm: any) => {
            return itm.hasOwnProperty("providers") && itm.providers.length > 0;
          }
        );

        // fill the list
        if (operatorList.length > 0) {
          operatorList.forEach((element: any) => {
            if (element.providers[0].method == "mobile_money") {
              mobileOperatorList = element.providers.concat(mobileOperatorList);
            }
          });
          if (
            mobileOperatorList.length > 0 &&
            (values?.operator == undefined || values?.operator == "")
          ) {
            setallMobileOperators(
              mobileOperatorList
                .concat([other])
                ?.map((itm: any) => ({ ...itm, checked: false }))
            );
          } else {
            // set default value in case on edit
            const isNotOther = mobileOperatorList.find(
              (itm: any) => itm?.name == values?.operator
            );

            // if its other
            if (isNotOther == undefined) {
              setCheckedOtherBox(true);
              setallMobileOperators(
                mobileOperatorList
                  .concat([other])
                  ?.map((itm: any) =>
                    itm.name == "Other"
                      ? { ...itm, checked: true }
                      : { ...itm, checked: false }
                  )
              );
              setotherOperator(values?.operator);
            } else {
              setSingleItem(isNotOther);
              setallMobileOperators(
                mobileOperatorList
                  .concat([other])
                  ?.map((itm: any) =>
                    itm.name == values?.operator
                      ? { ...itm, checked: true }
                      : { ...itm, checked: false }
                  )
              );
              setotherOperator(values?.operator);
            }
          }
        } else {
          mobileOperatorList = [];
          setallMobileOperators([]);
        }
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, [transfer?.getAllMobileOperator, values]);

  // handle operator checkbox ui
  const handleMobileOperator = (name: any) => {
    if (name !== "Other") {
      values.operator = name;
    }
    setallMobileOperators((prev: any) =>
      prev?.map((itm: any) =>
        itm.name == name
          ? { ...itm, checked: true }
          : { ...itm, checked: false }
      )
    );
  };

  // backup for edit mobile operator
  // useEffect(() => {
  //   if (values?.operator === '') {
  //     dispatch(getAllMobileOperatorWithCode([]));
  //   }
  //   if (values?.operator !== '') {
  //     const filterOperator: any = transfer.danaPayCountries.filter((e: any) => e.name?.toString() === values?.country.toString());
  //     getAllMobileOperatorWithCountryCode(filterOperator[0]?.code).then((res: any) => {
  //       const findProvider = res.find((val: any) => {
  //         if (val?.providers !== undefined) {
  //           return val;
  //         }
  //       });
  //       const findOther: any = findProvider?.providers?.filter((val: any) => val?.name === values?.operator);
  //       values.Other = values.operator;
  //       if (findOther?.length === 0) {
  //         setCheckedOtherBox(true);
  //       }
  //       if (findOther?.length > 0) {
  //         values.Other = '';
  //         setSingleItem(findOther[0]);
  //       }
  //       handleChangeBox(true, findOther?.length === 0 ? 'Other' : values?.operator, '');
  //       dispatch(getAllMobileOperatorWithCode(res));
  //     }).catch((error: any) => {
  //     });
  //     handleChangeBox(true, values?.operator, '');
  //   }
  // }, []);

  useEffect(() => {
    if (values?.operator === "") {
      dispatch(getAllMobileOperatorWithCode([]));
    }

    // if (values?.operator !== '') {
    //   // get country code
    //   const filterOperator: any = transfer.danaPayCountries.filter((e: any) => e.name?.toString() === values?.country.toString());

    //   // get provider object
    //   getAllMobileOperatorWithCountryCode(filterOperator[0]?.code).then((res: any) => {
    //     const findProvider = res.find((val: any) => {
    //       if (val?.providers !== undefined) {
    //         return val;
    //       }
    //     });
    //     const findOther: any = findProvider?.providers?.filter((val: any) => val?.name === values?.operator);
    //     values.Other = values.operator;
    //     if (findOther?.length === 0) {
    //       setCheckedOtherBox(true);
    //     }
    //     if (findOther?.length > 0) {
    //       values.Other = '';
    //       setSingleItem(findOther[0]);
    //     }
    //     handleChangeBox(true, findOther?.length === 0 ? 'Other' : values?.operator, '');
    //     dispatch(getAllMobileOperatorWithCode(res));
    //   }).catch((error: any) => {
    //   });
    //   handleChangeBox(true, values?.operator, '');
    // }
  }, []);

  const handleChangeBox = (event: any, value: any, providerItem: any) => {
    setCheckedBox(event === true ? event : event.target.checked);
    setCheckedOtherBox(false);
    if (providerItem === "") {
      values.operator = value;
    } else {
      values.operator = providerItem.name;
      setSingleItem(providerItem);
    }
    if (event?.target?.checked === false) {
      setSingleItem("");
    }

    if (value === "Other") {
      setCheckedOtherBox(event === true ? event : event.target.checked);
      setCheckedBox(false);
    }
  };

  return (
    <>
      <Form>
        <div className="my-2">
          <label className="mb-1 text-sm text-gray-600">{t("Country")}</label>

          <CustomDropdown
            triggerOnChangeForDefaultValue={false}
            defaultValue={values.country || ""}
            onchange={(val: any) => {
              const country = getCountry(transfer, val);

              try {
                country &&
                  getAllMobileOperatorWithCountryCode(country?.code)
                    .then((res: any) => {
                      dispatch(getAllMobileOperatorWithCode(res));
                      if (action == "add") {
                        handleChange("operator")("");
                      }
                    })
                    .catch((error: any) => {
                      toast.error(t(extractError(error)));
                      Sentry.captureException(error);
                    });

                fetchHub2BanksAccordingToCountry(Number(country.country_code));
              } catch (error: any) {
                Sentry.captureException(error);
              }

              handleChange("customBankName")("");
              handleChange("bank_name")("");
              handleChange("country")(val);
            }}
            list={transfer?.danaPayCountries}
            customClasses="wrapperBankCheckBox innerBankBox m-0 customInputStyle w-full px-2 py-2 text-sm bg-white border-2 rounded-2"
            placeholder={`${t("Select_Country")}`}
          />

          {errors.country && (
            <small className="text-red-500">{t(`${errors.country}`)}</small>
          )}
        </div>

        <InputField
          extraClasses="mb-3 customInputStyle"
          name="title"
          handleChange={handleChange}
          onBlur={handleBlur}
          placeholder={t("Account_Title")}
          value={values.title}
          label={t("Account_Title")}
          error={t(`${errors.title}`)}
          touched={touched.title}
        />
        <InputField
          extraClasses="mb-3 customInputStyle"
          name="owner"
          handleChange={handleChange}
          onBlur={handleBlur}
          placeholder={t("Owner_Name")}
          value={values.owner}
          label={t("Owner_Name")}
          error={t(`${errors.owner}`)}
          touched={touched.owner}
        />
        {type === "bank" ? (
          <>
            <div className="mb-3 outerfieldBox">
              {/* bank name dropdown */}
              {hub2BankAccounts.length > 0 && !fetchingBankNames ? (
                <>
                  <label
                    htmlFor="bank name"
                    className="mb-1 text-gray-600 text-[12.6px]"
                  >
                    {t("Bank_Name")}
                  </label>
                  <CustomDropdown
                    isDisabled={values?.country == "" || fetchingBankNames}
                    defaultValue={
                      hub2BankAccounts.length > 0
                        ? selected_account?.bank_name
                        : undefined
                    }
                    onchange={(val: any) => {
                      handleChange("bank_name")(val);
                    }}
                    list={hub2BankAccounts}
                    customClasses="bankNameDropDown"
                    placeholder={t("select_the_bank")}
                  />

                  {/* dropdown bank name not selected error */}
                  {errors.bank_name !== undefined &&
                    values?.bank_name == "" && (
                      <small
                        className="mt-2 text-red-400"
                        style={{ fontSize: 11 }}
                      >
                        {t("bank_name_required")}
                      </small>
                    )}
                </>
              ) : (
                <InputField
                  name="bank_name"
                  disabled={values?.country == "" || fetchingBankNames}
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t("Bank_Name")}
                  value={values.bank_name}
                  label={t("Bank_Name")}
                  error={t(`${errors.bank_name}`)}
                  touched={touched.company}
                />
              )}

              {/* custom bank name */}
              {values?.bank_name == "Other" && hub2BankAccounts.length > 0 && (
                <>
                  <InputField
                    name="customBankName"
                    disabled={fetchingBankNames || values?.country == ""}
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t("Bank_Name")}
                    value={values.customBankName}
                    error={t(`${errors.customBankName}`)}
                    touched={touched.customBankName || touched.bank_name}
                  />
                </>
              )}
            </div>
            <InputField
              extraClasses="mb-3 customInputStyle"
              name="iban"
              handleChange={handleChange}
              onBlur={handleBlur}
              placeholder={t("IBAN")}
              value={values.iban}
              label={t("IBAN")}
              error={t(`${errors.iban}`)}
              touched={touched.iban}
            />
            <InputField
              extraClasses="mb-3 customInputStyle"
              name="bic"
              handleChange={handleChange}
              onBlur={handleBlur}
              placeholder={t("BIC")}
              value={values.bic}
              label={t("BIC")}
              error={t(`${errors.bic}`)}
              touched={touched.bic}
            />
          </>
        ) : (
          <>
            <div
              className="flex flex-row items-center justify-between w-full p-2 mt-2 mb-2 border-2 rounded-2"
              style={{
                background: "#fafafa",
                color: "#080707",
                border: "0px",
                padding: "15px 16px",
                margin: "7px 0px",
                cursor: "pointer",
              }}
            >
              {allMobileOperators.length > 0 &&
              values?.operator !== "" &&
              values?.operator !== undefined ? (
                <div className="px-1">
                  <p className="text-sm">
                    <p className="text-sm">{values?.operator}</p>
                  </p>
                </div>
              ) : (
                <div className="px-1">
                  <p className="text-sm text-[12.6px] text-red-500">
                    {t("No_Operator_Found")}
                  </p>
                </div>
              )}

              <div
                className="my-2 text-center text-[12.6px] mr-2"
                style={{ color: "rgb(3, 115, 117)" }}
                onClick={() =>
                  values?.country !== "" && setIsMobileOperatorOpen(true)
                }
              >
                {t("Select_Mobile_Operator")}
              </div>
            </div>

            {/* mobile operator modal */}
            <MobileOperatorModal
              isMobileOperator={isMobileOperator}
              openModal={openModal}
              transfer={transfer}
              t={t}
              allMobileOperators={allMobileOperators}
              handleChange={handleChange}
              setotherOperator={setotherOperator}
              values={values}
              handleMobileOperator={handleMobileOperator}
              handleChangeBox={handleChangeBox}
              errors={errors}
              handleBlur={handleBlur}
              touched={touched}
              otherOperator={otherOperator}
              checkedOtherBox={checkedOtherBox}
            />

            <InputField
              disabled={values.country == "" || values.country == undefined}
              type="number"
              extraClasses="mb-3 customInputStyle"
              name="phone_number"
              handleChange={handleChange}
              onBlur={handleBlur}
              placeholder={t("Phone_Number")}
              value={values.phone_number}
              label={t("Phone_Number")}
              error={t(`${errors.phone_number}`)}
              touched={touched.phone_number}
            />
          </>
        )}

        <div className="flex flex-row items-center justify-between mt-10 justify-content-center">
          {processing ? (
            <button
              className={`btn text-center rounded-md text-[12.6px] leading-6 text-white`}
              style={{ backgroundColor: "rgb(3, 115, 117)" }}
            >
              {t("Please_wait")}
            </button>
          ) : (
            <button
              type="submit"
              className={`btn text-center rounded-md text-[12.6px] leading-6 text-white`}
              style={{ backgroundColor: "rgb(3, 115, 117)" }}
              disabled={
                !isValid ||
                (type === "mobile"
                  ? transfer?.getAllMobileOperator?.find((val: any) => {
                      return val;
                    })?.providers !== undefined ||
                    checkedBox === true ||
                    (checkedOtherBox === true && values?.Other !== "")
                    ? false
                    : true
                  : false)
              }
            >
              {action === "add" ? t("Add_Account") : t("Edit_Account")}
            </button>
          )}
        </div>
      </Form>
    </>
  );
};

export default FormComp;

{
  /* <select
            required
            name="country"
            onChange={(e) => {
              handleChange(e);

              const countryObject: any = JSON.parse(e.target.value);
              try {
                getAllMobileOperatorWithCountryCode(countryObject?.code)
                  .then((res: any) => {
                    dispatch(getAllMobileOperatorWithCode(res));
                    if (action == "add") {
                      handleChange("operator")("");
                    }
                  })
                  .catch((error: any) => {
                     toast.error(t(extractError(error)));
                    Sentry.captureException(error);
                  });

                fetchHub2BanksAccordingToCountry(
                  Number(countryObject.country_code)
                );
              } catch (error: any) {
                Sentry.captureException(error);
              }
              handleChange("customBankName")("");
              handleChange("bank_name")("");
            }}
            className="w-full py-1 bg-white border-2 border-b-slate-400"
          >
            {values?.country === "" && <option>{t("select")}</option>}
            {transfer?.danaPayCountries?.map((country: any, index: any) => {
              if (country.name.toString() === values?.country.toString()) {
                return (
                  <option selected value={JSON.stringify(country)}>
                    {values.country}
                  </option>
                );
              } else {
                return (
                  <option value={JSON.stringify(country)}>
                    {country.name}
                  </option>
                );
              }
            })}
          </select> */
}
